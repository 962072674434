import React from "react";
import {
  DsBottomSheet,
  DsBox,
  DsButton,
  DsImage,
  DsImageProps,
  DsTypography,
} from "@am92/react-design-system";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import images from "~/src/Constants/images";

interface SelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  mainHeading: string;
  description: string;
  handleDeleteClick: () => void;
}

class SelectionModal extends React.Component<SelectionModalProps> {
  constructor(props: SelectionModalProps) {
    super(props);
    this.state = {};
  }

  handleLeftClick = () => {
    const { mainHeading, onClose } = this.props;

    //const label = mainHeading === "Disable autopay?" ? "Disable" : "Delete";
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      mainHeading.substr(0, mainHeading.length - 1),
      "cancel"
    );
    onClose();
  };

  handleRightClick = () => {
    const { mainHeading, handleDeleteClick } = this.props;

    const label = mainHeading === "Disable autopay?" ? "Disable" : "Delete";
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      mainHeading.substr(0, mainHeading.length - 1),
      label
    );
    handleDeleteClick();
  };

  render() {
    const { isOpen, onClose, mainHeading, description, handleDeleteClick } =
      this.props;

    return (
      <DsBox>
        <DsBottomSheet
          open={isOpen}
          onClose={onClose}
          sx={{
            "& .MuiPaper-root": {
              paddingTop: "var(--ds-spacing-zero)",
              paddingBottom: "var(--ds-spacing-zero)",
            },

            "& .MuiDialogContent-root": {
              paddingLeft: "var(--ds-spacing-zero)",
              paddingRight: "var(--ds-spacing-zero)",
            },

            "& .MuiDrawer-paper": {
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "38rem",
              maxHeight: "fit-content",
            },
          }}
        >
          <DsBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="var(--ds-spacing-frostbite)"
            sx={{
              paddingTop: "var(--ds-spacing-zero)",
              paddingBottom: "var(--ds-spacing-mild)",
              paddingLeft: "var(--ds-spacing-bitterCold)",
              paddingRight: "var(--ds-spacing-bitterCold)",
            }}
          >
            <DsTypography variant="headingBoldLarge" textAlign="center">
              {mainHeading}
            </DsTypography>
            <DsTypography
              textAlign="center"
              variant="bodyRegularMedium"
              color="var(--ds-colour-typoSecondary)"
            >
              {description}
            </DsTypography>

            <DsBox textAlign="center">
              <DsImage
                srcSet={
                  mainHeading.toLowerCase() === "Disable autopay?".toLowerCase()
                    ? DisableAutopayImage
                    : mainHeading.toLowerCase() ===
                      "Delete scheduled payment?".toLowerCase()
                    ? DeleteScheduledPaymentImage
                    : DeleteBillerImage
                }
                height="148px"
                width="148px"
              />
            </DsBox>
          </DsBox>

          <DsBox
            display="flex"
            sx={{
              padding: "var(--ds-spacing-bitterCold)",
              paddingBottom: "var(--ds-spacing-mild)",
            }}
            justifyContent="space-around"
          >
            <DsBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "var(--ds-spacing-frostbite)",
                width: "100%",
              }}
            >
              <DsButton
                color="secondary"
                onClick={this.handleLeftClick}
                size="large"
                sx={{ width: "100%" }}
              >
                Cancel
              </DsButton>
              <DsButton
                color="primary"
                onClick={this.handleRightClick}
                sx={{ width: "100%" }}
                size="large"
              >
                {mainHeading.toLowerCase() === "Disable autopay?".toLowerCase()
                  ? "Disable"
                  : "Delete"}
              </DsButton>
            </DsBox>
          </DsBox>
        </DsBottomSheet>
      </DsBox>
    );
  }
}

export default SelectionModal;

const DisableAutopayImage: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.DisableAutopaySBZ}`, import.meta.url).href,
    alt: "Disable Autopay",
    // as: "image/png",
  },
];

const DeleteBillerImage: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.DeleteBillerSBZ}`, import.meta.url).href,
    alt: "Delete Biller",
    // as: "image/png",
  },
];

const DeleteScheduledPaymentImage: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.DeleteSchedulePaymentSBZ}`, import.meta.url).href,
    alt: "Delete Scheduled Payment",
    // as: "image/png",
  },
];
