import AppStore, { RootState } from "~/src/Configurations/AppStore";

import {
  getBiller,
  getBillerAccount,
} from "~/src/Redux/Billers/Services/Biller.Service";
import { setBillerRegisterationToPaymentPage } from "~/src/Redux/Billers/Reducers/SearchService.reducer";

import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import {
  hideLoader,
  showLoader,
} from "~/src/Redux/Loaders/Reducers/Loaders.reducers";

let loaderExecuted = false;

export const manageBillerDetailsLoader = async () => {
  if (loaderExecuted) {
    console.log("Loader already executed, skipping...");
    return null;
  }
  loaderExecuted = true; // Set the flag

  const state: RootState = AppStore.getState();
  const {
    customerDetail,
    billers: { billerRegisterationToPaymentPage },
  } = state;

  try {
    AppStore.dispatch(showLoader());
    let biller = billerRegisterationToPaymentPage?.biller;
    let billerAccount = billerRegisterationToPaymentPage?.billerAccount;

    if (!biller && billerAccount) {
      const getBillerDetailsApiCall = await Promise.all([
        AppStore.dispatch(getBiller(billerAccount?.billerId || "")),
      ]);
      biller = getBillerDetailsApiCall[0];
    }

    AppStore.dispatch(
      setBillerRegisterationToPaymentPage({
        ...state?.billers?.billerRegisterationToPaymentPage,
        biller,
        billerAccount,
      })
    );

    loaderExecuted = false;
    AppStore.dispatch(hideLoader());
    return null;
  } catch (error) {
    console.error("Error in Manage Biller Details:", error);
    window.location =
      APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString() as any;
    // redirect(APP_ROUTES.SOMETHING_WENT_WRONG.pathname.toString());
    throw error;
  }
};
