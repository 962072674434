import { parse } from "date-fns";
import { ModelMapper } from "~/src/Lib/types/modelmapper";

//Registered Biller Mapper and Interface
interface RegisteredBillerBillAuthDto {
  parameter_name: string;
  value: string;
}

interface BilleraccountDto {
  objectid: string;
  sourceid: string;
  billeraccountid: string;
  billerid: string;
  biller_name: string;
  short_name: string;
  authenticators: RegisteredBillerBillAuthDto[];
  status: string;
  registration_date: string;
  autopay_status: string;
  biller_category: string;
  biller_logo: string;
  autopay_amount?: string;
  payment_account?: {
    objectid: string;
    paymentaccountid: string;
    short_name: string;
    payment_method: string;
    account_number: string;
  };
  currency?: string;
}

interface RegisteredBillerBillListDto {
  objectid: string;
  billid: string;
  billerid: string;
  sourceid: string;
  customerid: string;
  billeraccountid: string;
  billstatus: string;
  authenticators: RegisteredBillerBillAuthDto[];
  billnumber: string;
  late_payment_amount: string;
  net_billamount: string;
  billduedate: string;
  billamount: string;
  billdate: string;
}

interface RegisteredBillerTransactionHistoryDto {
  sourceid: string;
  customerid: string;
  paymentid: string;
  objectid: string;
  currency: string;
  billerid: string;
  payment_amount: string;
  biller_name: string;
  biller_category: string;
  billeraccountid: string;
  short_name: string;
  authenticators: RegisteredBillerBillAuthDto[];
  payment_type: string;
  txn_date_time: string;
  biller_status: string;
  biller_status_desc: string;
  biller_error_code: string;
  biller_error_type: string;
  payment_account: {
    objectid: string;
    paymentaccountid: string;
    payment_method: string;
    account_number: string;
    ifsc: string;
    account_type: string;
  };
  billlist: RegisteredBillerBillListDto[];
  payment_status: string;
  payment_error_desc: string;
  payment_error_type: string;
  payment_error_code: string;
  debit_amount: string;
  schedule_date: string;
  cou_conv_fee: string;
  bou_conv_fee: string;
  biller_logo: string;
}

interface RegisteredBillerEntryDto {
  objectid: string;
  customerid: string;
  billeraccount: BilleraccountDto;
  billlist?: RegisteredBillerBillListDto[];
  transaction_history?: RegisteredBillerTransactionHistoryDto[];
}
export interface RegisteredBillersDto {
  [key: string]: RegisteredBillerEntryDto[];
}

interface RegisteredBillerBillAuth {
  parameterName: string;
  value: string;
}

interface Billeraccount {
  objectId: string;
  sourceId: string;
  billeraccountId: string;
  billerId: string;
  billerName: string;
  shortName: string;
  authenticators: RegisteredBillerBillAuth[];
  status: string;
  registrationDate: Date;
  autopayStatus: boolean;
  billerCategory: string;
  billerLogo: string;
  paymentAccount?: {
    objectId: string;
    paymentAccountId: string;
    shortName: string;
    paymentMethod: string;
    accountNumber: string;
  };
  currency?: string;
}

interface RegisteredBillerBillList {
  objectId: string;
  billId: string;
  billerId: string;
  sourceId: string;
  customerId: string;
  billerAccountId: string;
  billStatus: string;
  authenticators: RegisteredBillerBillAuth[];
  billNumber: string;
  latePaymentAmount: string;
  netBillamount: string;
  billDueDate: Date;
  billAmount: string;
  billDate: Date;
}

interface RegisteredBillerTransactionHistory {
  sourceId: string;
  customerId: string;
  paymentId: string;
  objectId: string;
  currency: string;
  billerId: string;
  paymentAmount: string;
  billerName: string;
  billerCategory: string;
  billerAccountId: string;
  shortName: string;
  authenticators: RegisteredBillerBillAuth[];
  paymentType: string;
  txnDateTime: Date;
  billerStatus: string;
  billerStatusDesc: string;
  billerErrorCode: string;
  billerErrorType: string;
  paymentAccount: {
    objectId: string;
    paymentAccountId: string;
    paymentMethod: string;
    accountNumber: string;
    ifsc: string;
    accountType: string;
  };
  billList: RegisteredBillerBillList[];
  paymentStatus: string;
  paymentErrorDesc: string;
  paymentErrorType: string;
  paymentErrorCode: string;
  debitAmount: string;
  scheduleDate: Date;
  couConvFee: string;
  bouConvFee: string;
  billerLogo: string;
}

export interface RegisteredBillerEntry {
  objectId: string;
  customerId: string;
  billerAccount: Billeraccount;
  billList?: RegisteredBillerBillList[];
  transactionHistory?: RegisteredBillerTransactionHistory[];
}

export interface RegisteredBillers {
  [key: string]: RegisteredBillerEntry[];
}

export class RegisteredBillerInfoMapper
  implements ModelMapper<RegisteredBillersDto, RegisteredBillers>
{
  toModel(dto: RegisteredBillersDto): RegisteredBillers {
    const registeredBillerObj: RegisteredBillers = {};
    for (const [key, value] of Object.entries(dto)) {
      registeredBillerObj[key] = value.map((item) => ({
        objectId: item.objectid,
        customerId: item.customerid,
        transactionHistory: item?.transaction_history?.map((trnxhistory) => ({
          sourceId: trnxhistory.sourceid,
          customerId: trnxhistory.customerid,
          paymentId: trnxhistory.paymentid,
          objectId: trnxhistory.objectid,
          currency: trnxhistory.currency,
          billerId: trnxhistory.billerid,
          paymentAmount: trnxhistory.payment_amount,
          billerName: trnxhistory.biller_name,
          billerCategory: trnxhistory.biller_category,
          billerAccountId: trnxhistory.billeraccountid,
          shortName: trnxhistory.short_name,
          authenticators: trnxhistory.authenticators.map((auth) => ({
            parameterName: auth.parameter_name,
            value: auth.value,
          })),
          paymentType: trnxhistory.payment_type,
          txnDateTime: parse(
            trnxhistory.txn_date_time?.split(" ")[0],
            "dd-MM-yyyy",
            new Date()
          ),
          billerStatus: trnxhistory.biller_status,
          billerStatusDesc: trnxhistory.biller_status_desc,
          billerErrorCode: trnxhistory.biller_error_code,
          billerErrorType: trnxhistory.biller_error_type,
          paymentAccount: {
            objectId: trnxhistory.payment_account.objectid,
            paymentAccountId: trnxhistory.payment_account.paymentaccountid,
            paymentMethod: trnxhistory.payment_account.payment_method,
            accountNumber: trnxhistory.payment_account.account_number,
            ifsc: trnxhistory.payment_account.ifsc,
            accountType: trnxhistory.payment_account.account_type,
          },
          billList: trnxhistory.billlist?.map((bills) => ({
            objectId: bills.objectid,
            billId: bills.billid,
            billerId: bills.billerid,
            sourceId: bills.sourceid,
            customerId: bills.customerid,
            billerAccountId: bills.billeraccountid,
            billStatus: bills.billstatus,
            authenticators: bills.authenticators.map((auth) => ({
              parameterName: auth.parameter_name,
              value: auth.value,
            })),
            billNumber: bills.billnumber,
            latePaymentAmount: bills.late_payment_amount,
            netBillamount: bills.net_billamount,
            billDueDate: parse(bills.billduedate, "dd-MM-yyyy", new Date()),
            billAmount: bills.billamount,
            billDate: parse(bills.billdate, "dd-MM-yyyy", new Date()),
          })),
          paymentStatus: trnxhistory.payment_status,
          paymentErrorDesc: trnxhistory.payment_error_desc,
          paymentErrorType: trnxhistory.payment_error_type,
          paymentErrorCode: trnxhistory.payment_error_code,
          debitAmount: trnxhistory.debit_amount,
          scheduleDate: parse(
            trnxhistory.schedule_date,
            "dd-MM-yyyy",
            new Date()
          ),
          couConvFee: trnxhistory.cou_conv_fee,
          bouConvFee: trnxhistory.bou_conv_fee,
          billerLogo: trnxhistory.biller_logo,
        })),

        billerAccount: {
          objectId: item.billeraccount.objectid,
          sourceId: item.billeraccount.sourceid,
          shortName: item.billeraccount.short_name,
          status: item.billeraccount.status,
          billeraccountId: item.billeraccount.billeraccountid,
          billerId: item.billeraccount.billerid,
          billerName: item.billeraccount.biller_name,
          autopayStatus:
            item.billeraccount.autopay_status === "N" ? false : true,
          billerCategory: item.billeraccount.biller_category,
          billerLogo: item.billeraccount.biller_logo,
          authenticators: item.billeraccount.authenticators.map((auth) => ({
            parameterName: auth.parameter_name,
            value: auth.value,
          })),
          registrationDate: parse(
            item.billeraccount.registration_date,
            "dd-MM-yyyy HH:mm:ss",
            new Date()
          ),
        },
        billList: item?.billlist?.map((bills) => ({
          objectId: bills.objectid,
          billId: bills.billid,
          billerId: bills.billerid,
          sourceId: bills.sourceid,
          customerId: bills.customerid,
          billerAccountId: bills.billeraccountid,
          billStatus: bills.billstatus,
          authenticators: bills.authenticators.map((auth) => ({
            parameterName: auth.parameter_name,
            value: auth.value,
          })),
          billNumber: bills.billnumber,
          latePaymentAmount: bills.late_payment_amount,
          netBillamount: bills.net_billamount,
          billDueDate: parse(bills.billduedate, "dd-MM-yyyy", new Date()),
          billAmount: bills.billamount,
          billDate: parse(bills.billdate, "dd-MM-yyyy", new Date()),
        })),
      }));
    }
    return registeredBillerObj;
  }
  toDto(model: RegisteredBillers): RegisteredBillersDto {
    throw new Error("Method not implemented. " + model);
  }
}

// Delete Billers

export interface DeleteAndModifyBillerInfoDto {
  objectid: string;
  sourceid: string;
  billeraccountid: string;
  billerid: string;
  short_name: string;
  authenticators: RegisteredBillerBillAuthDto[];
  status: string;
  registration_date: string;
  deletion_date?: string;
  modified_date?: string;
  autopay_status: string;
  autopay_amount: string;
}

export interface DeleteAndModifyBillerInfo {
  objectId: string;
  sourceId: string;
  billerAccountId: string;
  billerId: string;
  shortName: string;
  authenticators: RegisteredBillerBillAuth[];
  status: string;
  registrationDate: Date;
  modifiedDate?: Date | undefined;
  deletionDate?: Date | undefined;
  autopayAmount: string;
  autopayStatus: boolean;
}

export class DeleteAndModifyBillerInfoMapper
  implements
    ModelMapper<DeleteAndModifyBillerInfoDto, DeleteAndModifyBillerInfo>
{
  toModel(dto: DeleteAndModifyBillerInfoDto): DeleteAndModifyBillerInfo {
    return {
      objectId: dto.objectid,
      sourceId: dto.sourceid,
      billerAccountId: dto.billeraccountid,
      billerId: dto.billerid,
      shortName: dto.short_name,
      authenticators: dto.authenticators.map((auth) => ({
        parameterName: auth.parameter_name,
        value: auth.value,
      })),
      status: dto.status,
      registrationDate: parse(
        dto.registration_date,
        "dd-MM-yyyy HH:mm:ss",
        new Date()
      ),
      deletionDate: dto.deletion_date
        ? parse(dto.deletion_date, "dd-MM-yyyy HH:mm:ss", new Date())
        : undefined,
      modifiedDate: dto.modified_date
        ? parse(dto.modified_date, "dd-MM-yyyy HH:mm:ss", new Date())
        : undefined,
      autopayStatus: dto.autopay_status === "N" ? false : true,
      autopayAmount: dto.autopay_amount || "",
    };
  }
  toDto(model: DeleteAndModifyBillerInfo): DeleteAndModifyBillerInfoDto {
    throw new Error("Method not implemented. " + model.billerAccountId);
  }
}
