import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import { DeleteAndModifyBillerInfoMapper } from "~/src/Redux/Billers/Model/RegisteredBiller.model";

import {
  fetchModifyRegisteredBillerList,
  fetchModifyRegisteredBillerListFail,
  fetchModifyRegisteredBillerListSuccess,
} from "~/src/Redux/Billers/Reducers/ModifyRegisteredBiller.reducer";
import ChannelStore from "~/src/Pages/common/utitlity/ChannelStore";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const MODIFY_REGISTERED_BILLER_URL = URL.BILLER.MODIFY_REGISTERED_BILLER;

export const getModifyRegisteredBillerListThunk =
  (
    customerId: string,
    mobile: string,
    email: string,
    billeraccountId: string,
    autopayStatus?: boolean,
    autopayAmount?: number,
    currency?: number,
    paymentAccount?: {
      paymentMethod: string;
      accountNumber?: string;
      ifsc?: string;
      cardStart?: string;
      cardEnd?: string;
      primeCardSerialNumber?: string;
    },
    isCreditCardSelected?: boolean,
    shortName?: string
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(
      fetchModifyRegisteredBillerList({
        customerId,
        mobile,
        email,
        billeraccountId,
        autopayStatus,
        autopayAmount,
        currency,
        paymentAccount,
        isCreditCardSelected,
        shortName,
      })
    );
    try {
      const options: WebHttpRequestOptions = {
        url: MODIFY_REGISTERED_BILLER_URL,
        method: "POST",
        data: {
          customerid: customerId,
          mobile: mobile,
          email: email ? email : "",
          short_name: shortName,
          billeraccountid: billeraccountId,
          autopay_status:
            autopayStatus === true
              ? "Y"
              : autopayStatus === false
              ? "N"
              : undefined,
          autopay_amount: autopayAmount ? autopayAmount : undefined,
          currency: currency ? currency : undefined,
          payment_account:
            paymentAccount && !isCreditCardSelected
              ? {
                  payment_method: paymentAccount.paymentMethod,
                  account_number: paymentAccount.accountNumber,
                  ifsc: paymentAccount.ifsc,
                }
              : paymentAccount && isCreditCardSelected
              ? {
                  payment_method: paymentAccount.paymentMethod,
                  card_start: paymentAccount.cardStart,
                  card_end: paymentAccount.cardEnd,
                  card_account_number: paymentAccount.primeCardSerialNumber
                    ? `${paymentAccount.primeCardSerialNumber}`
                    : undefined,
                }
              : undefined,
          channelName: ChannelStore.get("channel") || "",
          auth_code: isCreditCardSelected ? "0013" : undefined,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;
      const { tokens = {} } = data;
      const token =
        // Instore.get('auth') ||
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3YjUzMWM1LWE5YzAtNGJhMS05NzlhLWQ1ZDVjMTQ1MmU0YiIsImlhdCI6MTY1NDg2MDg5NzkyNX0.rKAXRZIvOUed22lbPesnFoXHzaYNTLkRNtpGvJvBvCY";

      /*       const { accessToken = token, refreshToken = "" } = tokens;
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken); */

      const mapper = new DeleteAndModifyBillerInfoMapper();
      const mappedData = mapper.toModel(response.data.data);

      dispatch(fetchModifyRegisteredBillerListSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchModifyRegisteredBillerListFail(error as ApiResponseError));

      throw error;
    }
  };
