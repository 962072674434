interface ApiResponse {
  status: string;
  statusCode: number;
  message: string;
}
export interface ApiResponseData<T> extends ApiResponse {
  data: T;
}
export interface ApiResponseError extends ApiResponse {
  errorObj: any;
  error: {
    status: string;
    statusCode: number;
    msg: string;
    // error: Record<string, any>;
  };
}
export class ApiResponseErrorImpl implements ApiResponseError {
  error: {
    status: string;
    statusCode: number;
    msg: string;
    // error: Record<string, any>;
  };
  statusCode: number;
  status: string;
  message: string;
  errorObj: any;
  constructor(
    statusCode: number,
    status: string,
    message: string,
    error: any = {}
  ) {
    this.statusCode = statusCode;
    this.status = status;
    this.message = message;
    this.errorObj = error;
    this.error = {
      status: (error.status as string) || this.status,
      statusCode: (error.statusCode as number) || this.statusCode,
      msg: (error.msg as string) || this.message,
      // error: error.error as Record<string, any>,
    };
  }
}
