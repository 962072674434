import React, { FC, useEffect, useState } from "react";
import {
  DsBox,
  DsButton,
  DsChip,
  DsRemixIcon,
  DsTypography,
} from "@am92/react-design-system";
import { AccountDetail } from "~/src/Redux/Payment/Model/AccountDetail.model";
import { HorizontalLogoInfoButton } from "~/src/components/HorizontalInfoItem";
import images from "~/src/Constants/images";
import {
  ccmaskAccountNumber,
  getSchemeNameFromType,
  maskAccountNumber,
  updatedMaskAccountNumber,
  updatedccmaskAccountNumber,
} from "../Payment.helper";
import { localeAmountFormat } from "~/src/Common/utitlity/validation.utilities";
import { CreditCardDetailData } from "~/src/Redux/Payment/Model/CreditCard.model";
import { DataStatus } from "~/src/Lib/types/datatransfer";

type PayUsingAccountInfoProps = {
  accountDetail: AccountDetail;
  onClick: (acc: AccountDetail, cc: CreditCardDetailData) => void;
  isCCSelected: boolean;
  creditCardDetailData?: CreditCardDetailData;
  initialCCSelect?: CreditCardDetailData;
  paymentAmount: number;
  isCasaAccountSelected: boolean;
  isFromPaymentPage?: boolean;
  ccApiStatus?: string;
  accApiStatus: string;
  isCreditCardSelectedFromDrawer?: boolean;
  isdefaultCCAccount?: string;
};

export const PayUsingAccountInfo: FC<PayUsingAccountInfoProps> = ({
  accountDetail,
  onClick,
  isCCSelected,
  creditCardDetailData,
  initialCCSelect,
  paymentAmount,
  isCasaAccountSelected,
  isFromPaymentPage,
  accApiStatus,
  ccApiStatus,
  isCreditCardSelectedFromDrawer,
  isdefaultCCAccount,
}: PayUsingAccountInfoProps) => {
  const [displayDetails, setDisplayDetails] = useState<{
    showCreditCard: boolean;
    showAccount: boolean;
  }>({
    showCreditCard: false,
    showAccount: false,
  });

  useEffect(() => {
    // Note: adding the casa as mop by default. BPN-2568
    if (accApiStatus === DataStatus.LOADED && !isdefaultCCAccount) {
      if (
        accountDetail &&
        Object.keys(accountDetail).length > 0 &&
        accountDetail.accountAvailableBalance +
          accountDetail.accountFFDBalance >
          paymentAmount &&
        !isCreditCardSelectedFromDrawer
      ) {
        setDisplayDetails({ showAccount: true, showCreditCard: false });
      } else if (ccApiStatus === DataStatus.LOADED) {
        setDisplayDetails({ showAccount: false, showCreditCard: true });
      }
    } else if (isdefaultCCAccount) {
      setDisplayDetails({ showAccount: false, showCreditCard: true });
    } else if (
      accApiStatus === DataStatus.ERRORED &&
      ccApiStatus === DataStatus.LOADED
    ) {
      setDisplayDetails({ showAccount: false, showCreditCard: true });
    }

    // Note: commenting for furute Ref. if you want to add cc as mop then use this.
    // if (
    //   accApiStatus === DataStatus.LOADED &&
    //   ccApiStatus === DataStatus.LOADED
    // ) {
    //   if (
    //     creditCardDetailData &&
    //     Object.keys(creditCardDetailData).length > 0 &&
    //     accountDetail &&
    //     Object.keys(accountDetail).length > 0
    //   ) {
    //     setDisplayDetails({ showAccount: false, showCreditCard: true });
    //   } else if (
    //     creditCardDetailData &&
    //     Object.keys(creditCardDetailData).length === 0 &&
    //     accountDetail &&
    //     Object.keys(accountDetail).length > 0
    //   ) {
    //     setDisplayDetails({ showAccount: true, showCreditCard: false });
    //   } else if (
    //     creditCardDetailData &&
    //     Object.keys(creditCardDetailData).length > 0 &&
    //     !accountDetail
    //   ) {
    //     setDisplayDetails({ showAccount: false, showCreditCard: true });
    //   }
    // } else if (
    //   accApiStatus === DataStatus.ERRORED &&
    //   ccApiStatus === DataStatus.LOADED
    // ) {
    //   setDisplayDetails({ showAccount: false, showCreditCard: true });
    // } else if (
    //   accApiStatus === DataStatus.LOADED &&
    //   ccApiStatus === DataStatus.ERRORED
    // ) {
    //   setDisplayDetails({ showAccount: true, showCreditCard: false });
    // }

    // if (isCreditCardSelectedFromDrawer) {
    //   setDisplayDetails({ showAccount: false, showCreditCard: true });
    // }
  }, [
    accApiStatus,
    ccApiStatus,
    accountDetail,
    // creditCardDetailData,
    isCreditCardSelectedFromDrawer,
  ]);

  return (
    <DsBox>
      <DsBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16",
        }}
      >
        {(accountDetail || creditCardDetailData) && (
          <DsTypography variant="subheadingSemiboldLarge">
            Pay Using
          </DsTypography>
        )}
        {creditCardDetailData && (
          <DsBox
            display={"flex"}
            sx={{
              width: "50%",
              justifyContent: "end",
              flexGrow: 1,
              borderRadius: "4px",
            }}
          >
            <DsChip
              label={
                <DsBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="var(--ds-spacing-quickFreeze)"
                  padding="2px 8px 2px 8px"
                >
                  <DsRemixIcon
                    className="ri-bank-card-line"
                    sx={{
                      fontSize: "14px",
                      color: "var(--ds-colour-iconTypical)",
                    }}
                  />
                  <DsTypography
                    variant="supportRegularInfo"
                    sx={{
                      color:
                        " var(--light-support-typical-sz-colour-support-typical, #165964)",
                    }}
                  >
                    Credit Card payment available now!
                  </DsTypography>
                </DsBox>
              }
              sx={{
                backgroundColor: "var(--ds-colour-supportTypicalNeutral)",
                textTransform: "none",
                padding: "0px",
              }}
            />
          </DsBox>
        )}
      </DsBox>
      <DsBox
        sx={{
          marginTop: "var(--ds-spacing-bitterCold)",
          // marginBottom: "var(--ds-spacing-bitterCold)",
        }}
      >
        {isCCSelected &&
        creditCardDetailData &&
        displayDetails.showCreditCard ? (
          <HorizontalLogoInfoButton
            logo={images.AxisWhiteLogo}
            title={creditCardDetailData.productType}
            subtitle={updatedccmaskAccountNumber(
              creditCardDetailData.cardNumber
            )}
            onClick={() => onClick({} as AccountDetail, creditCardDetailData)}
            paymentAmount={paymentAmount}
            accountDetail={accountDetail}
            isFromPaymentPage={isFromPaymentPage}
            isCasaBalanceLess={false}
          />
        ) : accountDetail && Object.keys(accountDetail).length > 0 ? (
          <HorizontalLogoInfoButton
            logo={images.AxisWhiteLogo}
            title={getSchemeNameFromType(accountDetail?.schemType || "")}
            subtitle={`${updatedMaskAccountNumber(
              (accountDetail && accountDetail?.acid) || ""
            )} | Balance: ${localeAmountFormat(
              accountDetail?.accountAvailableBalance || 0
            )}`}
            subtext={
              accountDetail && accountDetail.accountFFDBalance
                ? `Flexi FD | Balance: ${localeAmountFormat(
                    accountDetail && accountDetail.accountFFDBalance
                  )}`
                : undefined
            }
            onClick={() => {
              onClick(accountDetail, {} as CreditCardDetailData);
            }}
            paymentAmount={paymentAmount}
            accountDetail={accountDetail}
            isFromPaymentPage={isFromPaymentPage}
            isCasaBalanceLess={true}
          />
        ) : !isCCSelected &&
          accountDetail &&
          Object.keys(accountDetail).length === 0 &&
          creditCardDetailData ? (
          <HorizontalLogoInfoButton
            logo={images.AxisWhiteLogo}
            title={creditCardDetailData.productType}
            subtitle={updatedccmaskAccountNumber(
              creditCardDetailData.cardNumber
            )}
            onClick={() => {
              onClick({} as AccountDetail, creditCardDetailData);
            }}
            paymentAmount={paymentAmount}
            accountDetail={accountDetail}
            isFromPaymentPage={isFromPaymentPage}
            isCasaBalanceLess={false}
          />
        ) : !isCCSelected &&
          !isCasaAccountSelected &&
          initialCCSelect &&
          creditCardDetailData ? (
          <HorizontalLogoInfoButton
            logo={images.AxisWhiteLogo}
            title={creditCardDetailData.productType}
            subtitle={updatedccmaskAccountNumber(
              creditCardDetailData.cardNumber
            )}
            onClick={() => {
              onClick({} as AccountDetail, creditCardDetailData);
            }}
            paymentAmount={paymentAmount}
            accountDetail={accountDetail}
            isFromPaymentPage={isFromPaymentPage}
            isCasaBalanceLess={false}
          />
        ) : accountDetail &&
          Object.keys(accountDetail).length > 0 &&
          accountDetail.accountAvailableBalance +
            accountDetail.accountFFDBalance >
            paymentAmount ? (
          <HorizontalLogoInfoButton
            logo={images.AxisWhiteLogo}
            title={getSchemeNameFromType(accountDetail?.schemType || "")}
            subtitle={`${updatedMaskAccountNumber(
              (accountDetail && accountDetail?.acid) || ""
            )} | Balance: ${localeAmountFormat(
              accountDetail?.accountAvailableBalance || 0
            )}`}
            subtext={
              accountDetail && accountDetail.accountFFDBalance
                ? `Flexi FD | Balance: ${localeAmountFormat(
                    accountDetail && accountDetail.accountFFDBalance
                  )}`
                : undefined
            }
            onClick={() => onClick(accountDetail, {} as CreditCardDetailData)}
            paymentAmount={paymentAmount}
            accountDetail={accountDetail}
            isFromPaymentPage={isFromPaymentPage}
            isCasaBalanceLess={true}
          />
        ) : null}
      </DsBox>
    </DsBox>
  );
};
