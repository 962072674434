import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";

import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

import { DeleteAndModifyBillerInfoMapper } from "~/src/Redux/Billers/Model/RegisteredBiller.model";

import {
  fetchDeleteRegisterBillerList,
  fetchDeleteRegisterBillerListFail,
  fetchDeleteRegisterBillerListSuccess,
} from "~/src/Redux/Billers/Reducers/DeleteRegisterBiller.Reducer";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const DELETE_REGISTER_BILLER_URL = URL.BILLER.DELETE_REGISTERED_BILLER;

export const getDeleteRegisteredBillerListThunk =
  (
    customerId: string,
    billeraccountId: string
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(
      fetchDeleteRegisterBillerList({
        customerId,
        billeraccountId,
      })
    );
    try {
      const options: WebHttpRequestOptions = {
        url: DELETE_REGISTER_BILLER_URL,
        method: "POST",
        data: {
          customerid: customerId,
          billeraccountid: billeraccountId,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;
      const { tokens = {} } = data;
      const token =
        // Instore.get('auth') ||
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA3YjUzMWM1LWE5YzAtNGJhMS05NzlhLWQ1ZDVjMTQ1MmU0YiIsImlhdCI6MTY1NDg2MDg5NzkyNX0.rKAXRZIvOUed22lbPesnFoXHzaYNTLkRNtpGvJvBvCY";

      const { accessToken = token, refreshToken = "" } = tokens;
      // asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken);
      // asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken);

      const mapper = new DeleteAndModifyBillerInfoMapper();
      const mappedData = mapper.toModel(response.data.data);

      dispatch(fetchDeleteRegisterBillerListSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchDeleteRegisterBillerListFail(error as ApiResponseError));
      throw error;
    }
  };
