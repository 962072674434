import React, {
  ReactElement,
  ChangeEvent,
  Component,
  forwardRef,
  useState,
  ReactNode,
  FC,
} from "react";
import {
  DsButton,
  DsRemixIcon,
  DsTypography,
  DsBox,
  DsTextField,
  DsMenuItem,
  DsFormControl,
  DsSelect,
  DsAvatar,
  DsToggle,
  DsSwitch,
  DsBottomSheet,
  DsButtonGroup,
  DsImage,
  DsDialog,
  DsDialogContent,
} from "@am92/react-design-system";
import APP_ROUTES from "../Constants/APP_ROUTES";
// import {
//   NavbarInjectedProps,
//   withNavigationBar
// } from '~/src/Components/NavigationBar'
import { NavigationBar } from "~/src/components/NavigationBar";
import { useNavigate } from "react-router-dom";
import { PAYMENT_ROUTES } from "../Pages/Payment/payment.routes";

type FullModalProps = {
  title: string;
  onBackClick?: () => void;
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
  footerRender?: ReactNode;
  navRightSection?: ReactElement;
};
export const FullModal: FC<FullModalProps> = ({
  onClose,
  isOpen,
  title,
  onBackClick,
  children,
  footerRender,
  navRightSection,
}: FullModalProps) => {
  const navigate = useNavigate();
  return (
    <DsDialog
      open={isOpen}
      fullScreen
      showClose={false}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 0,
          paddingTop: 0,
        },
        "& .MuiDialogContent-root": {
          marginTop: 0,
          padding: 0,
        },
      }}
      style={{ borderRadius: 0, zIndex: 50 }}
      onClose={onClose}
    >
      <DsBox>
        <NavigationBar
          title={title}
          onBackClick={onClose}
          rightSection={navRightSection}
        />
        <DsDialogContent>{children}</DsDialogContent>
      </DsBox>
    </DsDialog>
  );
};
