import {
  DsAvatar,
  DsBox,
  DsButton,
  DsCard,
  DsCheckbox,
  DsRadio,
  DsTypography,
  enqueueNotistack,
} from "@am92/react-design-system";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { localeAmountFormat } from "~/src/Common/utitlity/validation.utilities";
import { RootState } from "~/src/Configurations/AppStore";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { ApiResponseErrorImpl } from "~/src/Lib/types/api";
import { DataStatus } from "~/src/Lib/types/datatransfer";
import withRouter, { IWithRouterProps } from "~/src/Lib/withRouter";
import { Biller } from "~/src/Redux/Billers/Model/Biller.model";
import { BillerAccount } from "~/src/Redux/Billers/Model/BillerAccount.model";
import { BillerDataStateType } from "~/src/Redux/Billers/Reducers/Biller.reducer";
import { setBillerAccountStatusNotLoaded } from "~/src/Redux/Billers/Reducers/BillerAccount.reducer";
import { ModifyRegisteredBillerListState } from "~/src/Redux/Billers/Reducers/ModifyRegisteredBiller.reducer";
import { billersSelector } from "~/src/Redux/Billers/Selectors/Biller.selector";
import { getModifyRegisteredBillerSelector } from "~/src/Redux/Billers/Selectors/ModifyRegisteredBiller.selector";
import { getBiller } from "~/src/Redux/Billers/Services/Biller.Service";
import { getDueBills } from "~/src/Redux/Billers/Services/BillerAccount.Service";
import { getModifyRegisteredBillerListThunk } from "~/src/Redux/Billers/Services/ModifyRegisteredBiller.Service";
import { CustomerChannelEnum } from "~/src/Redux/Customer/Customer.reducer";
import { getCustomerData } from "~/src/Redux/Customer/Customer.selector";
import { CustomerDetail } from "~/src/Redux/Customer/Model";
import { AccountDetail } from "~/src/Redux/Payment/Model/AccountDetail.model";
import {
  CreditCardDetailData,
  mapCardDetailToPaymentAccount,
} from "~/src/Redux/Payment/Model/CreditCard.model";
import { BottomSticker } from "~/src/components/BottomSticker";
import { FullModal } from "~/src/components/FullModal";
import OverlayLoader from "~/src/components/OverlayLoader";
import { SuccessStatusModal } from "~/src/components/StatusModal";
import { TechnicalErrorDrawer } from "~/src/components/TechicalErrorDrawer";
import { AmountFormField } from "../../Payment/components/AutoPay.modal";
import MpinModal from "../../Payment/components/MpinModal";
import OtpModal from "../../Payment/components/OtpModal";
import PayUsing from "../../Payment/components/PayUsing";
import { commonErrorHandler } from "../../common/error/helper";
import { minMaxAmountAutopayModal } from "../../common/utitlity/biller.utilities";
import { ManageBillerSuccessModalStatusText } from "../helpers/ManageBillerHelperFunctions";

interface AutoPayModalProps extends IWithRouterProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthSuccess: () => void;
  title: string;
  billerAccount: BillerAccount;
  typeOfOperation: "Edit Autopay" | "Add Autopay";
  isFullPay: boolean;
  setFullPay: (isFullPay: boolean) => void;
  customerData: CustomerDetail;
  billerDetailData: BillerDataStateType;
  modifyBillerData: ModifyRegisteredBillerListState;
  customerId: string;
  actions: {
    loadBiller: (billerId: string) => void;
    modifyBiller: (
      customerId: string,
      mobile: string,
      email: string,
      billeraccountId: string,
      autopayStatus?: boolean,
      autopayAmount?: number,
      currency?: number,
      paymentAccount?: {
        paymentMethod: string;
        accountNumber?: string;
        ifsc?: string;
        cardStart?: string;
        cardEnd?: string;
        primeCardSerialNumber?: string;
      },
      isCreditCardSelected?: boolean,
      shortName?: string
    ) => void;
    getBillerAccounts: (customerId: string) => void;
    setBillerAccountStatusNotLoaded: () => void;
  };
}

interface AutoPayModalState {
  autoPayAmount: string;
  selectedAccount: AccountDetail | undefined;
  selectedCCAccount: CreditCardDetailData | undefined;
  isCreditCardSelected: boolean;
  billerData: Biller;
  selectedOpt: string;
  isMpinModalOpen: boolean;
  isOtpModalOpen: boolean;
  statusText: string;
  isSuccessModalOpen: boolean;
  isChecked: boolean;
  isAmountUpdated: boolean;
  isTechnicalErrorOpen: boolean;
}

class AutoPayModal extends Component<AutoPayModalProps, AutoPayModalState> {
  constructor(props: AutoPayModalProps) {
    super(props);
    this.state = {
      autoPayAmount: "",
      selectedAccount: undefined,
      selectedCCAccount: undefined,
      isCreditCardSelected: false,
      billerData: {} as Biller,
      selectedOpt: "autoPayFull",
      isMpinModalOpen: false,
      isOtpModalOpen: false,
      statusText: "",
      isSuccessModalOpen: false,
      isChecked: false,
      isAmountUpdated: true,
      isTechnicalErrorOpen: false,
    };
  }

  componentDidMount(): void {
    const { actions, billerAccount, isOpen } = this.props;

    if (isOpen) {
      actions.loadBiller(billerAccount.billerId);
    }
  }

  componentDidUpdate(
    prevProps: AutoPayModalProps,
    prevState: AutoPayModalState
  ): void {
    const {
      actions,
      billerAccount,
      billerDetailData: { billerDetailData, billerStatus },
      modifyBillerData: { modifyRegisteredBillerData },
      isOpen,
    } = this.props;

    if (
      billerStatus === DataStatus.LOADED &&
      billerDetailData &&
      prevProps.billerDetailData.billerStatus !==
        this.props.billerDetailData.billerStatus
    ) {
      this.setState({ billerData: billerDetailData });
    }
    if (
      billerStatus === DataStatus.ERRORED &&
      billerDetailData &&
      prevProps.billerDetailData?.billerStatus !==
        this.props.billerDetailData?.billerStatus
    ) {
      this.setState({ isTechnicalErrorOpen: true });
    }
    if (
      modifyRegisteredBillerData.status === DataStatus.LOADED &&
      modifyRegisteredBillerData.data &&
      prevProps.modifyBillerData.modifyRegisteredBillerData.status !==
        this.props.modifyBillerData.modifyRegisteredBillerData.status
    ) {
      this.setState({ isSuccessModalOpen: true });
    }

    if (
      modifyRegisteredBillerData.status === DataStatus.ERRORED &&
      prevProps.modifyBillerData.modifyRegisteredBillerData.status !==
        this.props.modifyBillerData.modifyRegisteredBillerData.status
    ) {
      if (modifyRegisteredBillerData.error?.statusCode === 500) {
        this.setState({ isTechnicalErrorOpen: true });
      } else if (modifyRegisteredBillerData.error) {
        commonErrorHandler(modifyRegisteredBillerData.error);
      }
    }

    if (
      billerAccount?.autopayStatus &&
      billerAccount?.autopayAmount &&
      this.state.isAmountUpdated
    ) {
      this.setState({
        autoPayAmount: billerAccount?.autopayAmount.toLocaleString("en-in"),
        isAmountUpdated: false,
      });
    }
  }

  handleChange = (event: any) => {
    if (this.state.selectedOpt !== event.target.value) {
      this.setState({ selectedOpt: event.target.value });
      this.props.setFullPay(event.target.value === "autoPayFull");
    }
  };

  getMinMaxAutoPayLimitForCreditCard = (min: boolean) => {
    const {
      billerAccount,
      billerDetailData: { billerDetailData },
    } = this.props;

    const { isCreditCardSelected, billerData } = this.state;
    let maxLimit;
    let minLimit;
    if (
      isCreditCardSelected &&
      billerDetailData?.isBillerBBPS &&
      billerDetailData.billerCategory.toLowerCase() ===
        "Insurance".toLowerCase()
    ) {
      maxLimit = process.env.CREDIT_CARD_AUTO_MAX_LIMIT_INSURANCE;
      minLimit = process.env.CREDIT_CARD_AUTO_MIN_LIMIT_INSURANCE;
    } else if (
      isCreditCardSelected &&
      billerDetailData.billerCategory.toLowerCase() !==
        "Insurance".toLowerCase()
    ) {
      maxLimit = process.env.CREDIT_CARD_AUTO_MAX_LIMIT;
      minLimit = process.env.CREDIT_CARD_AUTO_MIN_LIMIT;
    }
    return min ? Number(minLimit) : Number(maxLimit);
  };

  onAuthClose = () => {
    this.setState({
      isMpinModalOpen: false,
      isOtpModalOpen: false,
    });
  };

  onAuthSuccess = () => {
    const {
      isFullPay,
      customerData: { customerId, mobile, emailId, channelId },
      billerDetailData: { billerDetailData },
      billerAccount,
      actions,
      typeOfOperation,
    } = this.props;
    const {
      selectedAccount,
      selectedCCAccount,
      autoPayAmount,
      isCreditCardSelected,
    } = this.state;
    if (
      (selectedAccount || selectedCCAccount) &&
      (autoPayAmount || isFullPay)
    ) {
      //Making Modify Biller Call here
      const paymentAccount =
        isCreditCardSelected && selectedCCAccount
          ? mapCardDetailToPaymentAccount(selectedCCAccount, false)
          : {
              paymentMethod: selectedAccount?.paymentMethod,
              accountNumber: selectedAccount?.acid,
              ifsc: selectedAccount?.ifscCode,
            };
      const currency =
        selectedAccount?.currencyCode === "INR" || selectedCCAccount
          ? "356"
          : selectedAccount?.currencyCode;

      this.setState({
        statusText:
          typeOfOperation === "Edit Autopay"
            ? ManageBillerSuccessModalStatusText.editAutoPay
            : ManageBillerSuccessModalStatusText.enableAutopay,
      });

      const amount = isFullPay
        ? minMaxAmountAutopayModal(false, billerDetailData, channelId).toFixed(
            2
          )
        : Number.parseFloat(autoPayAmount).toFixed(2);
      actions.modifyBiller(
        customerId,
        mobile,
        emailId,
        billerAccount.billerAccountId,
        true,
        amount,
        currency,
        paymentAccount,
        isCreditCardSelected
      );
    } else {
      enqueueNotistack({
        message: "Something went wrong",
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        style: { paddingBottom: "var(--ds-spacing-cool)" },
      });
    }

    this.onAuthClose();
  };

  onAuthError = (error?: ApiResponseErrorImpl) => {
    enqueueNotistack({
      message: error?.message || "Authentication failed. Please try again",
      variant: "error",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      style: { paddingBottom: "var(--ds-spacing-cool)" },
    });
  };

  handleMpinClose = () => {
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "mpin verification",
      "back"
    );
    this.setState({ isMpinModalOpen: false });
  };

  handleOTPClose = () => {
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "otp verification",
      "back"
    );
    this.setState({ isOtpModalOpen: !this.state.isOtpModalOpen });
  };

  handleAutopayModalClose = () => {
    const { isFullPay } = this.props;

    const label = isFullPay
      ? "pay full bill amount | cancel"
      : "set autopay limit | cancel";
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "set auto pay limit",
      label
    );
    this.props.onClose();
  };

  handleSubmit = () => {
    const {
      customerData: { channelId },
      isFullPay,
    } = this.props;
    const { isCreditCardSelected, autoPayAmount } = this.state;

    const label = isFullPay
      ? "pay full bill amount"
      : `set autopay limit | ${Number.parseFloat(autoPayAmount)}`;
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "set auto pay limit",
      label
    );

    if (
      channelId === CustomerChannelEnum.MobileBanking &&
      !isCreditCardSelected
    ) {
      this.setState({ isMpinModalOpen: true });
    } else {
      this.setState({ isOtpModalOpen: true });
    }
  };

  handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isChecked: event.target.checked,
    });
  };

  generateOptions = () => {
    const {
      billerDetailData: { billerDetailData, billerStatus },
      customerData: { channelId },
    } = this.props;

    const { isCreditCardSelected } = this.state;

    let options: any[] = [];

    if (billerStatus === DataStatus.LOADED && billerDetailData) {
      options = [
        {
          value: "autoPayFull",
          heading: "Pay Full Bill Amount",
          description: isCreditCardSelected
            ? `We will automatically pay your bills four days prior to the bill due date. For auto payment instructions through credit card, bills greater than Rs. 15,000 will have to be paid manually`
            : `We will automatically pay your bills four days prior to the bill due date`,
        },
        {
          value: "autoPayPartial",
          heading: "Set Autopay Limit",
          description: isCreditCardSelected
            ? `Maximum autopay limit for payment via credit card is ${localeAmountFormat(
                this.getMinMaxAutoPayLimitForCreditCard(false)
              )}; and in case of payments via savings or current account it is ${localeAmountFormat(
                minMaxAmountAutopayModal(false, billerDetailData, channelId)
              )}`
            : `Maximum autopay limit for payment via savings or current account is ${localeAmountFormat(
                minMaxAmountAutopayModal(false, billerDetailData, channelId)
              )}`,
        },
      ];
    }

    return options;
  };
  handleAmountChange = (value: string) => {
    if (this.state.autoPayAmount !== value) {
      this.setState({ autoPayAmount: value });
    }
  };

  render() {
    const {
      isOpen,
      onClose,
      title,
      billerAccount,
      typeOfOperation,
      isFullPay,
      setFullPay,
      customerData: { channelId, customerId },
      billerDetailData: { billerDetailData },
      navigate,
      actions,
    } = this.props;
    const {
      isCreditCardSelected,
      autoPayAmount,
      selectedOpt,
      isSuccessModalOpen,
      isChecked,
      selectedAccount,
      selectedCCAccount,
      statusText,
    } = this.state;

    return (
      <FullModal
        isOpen={isOpen}
        onClose={this.handleAutopayModalClose}
        title=""
      >
        <DsBox>
          <DsBox padding="var(--ds-spacing-bitterCold)">
            <DsTypography variant="bodyBoldLarge">{title}</DsTypography>
          </DsBox>
          {billerDetailData && (
            <DsBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="var(--ds-spacing-glacial)"
              mt="var(--ds-spacing-mild)"
            >
              <DsAvatar
                src={billerDetailData.billerLogo}
                // alt={billerName.substring(0, 5)}
                sx={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  border: "1px solid #E2E2E2",
                  boxSizing: "initial",
                  height: "56px",
                  width: "56px",
                }}
              />

              <DsBox
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                gap="var(--ds-spacing-quickFreeze)"
              >
                <DsTypography variant="bodyBoldLarge">
                  {billerDetailData.billerName}
                </DsTypography>
                <DsTypography
                  variant="supportRegularMetadata"
                  sx={{
                    color: "var(--ds-colour-typoTertiary)",
                  }}
                >
                  {billerDetailData.billerId}
                </DsTypography>
              </DsBox>
            </DsBox>
          )}
        </DsBox>
        <DsBox sx={{ marginTop: "var(--ds-spacing-mild)" }}>
          {this.generateOptions().map((opt) => {
            return (
              <DsBox
                marginLeft="var(--ds-spacing-bitterCold)"
                marginRight="var(--ds-spacing-bitterCold)"
                marginBottom="var(--ds-spacing-mild)"
              >
                <DsCard
                  variant="outlined"
                  style={{
                    borderRadius: "8px",
                    padding: "var(--ds-spacing-frostbite)",
                    paddingBottom:
                      opt.value === "autoPayPartial"
                        ? "var(--ds-spacing-bitterCold)"
                        : "var(--ds-spacing-frostbite)",
                    borderColor:
                      selectedOpt === opt.value ? "#ED1164" : "#E2E2E2",
                  }}
                >
                  <DsBox
                    display="flex"
                    alignItems="center"
                    gap="var(--ds-spacing-frostbite)"
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                      },
                    }}
                  >
                    <DsRadio
                      checked={selectedOpt === opt.value}
                      onChange={this.handleChange}
                      value={opt.value}
                      sx={{
                        "& .MuiRadio-root": {
                          padding: "0px !important",
                        },
                      }}
                    />
                    <DsBox
                      display="flex"
                      flexDirection="column"
                      gap="var(--ds-spacing-quickFreeze)"
                    >
                      <DsTypography variant="bodyBoldMedium">
                        {opt.heading}
                      </DsTypography>
                      <DsTypography
                        variant="bodyRegularSmall"
                        sx={{
                          color: "var(--ds-colour-typoSecondary)",
                        }}
                      >
                        {opt.description}
                      </DsTypography>
                    </DsBox>
                  </DsBox>

                  <DsBox marginLeft="var(--ds-spacing-tepid)">
                    {opt.value === "autoPayPartial" ? (
                      <AmountFormField
                        defaultValue={this.state.autoPayAmount}
                        min={
                          isCreditCardSelected
                            ? this.getMinMaxAutoPayLimitForCreditCard(true)
                            : minMaxAmountAutopayModal(
                                true,
                                billerDetailData,
                                channelId
                              )
                        }
                        max={
                          isCreditCardSelected
                            ? this.getMinMaxAutoPayLimitForCreditCard(false)
                            : minMaxAmountAutopayModal(
                                false,
                                billerDetailData,
                                channelId
                              )
                        }
                        onAmountChange={(value) => {
                          this.setState({ autoPayAmount: value });
                        }}
                        infoText={
                          <DsBox>
                            <DsTypography>
                              Auto-payment will be processed whenever the bill
                              amount is up to the specified limit.
                            </DsTypography>
                            <DsTypography mt={3}>
                              We will notify you if your bill amount is more
                              than the autopay limit.
                            </DsTypography>
                          </DsBox>
                        }
                        selectedOption={selectedOpt}
                      />
                    ) : null}
                  </DsBox>
                </DsCard>
              </DsBox>
            );
          })}
        </DsBox>
        <BottomSticker
          sx={{
            padding: "var(--ds-spacing-bitterCold)",
            paddingBottom: "var(--ds-spacing-mild)",
          }}
        >
          <DsBox>
            <DsBox marginBottom="var(--ds-spacing-frostbite)">
              <DsBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <DsBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "var(--ds-spacing-glacial)",
                    " & .MuiCheckbox-root": {
                      padding: "var(--ds-spacing-zero)",
                      marginBottom: "auto",
                    },
                  }}
                >
                  <DsCheckbox
                    checked={this.state.isChecked}
                    onChange={this.handleCheckboxChange}
                    color="secondary"
                  />
                  <DsTypography
                    variant="bodyRegularSmall"
                    color={
                      this.state.isChecked
                        ? "var(--ds-colour-typoPrimary)"
                        : "var(--ds-colour-typoSecondary)"
                    }
                  >
                    I allow Axis Bank to share my account number with its
                    service provider to execute auto payments
                  </DsTypography>
                </DsBox>
              </DsBox>
            </DsBox>
            {isOpen &&
              this.props.billerDetailData.billerStatus ===
                DataStatus.LOADED && (
                <DsBox marginBottom="var(--ds-spacing-mild)">
                  <PayUsing
                    onClose={onClose}
                    gaOperation={"manage billers"}
                    biller={billerDetailData || ({} as Biller)}
                    isStandAloneAutoPayOrSchedulePay={true}
                    customerId={customerId}
                    defaultAccount={billerAccount.paymentAccount?.accountNumber}
                    defaultCCAccount={billerAccount?.paymentAccount?.cardEnd}
                    onAccountSelect={(acc) => {
                      this.setState({
                        selectedAccount: acc,
                        isCreditCardSelected: false,
                      });
                    }}
                    onCCAccountSelect={(ccAcc) => {
                      this.setState({
                        selectedCCAccount: ccAcc,
                        isCreditCardSelected: true,
                      });
                    }}
                    onError={(error) => {
                      // TODO: push to something went wrong
                    }}
                    isAutopayOpen={true}
                    paymentAmount={
                      isFullPay
                        ? minMaxAmountAutopayModal(
                            false,
                            billerDetailData,
                            channelId
                          )
                        : Number.parseFloat(autoPayAmount) || 0
                    }
                    isFromPaymentPage={false}
                  />
                </DsBox>
              )}

            <DsBox
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DsBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  width: "100%",
                  " & .MuiButton-root": {
                    zIndex: 0,
                  },
                }}
              >
                <DsButton
                  color="secondary"
                  sx={{
                    width: "100%",
                  }}
                  size="large"
                  onClick={() => onClose()}
                >
                  Cancel
                </DsButton>
                <DsButton
                  sx={{
                    width: "100%",
                  }}
                  size="large"
                  onClick={this.handleSubmit}
                  disabled={
                    isFullPay
                      ? (!(selectedAccount && selectedAccount.acid) &&
                          !isCreditCardSelected) ||
                        !isChecked
                      : isCreditCardSelected
                      ? Number.parseFloat(autoPayAmount || "0") <
                          this.getMinMaxAutoPayLimitForCreditCard(true) ||
                        Number.parseFloat(autoPayAmount || "0") >
                          this.getMinMaxAutoPayLimitForCreditCard(false) ||
                        !isChecked
                      : Number.parseFloat(autoPayAmount || "0") <
                          minMaxAmountAutopayModal(
                            true,
                            billerDetailData,
                            channelId
                          ) ||
                        Number.parseFloat(autoPayAmount || "0") >
                          minMaxAmountAutopayModal(
                            false,
                            billerDetailData,
                            channelId
                          ) ||
                        (!(selectedAccount && selectedAccount.acid) &&
                          !isCreditCardSelected) ||
                        !isChecked
                  }
                >
                  Confirm
                </DsButton>
              </DsBox>
            </DsBox>
          </DsBox>
        </BottomSticker>

        <MpinModal
          isOpen={this.state.isMpinModalOpen}
          onClose={() => {
            this.setState({ isMpinModalOpen: false });

            handleGAPush(
              CategoryHelperObject.categoryPayBills + "manage billers",
              "mpin verification",
              "back"
            );
          }}
          onSuccess={this.onAuthSuccess}
          onFailure={this.onAuthError}
          eventCategory={"manage billers"}
        />

        <OtpModal
          isOpen={this.state.isOtpModalOpen}
          onClose={this.handleOTPClose}
          onSuccess={this.onAuthSuccess}
          onFailure={this.onAuthError}
          eventCategory={"manage billers"}
        />
        {isSuccessModalOpen && (
          <SuccessStatusModal
            isOpen={isSuccessModalOpen}
            onClose={() => {
              onClose();
              actions.getBillerAccounts(this.props.customerData.customerId);
              navigate(APP_ROUTES.HOME.pathname);
            }}
            statusText={statusText}
            duration={3000}
          />
        )}

        <TechnicalErrorDrawer
          isOpen={this.state.isTechnicalErrorOpen}
          onClose={() => this.setState({ isTechnicalErrorOpen: false })}
          title="We are experiencing technical difficulties."
          description="Please try again later."
          onClick={() => {
            this.props.navigate(APP_ROUTES.HOME.pathname);
          }}
        />

        {(this.props.modifyBillerData?.modifyRegisteredBillerData?.status ===
          DataStatus.LOADING ||
          this.props.billerDetailData.billerStatus === DataStatus.LOADING) && (
          <OverlayLoader />
        )}
      </FullModal>
    );
  }
}

const mapStateToProps = (
  state: RootState & {
    customerId: string;
  }
) => {
  const customerData = getCustomerData(state);
  const billerDetailData = billersSelector(state);
  const modifyBillerData = getModifyRegisteredBillerSelector(state);

  return {
    customerData,
    billerDetailData,
    modifyBillerData,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  actions: {
    loadBiller: (billerId: string) => dispatch(getBiller(billerId)),
    modifyBiller: (
      customerId: string,
      mobile: string,
      email: string,
      billeraccountId: string,
      autopayStatus?: boolean,
      autopayAmount?: number,
      currency?: number,
      paymentAccount?: {
        paymentMethod: string;
        accountNumber?: string;
        ifsc?: string;
        cardStart?: string;
        cardEnd?: string;
        primeCardSerialNumber?: string;
      },
      isCreditCardSelected?: boolean,
      shortName?: string
    ) =>
      dispatch(
        getModifyRegisteredBillerListThunk(
          customerId,
          mobile,
          email,
          billeraccountId,
          autopayStatus,
          autopayAmount,
          currency,
          paymentAccount,
          isCreditCardSelected,
          shortName
        )
      ),
    getBillerAccounts: (customerId: string) =>
      dispatch(getDueBills(customerId)),
    setBillerAccountStatusNotLoaded: () =>
      dispatch(setBillerAccountStatusNotLoaded(DataStatus.NOT_LOADED)),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoPayModal)
);
