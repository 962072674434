import { useToggle } from "~/src/Common/hooks/useToggle";
import React, { ReactNode } from "react";
import {
  DsTooltip,
  DsRemixIcon,
  DsIconButton,
} from "@am92/react-design-system";
// import { IconButton, Icon, Tooltip } from '@chakra-ui/react'
// import { RiInformationLine } from 'react-icons/ri'
type InfoPopoverProps = {
  infoText: ReactNode;
};

export const InfoPopover = ({ infoText }: InfoPopoverProps) => {
  const [isOpen, toggleOpen, setIsOpen] = useToggle();
  return (
    <DsTooltip
      title={infoText}
      placement="bottom-end"
      hasArrow
      bg="gray-100"
      fontSize="0.75rem"
      borderRadius="lg"
    >
      <DsIconButton>
        <DsRemixIcon className="ri-information-line" fontSize="small" />
      </DsIconButton>
    </DsTooltip>
  );
};
