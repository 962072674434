import React, {
  FC,
  useState,
  ReactElement,
  forwardRef,
  ReactNode,
} from "react";
import {
  DsButton,
  DsRemixIcon,
  DsTypography,
  DsBox,
  DsTextField,
  DsMenuItem,
  DsFormControl,
  DsSelect,
  DsAvatar,
  DsToggle,
  DsToggleProps,
  DsToggleDefaultProps,
  DsDivider,
} from "@am92/react-design-system";

type InfoCardToggleProps = {
  label: string;
  header?: string;
  bottomElement?: ReactNode;
  checkedIcon: boolean;
  onChange: (name: string, value: any) => void;
} & DsToggleProps;

export const InfoCardToggle = forwardRef<HTMLInputElement, InfoCardToggleProps>(
  (
    {
      label,
      header,
      bottomElement,
      checkedIcon,
      onChange,
      ...toggleProps
    }: InfoCardToggleProps,
    ref
  ): ReactElement => {
    return (
      <DsBox
        border={"1px solid var(--ds-colour-strokeDefault)"}
        borderRadius={"10px"}
      >
        <DsBox display={"flex"} padding={4}>
          <DsBox
            display={"flex"}
            flexDirection={"column"}
            sx={(theme) => ({
              [theme.breakpoints.up("sm")]: {
                width: "70%",
              },
            })}
          >
            {header && (
              <DsTypography variant="bodyBoldMedium">{header}</DsTypography>
            )}

            {checkedIcon && (
              <DsTypography
                variant="bodyRegularSmall"
                sx={{
                  color:
                    "var(--light-typography-primary-sz-colour-typo-primary",
                  //   borderBottom: '1px solid var(--ds-colour-strokeDefault)'
                }}
              >
                {label}
              </DsTypography>
            )}

            {/* <hr style={{ padding: '4px' }}></hr> */}
            {!checkedIcon && (
              <DsTypography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color:
                    "var(--light-typography-primary-sz-colour-typo-primary",
                }}
              >
                {label}
              </DsTypography>
            )}
          </DsBox>

          <DsBox width={"45%"} textAlign={"end"}>
            <DsToggle
              // checked={checkedIcon}
              name=""
              value={checkedIcon}
              onChange={onChange}
              // {...toggleProps}
              // ref={ref}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked:hover": {
                  backgroundColor: "#fefefe",
                },
              }}
            ></DsToggle>
          </DsBox>
        </DsBox>
        {checkedIcon && (
          <DsDivider
            orientation="horizontal"
            sx={{
              color: "var(--ds-colour-stateDisabledSurface)",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          ></DsDivider>
        )}
        {bottomElement && <DsBox> {bottomElement}</DsBox>}
      </DsBox>
    );
  }
);

InfoCardToggle.displayName = "InfoCardToggle";
