import React, { Component, ReactElement } from "react";
import {
  DsBox,
  DsButton,
  DsOtp,
  DsStack,
  DsTypography,
} from "@am92/react-design-system";
import withRouter, { IWithRouterProps } from "~/src/Lib/withRouter";
import { ApiResponseErrorImpl } from "~/src/Lib/types/api";
import { FullModal } from "~/src/components/FullModal";
import { BottomSticker } from "~/src/components/BottomSticker";
import { MPIN_OTP } from "~/src/Common/constants/util";
import { RootState } from "~/src/Configurations/AppStore";
import {
  getCustomerData,
  getCustomerId,
} from "~/src/Redux/Customer/Customer.selector";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { validateMpinListThunk } from "~/src/Redux/Payment/Services/Mpin.service";
import { connect } from "react-redux";
import Instore from "~/src/Configurations/InStore";
// import { encryptPayload } from "common/network/cryptography";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { ErrorText } from "./AttempsError";
import { attemptText } from "../Payment.helper";
import { getMpinSelector } from "~/src/Redux/Payment/Selectors/Mpin.selector";
import { MpinListState } from "~/src/Redux/Payment/Reducers/Mpin.reducers";
import { DataStatus } from "~/src/Lib/types/datatransfer";
import OverlayLoader from "~/src/components/OverlayLoader";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";

interface MpinModalProps extends IWithRouterProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onFailure?: (error?: ApiResponseErrorImpl) => void;
  customerId: string;
  mpinData: MpinListState;
  actions: {
    validateMpin: (customerId: string, mpin: string) => void;
  };
  eventCategory?: string;
}

interface MpinModalState {
  pin: string;
  isInvalid: boolean;
  attempts: number;
  errorMsg: string;
  value: string;
}

class MpinModal extends Component<MpinModalProps, MpinModalState> {
  constructor(props: MpinModalProps) {
    super(props);
    this.state = {
      pin: "",
      isInvalid: false,
      attempts: MPIN_OTP.MAX_ATTEMPS_MPIN,
      errorMsg: "",
      value: "",
    };
  }

  onFail = (error?: any) => {
    const { navigate, onFailure } = this.props;
    const { attempts } = this.state;
    console.log("MPIN error", error);
    if (error && error.errorObj && error.errorObj.data) {
      const errorData = (
        (error as ApiResponseErrorImpl).errorObj as {
          data: { respCode: string; respDesc: string };
        }
      ).data;

      if (errorData.respCode) {
        const errorCode = Number.parseInt(errorData.respCode);
        if (errorCode < 5) {
          const resolveError = 5 - errorCode;
          this.setState({ errorMsg: attemptText(resolveError, "mPIN") });
          // setAttempts(attempts);
        } else if (errorCode === 16) {
          this.setState({ attempts: 0, errorMsg: errorData.respDesc });
          navigate(APP_ROUTES.ACCOUNT_LOCKED.pathname);
          return;
        } else {
          if (errorCode === 5) {
            this.setState({ attempts: 0 });
          }
          this.setState({
            errorMsg: `Invalid PIN: ${attempts} attempt(s) remaining`,
          });
        }
      }
    }

    this.setState({ isInvalid: true });
    if (attempts === 0) {
      // onFailure(); // TODO: when error
      if (onFailure) {
        onFailure(error);
      }
    }
  };

  componentDidUpdate(
    prevProps: MpinModalProps,
    prevState: MpinModalState
  ): void {
    const { mpinData, onSuccess, eventCategory, isOpen } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        // GA events for otp
        handleGAPush(
          CategoryHelperObject.categoryPayBills + eventCategory?.toLowerCase(),
          "pageload",
          "MPIN verification"
        );
      }
    }

    if (
      mpinData.mpinDetailData.status !==
      prevProps.mpinData.mpinDetailData.status
    ) {
      if (mpinData.mpinDetailData.status === DataStatus.LOADING) {
        this.setState({ errorMsg: "" });
      }
    }

    // Check if mpinStatus has changed and is now ERRORED
    if (
      mpinData.mpinDetailData.status !==
        prevProps.mpinData.mpinDetailData.status &&
      mpinData.mpinDetailData.status === DataStatus.ERRORED
    ) {
      // Focus on the input field
      const inputField = document.getElementById("firstInputField");
      if (inputField) {
        inputField.focus();
      }

      // Clear the value
      this.setState({ value: "", pin: "" });

      // Call onFail with the mpinError

      const mpinErrorObj = new ApiResponseErrorImpl(
        mpinData.mpinDetailData.error?.statusCode,
        mpinData.mpinDetailData.error?.status,
        mpinData.mpinDetailData.error?.message,
        mpinData.mpinDetailData.error?.error
      );

      this.onFail(mpinErrorObj);
    }

    // Check if mpinStatus has changed and is now LOADED
    if (
      mpinData.mpinDetailData.status !==
        prevProps.mpinData.mpinDetailData.status &&
      mpinData.mpinDetailData.status === DataStatus.LOADED &&
      mpinData
    ) {
      // Check if mpinData is valid
      if (mpinData.mpinDetailData.data?.isValid) {
        // Call onSuccess and clear error message
        onSuccess();
        this.setState({ errorMsg: "" });
        handleGAPush(
          CategoryHelperObject.categoryPayBills + eventCategory?.toLowerCase(),
          "payment success",
          "MPIN success"
        );
      } else {
        // Focus on the input field if available
        const inputField = document.getElementById("firstInputField");
        if (inputField) {
          inputField.focus();
        }

        // Clear the value
        this.setState({ value: "" });

        // Call onFail with a new ApiResponseErrorImpl instance
        this.onFail(
          new ApiResponseErrorImpl(
            500,
            "Server Error",
            "Something went wrong, try again later"
          )
        );
      }
    }
  }

  handleClose = () => {
    const { onClose } = this.props;

    onClose();

    this.setState({
      isInvalid: false,
      errorMsg: "",
      value: "",
    });
  };

  handleMpinChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: e.target.value, isInvalid: false });
  }

  render() {
    const { isOpen, onClose, actions, navigate, customerId } = this.props;
    const { pin, isInvalid, attempts, errorMsg, value } = this.state;
    return (
      isOpen && (
        <FullModal
          onClose={this.handleClose}
          isOpen={isOpen}
          title=""
          onBackClick={this.handleClose}
          navRightSection={null as unknown as ReactElement}
        >
          <DsBox
            m={4}
            display={"flex"}
            flexDirection={"column"}
            gap="var(--ds-spacing-glacial)"
          >
            {attempts !== 0 ? (
              <DsTypography variant="headingBoldMedium">
                Enter Your MPIN
              </DsTypography>
            ) : (
              <DsTypography variant="headingBoldMedium">
                Invalid MPIN
              </DsTypography>
            )}
            {attempts !== 0 ? (
              <DsTypography
                variant="bodyRegularLarge"
                sx={{ color: "var(--ds-colour-typoSecondary)" }}
              >
                Enter your 6-digit MPIN to authenticate
              </DsTypography>
            ) : (
              <DsBox display="flex" flexDirection="column">
                <DsTypography
                  variant="bodyRegularLarge"
                  sx={{ color: "var(--ds-colour-typoSecondary)" }}
                >
                  Attempts exhausted.
                </DsTypography>
                <DsTypography
                  variant="bodyRegularLarge"
                  sx={{ color: "var(--ds-colour-typoSecondary)" }}
                >
                  Your account is locked.
                </DsTypography>
              </DsBox>
            )}
            <DsBox sx={{ marginTop: "var(--ds-spacing-mild)" }}>
              <DsOtp
                length={6}
                sx={(theme) => ({
                  "& .MuiInputBase-root": {
                    [theme.breakpoints.down("sm")]: {
                      width: "40px",
                    },
                  },
                })}
                inputProps={{
                  inputMode: "numeric",
                }}
                type="password"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleMpinChange(e);
                }}
                onComplete={(pinVal) => {
                  this.setState({ pin: pinVal });
                  // const token = Instore.get("auth");
                  // if (process.env.NODE_ENV !== "development") {
                  //   navigate(APP_ROUTES.TECHNICAL_ERROR.pathname);
                  // }
                  // need to add encryption for mpin with @am92/web-http
                  // actions.validateMpin(
                  //   customerId,
                  //   encryptPayload(
                  //     pinVal,
                  //     process.env.NODE_ENV !== "development" ? token : ""
                  //   )
                  // );
                }}
                value={pin}
                disabled={attempts === 0 ? true : false}
              />
              {errorMsg && this.state.attempts > 1 ? (
                <DsBox sx={{ marginTop: "8px" }}>
                  <ErrorText error={errorMsg} />
                </DsBox>
              ) : null}
              {this.state.attempts === 1 && (
                <DsBox sx={{ marginTop: "8px" }}>
                  <ErrorText error="Invalid mPIN. One attempt remaining. Please enter the valid mPIN or your account will be locked." />
                </DsBox>
              )}
            </DsBox>
          </DsBox>
          <BottomSticker
            sx={{
              padding: "var(--ds-spacing-bitterCold)",
              paddingBottom: "var(--ds-spacing-mild)",
            }}
          >
            <DsBox>
              <DsButton
                sx={{ width: "100%" }}
                size="large"
                disabled={attempts !== 0 && pin.length !== 6}
                onClick={() => {
                  if (attempts === 0) {
                    navigate(APP_ROUTES.HOME.pathname);
                  } else {
                    actions.validateMpin(customerId, pin);
                  }
                  this.setState((prevState) => ({
                    attempts: prevState.attempts - 1,
                  }));
                }}
              >
                {attempts === 0 ? "Home" : "Confirm MPIN"}
              </DsButton>
            </DsBox>
          </BottomSticker>

          {this.props.mpinData.mpinDetailData?.status ===
            DataStatus.LOADING && <OverlayLoader />}
        </FullModal>
      )
    );
  }
}

const mapStateToProps = (
  state: RootState & {
    customerId: string;
  }
) => {
  const customerId = getCustomerId(state);
  const customerData = getCustomerData(state);
  const mpinData = getMpinSelector(state);

  return {
    customerId,
    customerData,
    mpinData,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  actions: {
    validateMpin: (customerId: string, mpin: string) =>
      dispatch(validateMpinListThunk(customerId, mpin)),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MpinModal)
);
