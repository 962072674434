import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Radio from '@mui/material/Radio';
import { DsRadioDefaultProps } from './DsRadio.Types';
import { DsFormControlLabel } from '../DsFormControlLabel';
import { DsRemixIcon } from '../DsRemixIcon';
export class DsRadio extends PureComponent {
    static defaultProps = DsRadioDefaultProps;
    render() {
        const { disabled, RadioProps, ...restProps } = this.props;
        return (_jsx(_Fragment, { children: _jsx(DsFormControlLabel, { ...restProps, disabled: disabled, control: _jsx(Radio, { icon: _jsx(DsRemixIcon, { className: "ri-checkbox-blank-circle-line", sx: { fontSize: 'var(--ds-typo-fontSizeCool)' } }), checkedIcon: _jsx(DsRemixIcon, { className: "ri-radio-button-line", sx: { fontSize: 'var(--ds-typo-fontSizeCool)' } }), color: "secondary", disabled: disabled, ...RadioProps }) }) }));
    }
}
