import {
  DsBox,
  DsButton,
  DsChip,
  DsOtp,
  DsRemixIcon,
  DsTypography,
  enqueueNotistack,
} from "@am92/react-design-system";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, {
  Component,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import texts from "~/src/Common/constants/texts";
import { MPIN_OTP } from "~/src/Common/constants/util";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { BottomSticker } from "~/src/components/BottomSticker";
import { FullModal } from "~/src/components/FullModal";
import OverlayLoader from "~/src/components/OverlayLoader";
import { RootState } from "~/src/Configurations/AppStore";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { ApiResponseErrorImpl } from "~/src/Lib/types/api";
import { DataStatus } from "~/src/Lib/types/datatransfer";
import withRouter, { IWithRouterProps } from "~/src/Lib/withRouter";
import { getCustomerData } from "~/src/Redux/Customer/Customer.selector";
import { CustomerDetail } from "~/src/Redux/Customer/Model";
import {
  hideLoader,
  showLoader,
} from "~/src/Redux/Loaders/Reducers/Loaders.reducers";
import { AccountDetailsListState } from "~/src/Redux/Payment/Reducers/AccountDetails.reducers";
import { SendOtpListState } from "~/src/Redux/Payment/Reducers/SendOtp.reducers";
import { ValidateOtpListState } from "~/src/Redux/Payment/Reducers/ValidateOtp.reducers";
import { getAccountDetailsSelector } from "~/src/Redux/Payment/Selectors/AccountDetails.selector";
import { getSendOtpSelector } from "~/src/Redux/Payment/Selectors/SendOtp.selector";
import { getValidateOtpSelector } from "~/src/Redux/Payment/Selectors/ValidateOtp.selector";
import {
  sendOtpListThunk,
  validateOtpListThunk,
} from "~/src/Redux/Payment/Services/Otp.service";

type OtpModalActions = {
  sendOtp: (
    mobile: string,
    emailId: string,
    customerName?: string,
    paymentAmount?: string
  ) => void;
  validateOtp: (refId: string, otp: string) => void;
  showLoader: () => void;
  hideLoader: () => void;
};

interface OtpModalProps extends IWithRouterProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onFailure: (error?: ApiResponseErrorImpl) => void;
  eventCategory: string;
  actions: OtpModalActions;
  sendOtpListData: SendOtpListState;
  validateOtpData: ValidateOtpListState;
  customerData: CustomerDetail;
  title: string;
  onBackClick?: () => void;
  paymentAmount?: string;
  isCreditCardSelected?: boolean;
  accountDetailsData: AccountDetailsListState;
  loaderStatus: boolean;
}

interface OtpModalState {
  value: string;
  pin: string;
  otpRefId: string;
  timer: number;
  attempts: number;
  resendAttempts: number;
  isResendButtonClicked: boolean;
}

function OtpModal({
  isOpen,
  onClose,
  onSuccess,
  onFailure,
  eventCategory,
  actions,
  sendOtpListData: { sendOtpListData },
  validateOtpData: { validateOtpData },
  customerData: { mobile, emailId },
  title,
  onBackClick,
  paymentAmount,
  isCreditCardSelected,
  accountDetailsData,
  loaderStatus,
  navigate,
}: OtpModalProps) {
  const [state, setState] = useState<OtpModalState>({
    value: "",
    pin: "",
    otpRefId: "",
    timer: MPIN_OTP.MAX_RESEND_TIMER,
    attempts: MPIN_OTP.MAX_ATTEMPS_OTP,
    resendAttempts: MPIN_OTP.MAX_ATTEMPS_RESEND_OTP,
    isResendButtonClicked: false,
  });

  const timeRef = useRef<any>(null);

  const firstInputFieldRef = useRef();

  const startTimer = () => {
    if (timeRef?.current) {
      clearInterval(timeRef.current);
    }
    if (state.resendAttempts > 0) {
      timeRef.current = setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          timer: prevState.timer - 1,
        }));
      }, 1000);
    }
  };

  const resertTimer = () => {
    if (timeRef.current) {
      clearInterval(timeRef.current);
      timeRef.current = null;
    }
    setState({ ...state, timer: MPIN_OTP.MAX_RESEND_TIMER });
  };

  const onFail = (error?: any) => {
    if (state.attempts === 0) {
      onFailure && onFailure(error);
    }
  };

  const callGetOtpApi = () => {
    actions.showLoader();
    const custName =
      (accountDetailsData?.accountDetailsData?.data?.CustomerDetails &&
        accountDetailsData?.accountDetailsData?.data?.CustomerDetails?.length >
          0 &&
        accountDetailsData?.accountDetailsData?.data?.CustomerDetails[0]
          ?.customerName) ||
      "Customer";
    const customerName = !isCreditCardSelected ? custName : "Customer";
    if (paymentAmount && custName) {
      actions.sendOtp(mobile, emailId, customerName, paymentAmount);
    } else {
      actions.sendOtp(mobile, emailId);
    }
  };

  useEffect(() => {
    if (isOpen) {
      callGetOtpApi();
      handleGAPush(
        CategoryHelperObject.categoryPayBills + eventCategory.toLowerCase(),
        "pageload",
        "OTP verification"
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (sendOtpListData?.status === DataStatus.LOADED) {
        actions.hideLoader();
        if (sendOtpListData?.data?.data?.refId) {
          setState((prevState) => ({
            ...prevState,
            resendAttempts: prevState.resendAttempts - 1,
            otpRefId: sendOtpListData?.data?.data?.refId,
          }));
          startTimer();
          enqueueNotistack({
            message: texts.sendOtpSuccess,
            variant: "success",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            style: { paddingBottom: "var(--ds-spacing-warm)" },
          });
        } else {
          resertTimer();
          enqueueNotistack({
            message: texts.sendOtpFail,
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            style: { paddingBottom: "var(--ds-spacing-warm)" },
          });
        }
      }
      if (sendOtpListData?.status === DataStatus.LOADING) {
        actions.showLoader();
        resertTimer();
      }

      if (
        sendOtpListData?.status === DataStatus.ERRORED &&
        sendOtpListData?.error
      ) {
        setState({ ...state, value: "" });
        actions.hideLoader();
        resertTimer();
        enqueueNotistack({
          message: sendOtpListData?.error?.message || texts.sendOtpFail,
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          style: { paddingBottom: "var(--ds-spacing-warm)" },
        });
      }
    }
  }, [sendOtpListData.status]);

  useEffect(() => {
    if (isOpen) {
      if (validateOtpData.status === DataStatus.LOADING) {
        actions.showLoader();
      }

      if (validateOtpData.status === DataStatus.ERRORED) {
        actions.hideLoader();

        const validateOtpErrorObj = new ApiResponseErrorImpl(
          validateOtpData.error?.statusCode,
          validateOtpData.error?.status,
          validateOtpData.error?.message,
          validateOtpData.error?.error
        );
        onFail(validateOtpErrorObj);
        firstInputFieldRef?.current && firstInputFieldRef?.current.focus();
        setState((prevState) => ({
          ...prevState,
          value: "",
          pin: "",
          attempts: prevState.attempts - 1,
        }));
      }

      if (validateOtpData?.status === DataStatus.LOADED) {
        actions.hideLoader();
        if (validateOtpData?.data?.data?.isValid) {
          onSuccess();
          handleGAPush(
            CategoryHelperObject.categoryPayBills + eventCategory.toLowerCase(),
            "otp verification",
            "OTP success"
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            attempts: prevState.attempts - 1,
          }));
          enqueueNotistack({
            message: texts.verifyOtpFail,
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            style: { paddingBottom: "var(--ds-spacing-warm)" },
          });
          if (state.attempts === 0) {
            setState((prevState) => ({
              ...prevState,
              value: "",
            }));

            onFailure(
              new ApiResponseErrorImpl(
                400,
                texts.verifyOtpFail,
                texts.verifyOtpFail
              )
            );
          }
        }
      }
    }
  }, [validateOtpData.status]);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, value: e.target.value });
  };

  const maskPhoneNumber = (phoneNumber: string): string => {
    const lastFourDigits = phoneNumber.slice(-4);
    const maskedPart = "*".repeat(phoneNumber.length - 4);
    return `${maskedPart}${lastFourDigits}`;
  };

  const { otpRefId, value, attempts, resendAttempts, timer, pin } = state;

  return (
    isOpen && (
      <FullModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          resertTimer();
        }}
        title={title}
        onBackClick={() => {
          onClose();
          resertTimer();
        }}
        navRightSection={null as unknown as ReactElement}
      >
        {!loaderStatus && (
          <DsBox
            m={6}
            display={"flex"}
            flexDirection={"column"}
            gap="var(--ds-spacing-glacial)"
          >
            {attempts !== 0 ? (
              <DsTypography variant="headingBoldMedium">
                Enter Your OTP
              </DsTypography>
            ) : (
              <DsTypography variant="headingBoldMedium">
                Verification Failed
              </DsTypography>
            )}
            {attempts !== 0 ? (
              <DsTypography
                variant="bodyRegularLarge"
                sx={{ color: "var(--ds-colour-typoSecondary)" }}
              >
                We have sent a 6-digit OTP to your registered mobile.
              </DsTypography>
            ) : (
              <DsBox display="flex" flexDirection="column">
                <DsTypography
                  variant="bodyRegularLarge"
                  sx={{ color: "var(--ds-colour-typoSecondary)" }}
                >
                  Maximum OTP limit exhausted.
                </DsTypography>
                <DsTypography
                  variant="bodyRegularLarge"
                  sx={{ color: "var(--ds-colour-typoSecondary)" }}
                >
                  Your account is locked. Please try again later after some
                  time.
                </DsTypography>
              </DsBox>
            )}
            <DsBox
              sx={{ marginTop: "var(--ds-spacing-mild)" }}
              display="flex"
              flexDirection="column"
              gap="var(--ds-spacing-glacial)"
            >
              <DsBox display="flex" alignItems="center" gap="10px">
                <DsBox
                  padding="5px"
                  sx={{
                    backgroundColor: "var(--ds-colour-neutral1)",
                    borderRadius: "var(--ds-spacing-quickFreeze)",
                  }}
                >
                  <DsRemixIcon
                    className="ri-lock-password-line"
                    sx={{ overflow: "none !important" }}
                  />
                </DsBox>
                <DsTypography variant="bodyBoldMedium">
                  {`${maskPhoneNumber(mobile)}`}
                </DsTypography>
              </DsBox>
              <DsOtp
                ref={firstInputFieldRef}
                sx={(theme) => ({
                  "& .MuiInputBase-root": {
                    [theme.breakpoints.down("sm")]: {
                      width: "40px",
                    },
                  },
                })}
                type="password"
                size="small"
                length={6}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleOtpChange(e)
                }
                onComplete={(otpString) => {
                  setState({ ...state, pin: otpString });
                  actions.validateOtp(state.otpRefId, otpString);
                }}
                inputProps={{
                  inputMode: "numeric",
                }}
                value={value}
                disabled={attempts === 0 ? true : false}
              />
            </DsBox>
            <DsBox>
              {state.attempts === 0 ? (
                ""
              ) : attempts < MPIN_OTP.MAX_ATTEMPS_OTP ? (
                <DsTypography
                  variant="bodyRegularSmall"
                  sx={{
                    color: "var(--ds-colour-iconNegative)",
                  }}
                >
                  Incorrect OTP entered. Please enter valid OTP and try again.{" "}
                  {state.attempts < 3 && (
                    <DsTypography>
                      {state.attempts} attempt(s) remaining.
                    </DsTypography>
                  )}
                </DsTypography>
              ) : null}
            </DsBox>
            <DsBox sx={{ marginTop: "var(--ds-spacing-mild)" }}>
              {resendAttempts > 0 ? (
                <DsBox display="flex" alignItems="center">
                  <DsBox
                    sx={{
                      "& .MuiButton-root": {
                        paddingTop: "var(--ds-spacing-glacial)",
                        paddingBottom: "var(--ds-spacing-glacial)",
                        paddingLeft: "var(--ds-spacing-quickFreeze)",
                        paddingRight: "var(--ds-spacing-quickFreeze)",
                      },
                    }}
                  >
                    <DsButton
                      variant="text"
                      color="secondary"
                      disabled={
                        attempts === 0 ||
                        resendAttempts === 0 ||
                        (timer > 0 && timer < MPIN_OTP.MAX_RESEND_TIMER)
                      }
                      onClick={() => {
                        callGetOtpApi();
                        setState({ ...state, isResendButtonClicked: true });
                        handleGAPush(
                          CategoryHelperObject.categoryPayBills +
                            eventCategory.toLowerCase(),
                          "otp verification",
                          "resend otp"
                        );
                      }}
                    >
                      Resend Otp
                    </DsButton>
                  </DsBox>
                  {attempts > 0 &&
                  resendAttempts > 0 &&
                  timer > 0 &&
                  timer < MPIN_OTP.MAX_RESEND_TIMER ? (
                    <DsBox display="flex" alignItems="center">
                      <DsTypography
                        variant="bodyRegularSmall"
                        sx={{
                          color:
                            "var(--light-typography-tertiary-sz-colour-typo-tertiary, #9D9D9D)",
                        }}
                      >
                        {/* in ({timer}s) */}
                        {`(in 00:${timer})`}
                      </DsTypography>
                    </DsBox>
                  ) : null}
                </DsBox>
              ) : null}
            </DsBox>

            <DsBox
              display={"flex"}
              sx={{
                flexGrow: 1,
                borderRadius: "4px",
                marginTop: "var(--ds-spacing-mild)",
              }}
            >
              <DsChip
                label={
                  <DsBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="var(--ds-spacing-quickFreeze)"
                    sx={{
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                    }}
                  >
                    <DsRemixIcon
                      className="ri-shield-keyhole-line"
                      sx={{
                        fontSize: "14px",
                        color: "var(--ds-colour-iconDefault)",
                      }}
                    />
                    <DsTypography
                      variant="supportRegularInfo"
                      sx={{
                        color: "var(--ds-colour-typoSecondary)",
                      }}
                    >
                      We aim to ensure 100% security of data
                    </DsTypography>
                  </DsBox>
                }
                sx={{
                  backgroundColor: "var(--ds-colour-surfaceSecondary)",
                  textTransform: "none",
                  padding: "0px",
                }}
              />
            </DsBox>
          </DsBox>
        )}
        <BottomSticker
          sx={{
            padding: "var(--ds-spacing-bitterCold)",
            paddingBottom: "var(--ds-spacing-mild)",
          }}
        >
          <DsBox>
            <DsButton
              sx={{ width: "100%" }}
              size="large"
              disabled={
                attempts !== 0 &&
                (pin.length !== 6 ||
                  validateOtpData.status === DataStatus.LOADING)
              }
              onClick={() => {
                if (attempts === 0) {
                  navigate(APP_ROUTES.HOME.pathname);
                } else {
                  actions.validateOtp(otpRefId, pin);
                }
              }}
            >
              {attempts === 0 ? "Home" : "Confirm OTP"}
            </DsButton>
          </DsBox>
        </BottomSticker>
      </FullModal>
    )
  );
}

const mapStateToProps = (
  state: RootState & {
    sendOtpListData: SendOtpListState;
    validateOtpData: ValidateOtpListState;
  }
) => {
  const sendOtpListData = getSendOtpSelector(state);
  const customerData = getCustomerData(state);
  const validateOtpData = getValidateOtpSelector(state);
  const accountDetailsData = getAccountDetailsSelector(state);
  const loaderStatus = state?.loader.loading;

  return {
    sendOtpListData,
    validateOtpData,
    customerData,
    accountDetailsData,
    loaderStatus,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  actions: {
    sendOtp: (
      mobile: string,
      emailId: string,
      customerName?: string,
      paymentAmount?: string
    ) =>
      dispatch(sendOtpListThunk(mobile, emailId, customerName, paymentAmount)),
    validateOtp: (refId: string, otp: string) =>
      dispatch(validateOtpListThunk(refId, otp)),

    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtpModal)
);
