import React, { FC } from "react";
import {
  DsBottomSheet,
  DsBox,
  DsButton,
  DsImage,
  DsImageProps,
  DsTypography,
} from "@am92/react-design-system";
import images from "../Constants/images";

type BottomSheetDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  onClick: () => void;
};

export const TechnicalErrorDrawer: FC<BottomSheetDrawerProps> = ({
  isOpen,
  onClose,
  title,
  description,
  onClick,
}: BottomSheetDrawerProps) => {
  return (
    <DsBottomSheet
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          paddingTop: "var(--ds-spacing-zero)",
          paddingBottom: "var(--ds-spacing-zero)",
        },

        "& .MuiDialogContent-root": {
          paddingLeft: "var(--ds-spacing-zero)",
          paddingRight: "var(--ds-spacing-zero)",
        },

        "& .MuiDrawer-paper": {
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "38rem",
          maxHeight: "fit-content",
        },
      }}
    >
      <DsBox>
        <DsBox
          display="flex"
          textAlign="center"
          alignItems="center"
          flexDirection="column"
          gap="var(--ds-spacing-frostbite)"
          sx={{
            paddingTop: "var(--ds-spacing-zero)",
            paddingBottom: "var(--ds-spacing-mild)",
            paddingLeft: "var(--ds-spacing-bitterCold)",
            paddingRight: "var(--ds-spacing-bitterCold)",
          }}
        >
          <DsTypography variant="headingBoldMedium">{title}</DsTypography>
          <DsTypography
            variant="bodyRegularMedium"
            sx={{
              color: "var(--ds-colour-typoSecondary)",
            }}
          >
            {description}
          </DsTypography>

          <DsBox>
            <DsImage
              srcSet={TechnicalErrorImage}
              height="148px"
              width="148px"
            />
          </DsBox>
        </DsBox>

        <DsBox
          sx={{
            padding: "var(--ds-spacing-bitterCold)",
            paddingBottom: "var(--ds-spacing-mild)",
          }}
        >
          <DsBox
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <DsButton sx={{ width: "100%" }} size="large" onClick={onClick}>
              Home
            </DsButton>
          </DsBox>
        </DsBox>
      </DsBox>
    </DsBottomSheet>
  );
};

const TechnicalErrorImage: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.TechnicalErrorSBZ}`, import.meta.url).href,
    alt: "Npci Error",
    // as: "image/png",
  },
];
