import { DsToastProps, enqueueNotistack } from "@am92/react-design-system";
import { CUSTOM_ERROR } from "~/src/Constants/API_URLS";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { ApiResponseErrorImpl } from "~/src/Lib/types/api";

export function commonErrorHandler(
  error: ApiResponseErrorImpl,
  redirectFn?: (url: any, error?: ApiResponseErrorImpl) => void,
  toastFn?: (opt: DsToastProps) => void,
  passThroughCode?: number[],
  passThroughCb?: (error: ApiResponseErrorImpl) => void
): void {
  if (
    passThroughCode &&
    passThroughCb &&
    passThroughCode.includes(error.statusCode)
  ) {
    passThroughCb(error);
    return;
  }

  // if (error.statusCode === 500) {
  //   // redirectFn(APP_ROUTES.TECHNICAL_ERROR, error);
  //   console.log("error");
  //   return;
  // } else

  if (error.statusCode === 401) {
    window.location = "/session-expired" as any;
  } else if (error.statusCode === CUSTOM_ERROR.TIMEOUT_ERROR.CODE) {
    window.location = "/something-went-wrong" as any;
  } else if (error.statusCode === 422) {
    enqueueNotistack({
      message: error.message,
      variant: "error",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      style: { paddingBottom: "var(--ds-spacing-warm)" },
    });
  } else {
    // NOTE: for time being just a passthrough code
    enqueueNotistack({
      message: error.message,
      variant: "error",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      style: { paddingBottom: "var(--ds-spacing-warm)" },
    });
  }
}

export function CustomErrorHandler(
  message: ApiResponseErrorImpl
): ApiResponseErrorImpl {
  new Error(message.message);
  Object.setPrototypeOf(message.message, CustomErrorHandler.prototype);
  return message;
}

CustomErrorHandler.prototype = Object.create(Error.prototype, {
  name: { value: "Custom Error Handler", enumerable: false },
});

const errorObject_500 = {
  status: "error",
  statusCode: 500,
  msg: "Something Went Wrong",
};

export const customError_500: ApiResponseErrorImpl = {
  error: errorObject_500,
  errorObj: errorObject_500,
  status: errorObject_500.msg,
  statusCode: errorObject_500.statusCode,
  message: errorObject_500.msg,
};
