import { RootState } from "~/src/Configurations/AppStore";
import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  fetchSendOtpList,
  fetchSendOtpListFail,
  fetchSendOtpListSuccess,
} from "../Reducers/SendOtp.reducers";

import {
  fetchValidateOtpList,
  fetchValidateOtpListFail,
  fetchValidateOtpListSuccess,
} from "../Reducers/ValidateOtp.reducers";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const VALIDATE_OTP = URL.PAYMENT.VALIDATE_OTP;
const SEND_OTP = URL.PAYMENT.SEND_OTP;

export const validateOtpListThunk =
  (refId: string, otp: string): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchValidateOtpList({ refId, otp }));
    try {
      const options: WebHttpRequestOptions = {
        url: VALIDATE_OTP,
        method: "POST",
        data: {
          refId: refId,
          otp: otp,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;

      dispatch(fetchValidateOtpListSuccess(data));
      return data;
    } catch (error) {
      dispatch(fetchValidateOtpListFail(error as ApiResponseError));
      throw error;
    }
  };

export const sendOtpListThunk =
  (
    mobile: string,
    email?: string,
    customerName?: string,
    paymentAmount?: string
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchSendOtpList({ mobile, email, customerName, paymentAmount }));
    try {
      const options: WebHttpRequestOptions = {
        url: SEND_OTP,
        method: "POST",
        data: {
          email: email
            ? {
                toEmailId: email,
                altEmail: email,
              }
            : undefined,
          phone: {
            number: mobile,
            altNumber: mobile,
          },
          notificationType: "S", //email ? "B" :
          customerName: customerName?.trim().replace(/[^a-zA-Z0-9\s]/g, ""), // BPN-2660 removing extra space & special charcaters
          paymentAmount,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;

      dispatch(fetchSendOtpListSuccess(data));
      return data;
    } catch (error) {
      dispatch(fetchSendOtpListFail(error as ApiResponseError));
      throw error;
    }
  };
