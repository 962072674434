import React, { Component, FC, useEffect, useRef, useState } from "react";
import {
  DsBottomSheet,
  DsBox,
  DsButton,
  DsFormControl,
  DsTextField,
} from "@am92/react-design-system";
import * as Yup from "yup";
import { AlphaNumericRegex } from "~/src/Common/constants/regex";
import { useFormik, getIn } from "formik";

type NickNameDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onNickNameChange: (name: string) => void;
  defaultNickName?: string;
  alreadyUsedNickNames?: {};
};

export const NicknameDrawer: FC<NickNameDrawerProps> = ({
  isOpen,
  onClose,
  onNickNameChange,
  defaultNickName,
  alreadyUsedNickNames,
}: NickNameDrawerProps) => {
  const errorMessage =
    "Nick name should be 3 - 15 characters long with no special characters.";
  const errorMessage2 = "Nickname already exists. Try a different nickname.";

  const NickNameFormValidation = Yup.object().shape({
    nickName: Yup.string()
      .min(3, errorMessage)
      .max(15, errorMessage)
      .matches(AlphaNumericRegex, errorMessage)
      .required(errorMessage)
      .typeError(errorMessage)
      .test("Unique NickName", errorMessage2, (value) => {
        if (alreadyUsedNickNames) {
          return !(value && alreadyUsedNickNames[value.toLowerCase()]);
        }
        return true;
      }),
  });

  const [name, setName] = useState(defaultNickName);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      nickName: name,
    },
    initialTouched: {
      nickName: true,
    },
    onSubmit: (values) => {
      if (values.nickName) {
        onNickNameChange(values.nickName);
        setName(values.nickName);
        onClose();
      }
    },
    validationSchema: NickNameFormValidation,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm({ values: { nickName: name } });

      formik.validateForm({ nickName: name });
    }
  }, [isOpen]);

  return (
    <DsBottomSheet
      open={isOpen}
      onClose={onClose}
      // sx={{ minHeight: "max(50vh, 400px)" }}
      sx={{
        "& .MuiPaper-root": {
          paddingTop: "var(--ds-spacing-zero)",
          paddingBottom: "var(--ds-spacing-zero)",
        },

        "& .MuiDialogContent-root": {
          paddingLeft: "var(--ds-spacing-zero)",
          paddingRight: "var(--ds-spacing-zero)",
        },

        "& .MuiDrawer-paper": {
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "38rem",
          maxHeight: "fit-content",
        },
      }}
    >
      <DsBox
        sx={{
          paddingTop: "var(--ds-spacing-zero)",
          paddingBottom: "var(--ds-spacing-mild)",
          paddingLeft: "var(--ds-spacing-bitterCold)",
          paddingRight: "var(--ds-spacing-bitterCold)",
        }}
      >
        <DsTextField
          label="Enter nick name"
          sx={{ width: "100%" }}
          name="nickName"
          placeholder="Please enter the nick name"
          value={formik.values.nickName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.nickName && Boolean(formik.errors.nickName)}
          helperText={formik.touched.nickName ? formik.errors.nickName : ""}
        />
      </DsBox>
      <DsBox
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "var(--ds-spacing-bitterCold)",
          paddingBottom: "var(--ds-spacing-mild)",
        }}
      >
        <DsBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            width: "100%",
          }}
        >
          <DsButton
            color="secondary"
            size="large"
            sx={{ width: "100%" }}
            onClick={onClose}
          >
            Cancel
          </DsButton>
          <DsButton
            sx={{ width: "100%" }}
            size="large"
            onClick={formik.submitForm}
            disabled={!(Object.keys(formik.errors).length === 0)}
          >
            Confirm
          </DsButton>
        </DsBox>
      </DsBox>
    </DsBottomSheet>
  );
};
