export default {
  paymentDelayDisclaimer:
    "Payment may take upto 3 working days to reflect at biller's end.",
  autoPayEnabledLabel:
    // "Auto-pay will be executed 4 days before the bill due date.",
    "Lean back and stay assured, your future bill payments will be done automatically.",
  autoPayLabel:
    "Avoid late fees. Your payment will never be missed, switch to autopay.",
  autoPayAlertTitle: "Pay this biller with Auto Payment?",
  autoPayAlertDescription:
    "Worried about missing your due dates? Opt for auto-pay and we'll automatically pay this bill on your behalf.",
  sendOtpSuccess: "OTP sent successfully",
  sendOtpFail: "Unable to send Otp, Try resending.",
  verifyOtpSuccess: "Otp verification succesful",
  verifyOtpFail: "Unable to verify Otp",
  mutualFundDesc:
    "Registration may take up to one month to reflect at biller's end.",
  autoPayPaymentDisclaimer:
    "We may share your account details with our payment fulfilment partner to execute auto-payments.",
  autopayConsentText:
    "I allow Axis Bank to share my account number with its service provider to execute auto-payments",
  refreshBillDisclaimer:
    "Your bill is unpaid as per our records. If you have already paid the bill, click 'REFRESH BILL' to refresh.",
  billerFluctuating:
    "This Biller is currently fluctuating. Payments might take some time to be successful.",
  billerOffline:
    "This Biller is currently down/offline. Payments cannot be made right now. Kindly try later.",
  billerFluctuatingAlert: `This Biller is currently fluctuating. Payments might take some time to be successful. Do you still want to continue making the payment?`,
  billerOfflineAlert: `This Biller is currently down/offline. Payments cannot be made right now. Kindly try later.`,
  paymentReflectDisclaimer: `Payment may take up to 3 working days to reflect at biller's end`,
  AxisCreditPaymentDelayDisclaimer: `Payment may take upto 3 working days to reflect on your card statement, for same day reflection in your statement, you can alternatively use "Pay Now" under Credit cards section.`,
  CoolingPeriodDisclaimerForNonBBPS: `For enhanced security, new credit cards are subject to a transaction limit of ₹10,000 and a maximum of 2 transactions in the first 24 hours.`,
  NoCashbackHistory: `You have not received any Cashbacks yet. Make a bill payment now to receive your cashback!`,
  NcmcDisclaimer: `Customer to carry out balance update at POS machine to reflect on their NCMC card.`,
  NpsPaymentDisclaimer: `Contribution amount will be invested at T+1 basis if contributed before 8:00pm, else T+2 day`,
  NpsPaymentReceiptDisclaimer: `Any unsettled contributions due to any reason will be refunded to the subscriber within 5 working days.`,
  VelocityCheckNudgeInPaymentPage: `For new registrations, billers under this category, there would be a set transaction limit of up to Rs. 10,000 and a maximum of 2 transactions in the cooling period of the first 24 hours.`,
  VelocityCheckNudgeInBillDetailPage: `For enhanced security, new biller registrations have a transaction limit of INR 10,000 and a maximum of two transactions in the first 24 hours.`,
  paymentProcessingDisclaimer: `We’re processing your payment. Please wait while we confirm the status. Refunds, if applicable, will be initiated within 5 days.`,
  paymentFailedDisclaimer: `Transaction failed. Refund will be initiated within 5 days.`,
};
