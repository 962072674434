import React, {
  ReactElement,
  ChangeEvent,
  Component,
  forwardRef,
  useState,
  FC,
  ReactNode,
  useEffect,
} from "react";
import {
  DsButton,
  DsTypography,
  DsBox,
  DsTextField,
  DsAvatar,
  DsRadioGroup,
  DsRadio,
  DsFormControlLabel,
  DsCard,
} from "@am92/react-design-system";
import {
  localeAmountFormat,
  twoDecimalPointRestriction,
} from "~/src/Common/utitlity/validation.utilities";
import { FullModal } from "~/src/components/FullModal";
import { BottomSticker } from "~/src/components/BottomSticker";
import { InfoPopover } from "~/src/components/InfoPopover";
import * as Yup from "yup";
import { useFormik } from "formik";
import { minMaxAmountAutopayModal } from "../../common/utitlity/biller.utilities";
import { Biller, PaymentChannel } from "~/src/Redux/Billers/Model/Biller.model";
import { CustomerChannelEnum } from "~/src/Redux/Customer/Customer.reducer";
import { PaymentMethodEnum } from "~/src/Redux/Payment/Model/PaymentDetail.model";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { NumericFormat } from "react-number-format";

type AutoPayModalProps = {
  billDetail?: {
    biller: string;
    billerLogo: string;
    billId: string;
  };
  title: string;
  onBackClick: () => void;
  isOpen: boolean;
  biller: Biller;
  channelId: CustomerChannelEnum;

  onClose: () => void;
  onAutoPaySubmit: (isFullPay: boolean, amount?: number) => void;
  paymentChannel: PaymentChannel;
  paymentMethod: PaymentMethodEnum;
};
export const AutoPayModal: FC<AutoPayModalProps> = ({
  isOpen,
  onClose,
  onBackClick,
  title,
  biller,
  channelId,
  billDetail,
  onAutoPaySubmit,
  paymentChannel,
  paymentMethod,
}: AutoPayModalProps) => {
  const getMinMaxAutoPayLimitForCreditCard = (min: boolean) => {
    let maxLimit;
    let minLimit;
    if (
      paymentMethod === PaymentMethodEnum.CreditCard &&
      biller.isBillerBBPS &&
      biller.billerCategory.toLowerCase() === "Insurance".toLowerCase()
    ) {
      maxLimit = process.env.CREDIT_CARD_AUTO_MAX_LIMIT_INSURANCE;
      minLimit = process.env.CREDIT_CARD_AUTO_MIN_LIMIT_INSURANCE;
    } else if (
      paymentMethod === PaymentMethodEnum.CreditCard &&
      biller.billerCategory.toLowerCase() !== "Insurance".toLowerCase()
    ) {
      maxLimit = process.env.CREDIT_CARD_AUTO_MAX_LIMIT;
      minLimit = process.env.CREDIT_CARD_AUTO_MIN_LIMIT;
    }
    return min ? Number(minLimit) : Number(maxLimit);
  };

  const options = [
    {
      value: "autoPayFull",
      heading: "Pay Full Bill Amount",
      description:
        paymentMethod === PaymentMethodEnum.CreditCard
          ? `We will automatically pay your bills four days prior to the bill due date. For auto payment instructions through credit card, bills greater than Rs. 15,000 will have to be paid manually`
          : `We will automatically pay your bills four days prior to the bill due date`,
    },
    {
      value: "autoPayPartial",
      heading: "Set Autopay Limit",
      description:
        paymentMethod === PaymentMethodEnum.CreditCard
          ? `Maximum autopay limit for payment via credit card is ${localeAmountFormat(
              getMinMaxAutoPayLimitForCreditCard(false)
            )}; and in case of payments via savings or current account it is ${localeAmountFormat(
              minMaxAmountAutopayModal(false, biller, channelId)
            )}`
          : `Maximum autopay limit for payment via savings or current account is ${localeAmountFormat(
              minMaxAmountAutopayModal(false, biller, channelId)
            )}`,
    },
  ];
  const [autoPayAmount, setAutoPayAmount] = useState<string>("");
  const [isFullPay, setFullPay] = useState<boolean>(true);
  const [selectedOpt, setSelectedOpt] = useState("autoPayFull");

  const [isConfirmDisabled, setConfirmDisabled] = useState<boolean>(false);
  // const handleChange = (event: any) => {
  //   setSelectedOpt(event.target.value);
  //   setFullPay(event.target.value === "autoPayFull");
  // };

  const handleChange = (event: any) => {
    const selectedOption = event.target.value;
    setSelectedOpt(selectedOption);
    setFullPay(selectedOption === "autoPayFull");
    setConfirmDisabled(
      selectedOption === "autoPayPartial" && autoPayAmount === ""
    );
  };

  useEffect(() => {
    if (selectedOpt === "autoPayPartial") {
      const amount = Number.parseFloat(autoPayAmount || "0");
      const isAmountValid =
        paymentMethod === PaymentMethodEnum.CreditCard
          ? amount >= getMinMaxAutoPayLimitForCreditCard(true) &&
            amount <= getMinMaxAutoPayLimitForCreditCard(false)
          : amount >= minMaxAmountAutopayModal(true, biller, channelId) &&
            amount <= minMaxAmountAutopayModal(false, biller, channelId);
      setConfirmDisabled(!isAmountValid);
    } else {
      setConfirmDisabled(false);
    }
  }, [autoPayAmount, selectedOpt]);

  const handleSubmit = () => {
    const label = isFullPay
      ? "pay full bill amount"
      : `set autopay limit | ${Number.parseFloat(autoPayAmount)}`;
    handleGAPush(
      CategoryHelperObject.categoryPayBills +
        biller.billerCategory.toLowerCase(),
      "set auto pay limit",
      label
    );
    onAutoPaySubmit(
      isFullPay,
      isFullPay ? 0 : Number.parseFloat(autoPayAmount)
    );
  };

  const handleClose = () => {
    const label = isFullPay
      ? "pay full bill amount | cancel"
      : "set autopay limit | cancel";
    handleGAPush(
      CategoryHelperObject.categoryPayBills +
        biller.billerCategory.toLowerCase(),
      "set auto pay",
      label
    );
    onClose();
  };

  return (
    <>
      <FullModal
        isOpen={isOpen}
        onClose={onClose}
        onBackClick={onBackClick}
        title=""
      >
        <DsBox padding="var(--ds-spacing-bitterCold)">
          <DsTypography variant="bodyBoldLarge">{title}</DsTypography>
        </DsBox>
        <DsBox>
          {billDetail && (
            <DsBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap="var(--ds-spacing-glacial)"
              mt="var(--ds-spacing-mild)"
            >
              <DsAvatar
                src={billDetail.billerLogo}
                // alt={billerName.substring(0, 5)}
                sx={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  border: "1px solid var(--ds-colour-strokeDefault)",
                  boxSizing: "initial",
                  height: "56px",
                  width: "56px",
                }}
              />

              <DsBox
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                gap="var(--ds-spacing-quickFreeze)"
              >
                <DsTypography variant="bodyBoldLarge">
                  {billDetail.biller}
                </DsTypography>
                <DsTypography
                  variant="supportRegularMetadata"
                  sx={{ color: "var(--ds-colour-typoTertiary)" }}
                >
                  {billDetail.billId}
                </DsTypography>
              </DsBox>
            </DsBox>
          )}
        </DsBox>
        <DsBox sx={{ marginTop: "var(--ds-spacing-mild)" }}>
          {options.map((opt) => {
            return (
              <DsBox
                marginLeft="var(--ds-spacing-bitterCold)"
                marginRight="var(--ds-spacing-bitterCold)"
                marginBottom="var(--ds-spacing-mild)"
              >
                <DsCard
                  variant="outlined"
                  style={{
                    borderRadius: "8px",
                    padding: "var(--ds-spacing-frostbite)",
                    borderColor:
                      selectedOpt === opt.value ? "#ED1164" : "#E2E2E2",
                  }}
                >
                  <DsBox
                    display="flex"
                    alignItems="center"
                    gap="var(--ds-spacing-frostbite)"
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        marginLeft: "0px !important",
                        marginRight: "0px !important",
                      },
                    }}
                  >
                    <DsRadio
                      checked={selectedOpt === opt.value}
                      onChange={handleChange}
                      value={opt.value}
                      sx={{
                        "& .MuiRadio-root": {
                          padding: "0px !important",
                        },
                      }}
                    />
                    <DsBox
                      display="flex"
                      flexDirection="column"
                      gap="var(--ds-spacing-quickFreeze)"
                    >
                      <DsTypography variant="bodyBoldMedium">
                        {opt.heading}
                      </DsTypography>
                      <DsTypography
                        variant="bodyRegularSmall"
                        sx={{
                          color: "var(--ds-colour-typoSecondary)",
                        }}
                      >
                        {opt.description}
                      </DsTypography>
                    </DsBox>
                  </DsBox>

                  <DsBox marginLeft="var(--ds-spacing-tepid)">
                    {opt.value === "autoPayPartial" ? (
                      <AmountFormField
                        defaultValue={autoPayAmount}
                        min={
                          paymentMethod === PaymentMethodEnum.CreditCard
                            ? getMinMaxAutoPayLimitForCreditCard(true)
                            : minMaxAmountAutopayModal(true, biller, channelId)
                        }
                        max={
                          paymentMethod === PaymentMethodEnum.CreditCard
                            ? getMinMaxAutoPayLimitForCreditCard(false)
                            : minMaxAmountAutopayModal(false, biller, channelId)
                        }
                        onAmountChange={setAutoPayAmount}
                        infoText={
                          <DsBox>
                            <DsTypography>
                              Auto-payment will be processed whenever the bill
                              amount is up to the specified limit.
                            </DsTypography>
                            <DsTypography mt={3}>
                              We will notify you if your bill amount is more
                              than the autopay limit.
                            </DsTypography>
                          </DsBox>
                        }
                        selectedOption={selectedOpt}
                      />
                    ) : null}
                  </DsBox>
                </DsCard>
              </DsBox>
            );
          })}
        </DsBox>
        <BottomSticker>
          <DsBox
            display={"flex"}
            alignItems="center"
            sx={{
              padding: "var(--ds-spacing-bitterCold)",
              paddingBottom: "var(--ds-spacing-mild)",
            }}
            gap="var(--ds-spacing-frostbite)"
          >
            <DsBox
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <DsButton
                color="secondary"
                onClick={handleClose}
                sx={{ width: "100%" }}
                size="large"
              >
                Cancel
              </DsButton>
            </DsBox>

            <DsButton
              sx={{ width: "100%" }}
              size="large"
              disabled={isConfirmDisabled}
              onClick={handleSubmit}
            >
              Confirm
            </DsButton>
          </DsBox>
        </BottomSticker>
      </FullModal>
    </>
  );
};

type AmountFormFieldProps = {
  min: number;
  max: number;
  defaultValue: string;
  onAmountChange: (amount: string) => void;
  errorMessage?: string;
  infoText?: ReactNode;
  selectedOption?: string;
};

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      thousandsGroupStyle="lakh"
      decimalScale={2}
    />
  );
};

export const AmountFormField = ({
  min,
  max,
  defaultValue,
  errorMessage,
  onAmountChange,
  infoText,
  selectedOption,
}: AmountFormFieldProps): ReactElement => {
  const errMsg = errorMessage
    ? errorMessage
    : `Please enter an amount between  ${localeAmountFormat(
        min
      )} and ${localeAmountFormat(max)}`;

  const AmountFormValidation = Yup.object().shape({
    amount: Yup.number()
      .min(min, errMsg)
      .max(max, errMsg)
      .required(errMsg)
      .typeError(errMsg),
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues: {
      amount: defaultValue,
    },
    initialTouched: {
      amount: true,
    },
    onSubmit: (values) => {
      onAmountChange(values.amount);
    },
    validationSchema: AmountFormValidation,
  });

  useEffect(() => {
    if (selectedOption === "autoPayFull") {
      formik.resetForm({ values: { amount: defaultValue } });
    }
  }, [selectedOption]);

  const [isInRange, setIsInRange] = useState<boolean>(true);

  return (
    <DsBox
      marginTop="var(--ds-spacing-frostbite)"
      display={"flex"}
      flexDirection={"column"}
    >
      <DsTextField
        name="amount"
        label={
          <DsBox
            display={"flex"}
            flexDirection={"row"}
            gap="4px"
            alignItems="center"
          >
            <DsTypography fontSize={"14px"} fontWeight={"400"}>
              Autopay Limit
            </DsTypography>
            <InfoPopover infoText={infoText} />
          </DsBox>
        }
        placeholder="Enter the amount here"
        type="string"
        inputMode="decimal"
        labelSupportText={selectedOption ? errMsg : ""}
        value={formik.values.amount}
        inputComponent={NumberFormatCustom}
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          const { value } = ev.target;

          const re = /^(\d*\.{0,1}\d{0,2}$)/;

          if (ev.target.value === "" || re.test(ev.target.value)) {
            const numericValueInRange =
              Number(ev.target.value) >= min && Number(ev.target.value) <= max;

            setIsInRange(numericValueInRange);

            onAmountChange(ev.target.value);
            formik.handleChange(ev);
          }
        }}
        readOnly={selectedOption === "autoPayFull"}
        error={
          selectedOption === "autoPayPartial" &&
          ((formik.touched.amount && !isInRange) ||
            (formik.touched.amount && Boolean(formik.errors.amount)))
        }
        helperText={
          selectedOption === "autoPayPartial" &&
          ((formik.touched.amount && !isInRange) ||
            (formik.touched.amount && Boolean(formik.errors.amount)))
            ? "Please enter a value within the recommended range"
            : ""
        }
        autoComplete="off"
        startAdornment={formik.values.amount ? "₹ " : ""}
        sx={{
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input:-internal-autofill-selected": {
            backgroundColor: "unset",
          },
          "& .MuiInputLabel-root": {
            zIndex: 0,
          },
        }}
      />
    </DsBox>
  );
};
