import {
  DsBox,
  DsButton,
  DsImage,
  DsImageProps,
  DsRemixIcon,
  DsTextField,
  DsTypography,
} from "@am92/react-design-system";
import Lottie from "lottie-react";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessTickAnimation from "~/src/Common/Json/SuccessTickAnimation.json";
import AutoPayModal from "~/src/Pages/ManageBillers/components/AutoPayModal";
import texts from "../Common/constants/texts";
import { localeAmountFormat } from "../Common/utitlity/validation.utilities";
import APP_ROUTES from "../Constants/APP_ROUTES";
import images from "../Constants/images";
import { DataStatus } from "../Lib/types/datatransfer";
import { Biller, BillerTypeEnum } from "../Redux/Billers/Model/Biller.model";
import { BillerAccount } from "../Redux/Billers/Model/BillerAccount.model";
import { BottomSticker } from "./BottomSticker";
import { FullModal } from "./FullModal";
import { InfoCardToggle } from "./InfoCardToggle";
import { BillPayLogoNav, NavRightSectionWithSupport } from "./NavigationBar";
import { isAutoPayRestriced } from "../Pages/common/utitlity/biller.utilities";

type FullScreenStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
  duration: number;
};

export const FullScreenStatusModal = ({
  isOpen,
  onClose,
  children,
  duration,
}: FullScreenStatusModalProps): ReactElement => {
  useEffect(() => {
    if (isOpen) {
      const closeTimer = setTimeout(onClose, duration);
      return () => clearTimeout(closeTimer);
    }
    return undefined;
  }, [isOpen]);
  return (
    <FullModal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size="full"
      closeOnOverlayClick={false}
      blockScrollOnMount={true}
      scrollBehavior="inside"
      navRightSection={null as unknown as ReactElement}
    >
      {/* <ModalOverlay /> */}
      <DsBox
        sx={{
          maxW: "min(600px, 100%)",
          width: "min(600px, 100%)",
          alignSelf: "center",
          minH: "100vh",
        }}
      >
        <DsBox height="100vh">{children}</DsBox>
      </DsBox>
    </FullModal>
  );
};

type SuccessStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  statusText: string;
  duration: number;
};

// const SuccessAnimation = lazy(
//   () => import("./SuccessAnimation" /* webpackChunkName: "SuccessAnimation" */)
// );

// const SuccessIconD = () => (
//   <Suspense fallback={<Skeleton height={50} noOfLines={3} />}>
//     <SuccessAnimation />
//   </Suspense>
// );

type SuccessStatusProps = {
  statusText: string;
};
export const SuccessStatus = ({
  statusText,
}: SuccessStatusProps): ReactElement => (
  // <GratificationLayout StatusIcon={SuccessIconD} statusText={statusText} />
  // <GratificationLayout statusText={statusText} />
  <DsBox
    display="flex"
    flexDirection="column"
    // gap="var(--ds-spacing-mild)"
    alignItems="center"
    marginTop={"50%"}
  >
    <Lottie
      animationData={SuccessTickAnimation}
      loop={false}
      className="loader"
      style={{ width: "188px", height: "188px" }}
    />

    <DsTypography variant="headingBoldSmall" textAlign={"center"}>
      {statusText}
    </DsTypography>
  </DsBox>
);

export const SuccessStatusModal = ({
  isOpen,
  onClose,
  statusText,
  duration,
}: SuccessStatusModalProps): ReactElement => {
  console.log(`isOpen______------${isOpen}_______-------`);
  console.log(`statusText______------${statusText}_______-------`);
  console.log(`onClose______------${onClose}_______-------`);

  return (
    <>
      <FullScreenStatusModal
        isOpen={isOpen}
        onClose={onClose}
        duration={duration}
      >
        <>{isOpen && <SuccessStatus statusText={statusText} />}</>
      </FullScreenStatusModal>
    </>
  );
};

type FullModalAlertProps = {
  isOpen: boolean;
  onClose: () => void;
  alertTitle?: string;
  alertDescription?: string;
  isCloseButton?: boolean;
  billerAccount: BillerAccount;
  biller?: Biller;
  isCashbackAvailable?: boolean;
  cashbackAlert?: string;
  cashbackOfferStatus?: DataStatus;
};

export const FullModalAlert: FC<FullModalAlertProps> = ({
  isOpen,
  onClose,
  alertTitle,
  alertDescription,
  isCloseButton,
  billerAccount,
  biller,
  isCashbackAvailable = false,
  cashbackAlert,
  cashbackOfferStatus,
}: FullModalAlertProps) => {
  const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
  const [isFullPay, setFullPay] = useState<boolean>(true);

  const location = useLocation();

  const navigate = useNavigate();

  const [autopayModalOpen, setAutopayModalOpen] = useState<boolean>(false);

  const handleAutopayModalOpen = () => {
    setAutopayModalOpen(true);
  };

  const handleAutopayModalClose = () => {
    setAutopayModalOpen(false);
  };

  const isAutoPayBiller =
    biller?.billerType === BillerTypeEnum.BILLER ||
    biller?.billerType === BillerTypeEnum.BOTH;

  const showEnableAutopay =
    Object.keys(billerAccount)?.length > 0 &&
    isAutoPayBiller &&
    !billerAccount?.autopayStatus &&
    biller?.billerCategory !== "LPG Cylinder" &&
    !isAutoPayRestriced(biller?.billerCategory);

  return (
    <FullModal
      isOpen={isOpen}
      onClose={onClose}
      title=""
      navRightSection={
        biller?.isBillerBBPS ? (
          <NavRightSectionWithSupport>
            <BillPayLogoNav />
          </NavRightSectionWithSupport>
        ) : undefined
      }
    >
      <DsBox>
        <DsBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="var(--ds-spacing-superheated)"
        >
          <DsBox textAlign="center">
            <DsImage
              srcSet={AlertImage}
              style={{ width: "148px", height: "148px" }}
            />
          </DsBox>
          <DsTypography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              letterSpacing: 0.24,
              lineHeight: "24px",
            }}
          >
            {alertTitle}
          </DsTypography>
          <DsTypography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              letterSpacing: 0.26,
              mt: "var(--ds-spacing-bitterCold)",
              color: "var(--ds-colour-typoSecondary)",
            }}
          >
            {alertDescription}
          </DsTypography>
        </DsBox>
      </DsBox>
      <BottomSticker padding="var(--ds-spacing-bitterCold)">
        <DsBox>
          {showEnableAutopay && !location?.pathname.includes("payment") && (
            <DsBox mb="var(--ds-spacing-mild)">
              <InfoCardToggle
                header={isAutoPay ? "Autopay enabled!" : ""}
                label={
                  isAutoPay
                    ? texts?.autoPayEnabledLabel
                    : "Switch to autopay to make your payments on time."
                }
                onChange={(name, value) => {
                  if (value) {
                    handleAutopayModalOpen();
                  } else {
                    setIsAutoPay(false);
                  }
                }}
                checkedIcon={isAutoPay}
                bottomElement={
                  isAutoPay ? (
                    <DsBox
                      display={"flex"}
                      padding="var(--ds-spacing-bitterCold)"
                      paddingRight="var(--ds-spacing-frostbite)"
                      margin={"auto"}
                    >
                      <DsBox width={"50%"} marginTop={3}>
                        <DsTypography>Autopay limit:</DsTypography>
                      </DsBox>
                      <DsBox textAlign="end" width={"65%"}>
                        <DsTextField
                          label=""
                          value={
                            isFullPay
                              ? "Pay full bill amount"
                              : `${localeAmountFormat(200)}` // `₹${autopayAmount}`
                          }
                          endAdornment={
                            <DsRemixIcon
                              className="ri-edit-line"
                              onClick={() => handleAutopayModalOpen()}
                            />
                          }
                          style={{
                            border: isFullPay
                              ? "none"
                              : "1px solid var(--ds-colour-strokeDefault)",
                          }}
                        ></DsTextField>
                      </DsBox>
                    </DsBox>
                  ) : null
                }
              />
            </DsBox>
          )}
          <DsButton
            sx={{
              width: "100%",
            }}
            size="large"
            onClick={() => {
              navigate(APP_ROUTES.HOME.pathname);
            }}
          >
            Home
          </DsButton>
        </DsBox>
      </BottomSticker>
      {autopayModalOpen && (
        <AutoPayModal
          isOpen={autopayModalOpen}
          onClose={handleAutopayModalClose}
          title="Setup Auto Pay"
          billerAccount={billerAccount}
          typeOfOperation={"Add Autopay"}
          isFullPay={isFullPay}
          setFullPay={setFullPay}
        />
      )}
    </FullModal>
  );
};

const AlertImage: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.NoBillDueSBZ}`, import.meta.url).href,
    alt: "No Bills Due",
  },
];

const SuccessTickMark: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.ConfirmTickMark}`, import.meta.url).href,
    alt: "Success",
  },
];
