import React, { FC, useState, ReactElement } from "react";
import {
  DsButton,
  DsRemixIcon,
  DsTypography,
  DsBox,
  DsTextField,
  DsMenuItem,
  DsFormControl,
  DsSelect,
  DsAvatar,
  DsRadio,
  DsRadioGroup,
  DsFormControlLabel,
  DsList,
  DsListItem,
  DsListItemAvatar,
  DsListItemText,
  DsListItemIcon,
  DsDivider,
} from "@am92/react-design-system";
import { NavigationBar } from "~/src/components/NavigationBar";
import { useNavigate } from "react-router-dom";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { BILLERS_ROUTES } from "../Pages/billers/biller.routes";
import { useLocation } from "react-router-dom";
import { AccountDetail } from "../Redux/Payment/Model/AccountDetail.model";

type HorizontalLogoInfoItemProps = {
  title: string;
  logo: string;
  subtitle?: string;
  category?: string; // For only cases where subtitle is not biller-category eg- Credit card to show fallback logo
  onItemClick: () => void;
  categoryLogo: any;
  //   logoStyleProps: ImageProps
};

export const HorizontalLogoInfoItem: FC<HorizontalLogoInfoItemProps> = ({
  title,
  logo,
  subtitle,
  onItemClick,
  categoryLogo,
  //   logoStyleProps,
  category,
}: HorizontalLogoInfoItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [url, setUrl] = useState(logo);
  const errorHandler = () => {
    let imgUrl = category
      ? categoryLogo[category || ""]
      : categoryLogo[subtitle || ""];
    setUrl(imgUrl);
  };
  return (
    <>
      {/* <DsBox
        display={"flex"}
        alignItems={"center"}
        sx={{
          borderTop: "1px solid var(--ds-colour-strokeDefault)",
          cursor: "pointer",
        }}
        onClick={onItemClick}
        paddingTop="var(--ds-spacing-bitterCold)"
        paddingBottom="var(--ds-spacing-bitterCold)"
      >
        <DsBox
          border={"1px solid var(--ds-colour-strokeDefault)"}
          borderRadius={"10px"}
          padding={2}
        >
          <DsAvatar
            variant="square"
            src={url}
            imgProps={{
              onError: errorHandler,
            }}
            ds-size="S"
          />
        </DsBox>
        <DsBox
          display={"flex"}
          flexDirection={"column"}
          marginLeft={2}
          justifyContent={"center"}
          width={"80%"}
          gap={1}
        >
          <DsTypography variant="bodyRegularMedium">{title}</DsTypography>
          <DsTypography
            variant="supportRegularInfo"
            color="var(--ds-colour-typoSecondary)"
          >
            {subtitle}
          </DsTypography>
        </DsBox>
        <DsRemixIcon
          sx={{ marginTop: 2 }}
          fontSize={"small"}
          className="ri-arrow-right-s-line"
        />
      </DsBox> */}

      <DsList
        disablePadding
        sx={{
          "& .MuiListItemIcon-root": {
            minWidth: 0,
          },

          "& .MuiListItemAvatar-root": {
            minWidth: "52px",
          },

          "& .MuiListItem-root": {
            padding: "16px 8px 16px 8px",
          },
        }}
      >
        <DsListItem
          onClick={onItemClick}
          sx={{
            cursor: "pointer",
            "& .MuiListItemAvatar-root": {
              minWidth: "48px",
            },
          }}
        >
          <DsListItemAvatar>
            <DsAvatar
              variant="square"
              src={url}
              sx={{
                border: "1px solid var(--ds-colour-strokeDefault)",
                borderRadius: "10px",
                width: "38px",
                height: "38px",
              }}
              imgProps={{
                onError: errorHandler,
              }}
            />
          </DsListItemAvatar>
          <DsListItemText
            // sx={{
            //   "& .MuiTypography-root": {
            //     fontSize: "14px",
            //     fontWeight: "400",
            //     lineHeight: "20px",
            //     letterSpacing: 0.24,
            //   },
            // }}
            primaryTypographyProps={{
              sx: {
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: 0.24,
                marginBottom: "var(--ds-spacing-quickFreeze)",
              },
            }}
            primary={title.length > 40 ? `${title.substr(0, 27)}...` : title}
            secondary={subtitle}
            secondaryTypographyProps={{
              sx: {
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: 0.32,
              },
            }}
          />
          <DsListItemIcon>
            <DsRemixIcon
              className="ri-arrow-right-s-line"
              fontSize="bitterCold"
              sx={{
                color: "var(--ds-colour-typoPrimary)",
              }}
            />
          </DsListItemIcon>
        </DsListItem>
        <DsDivider
          orientation="vertical"
          sx={{ borderColor: "var(--ds-colour-stateDisabledSurface)" }}
        />
      </DsList>
    </>
  );
};

// type HorizontalLogoInfoButtonProps = {
//   title: string
//   logo: string
//   subtitle: string
//   paymentAmount: number
// }

// export const HorizontalLogoInfoButton = ({
//   title,
//   logo,
//   subtitle,
//   paymentAmount
// }: HorizontalLogoInfoButtonProps) => {
//   return (
//     <DsBox>
//       <DsBox
//         sx={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           mt: 3,
//           p: 1
//         }}
//       >
//         <DsBox sx={{ display: 'flex', alignItems: 'center' }}>
//           <DsAvatar
//             src={logo}
//             sx={{
//               borderRadius: '5px',
//               border: '1px solid',
//               borderColor:
//                 'var(--light-stroke-default-sz-colour-stroke-default, #E2E2E2)'
//             }}
//           />
//           <DsBox
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'space-evenly',
//               ml: 3,
//               overflow: 'hidden',
//               gap: '4px'
//             }}
//           >
//             <DsTypography
//               sx={{
//                 fontSize: '12px',
//                 fontWeight: 700,
//                 letterSpacing: '0.32px',
//                 lineHeight: '18px',
//                 color:
//                   'var(--light-typography-primary-sz-colour-typo-primary, #282828)'
//               }}
//             >
//               {title}
//             </DsTypography>

//             <DsTypography
//               sx={{
//                 fontSize: '12px',
//                 fontWeight: 400,
//                 letterSpacing: 0.32,
//                 color:
//                   'var(--light-typography-secondary-sz-colour-typo-secondary, #6E6E6E)',
//                 lineHeight: '12px',
//                 textTransform: 'uppercase'
//               }}
//             >
//               {subtitle}
//             </DsTypography>
//           </DsBox>
//         </DsBox>
//         <DsBox
//           sx={{
//             display: 'flex',
//             flexDirection: 'row-reverse',
//             alignItems: 'center'
//           }}
//         >
//           <DsBox
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'flex-end',
//               gap: '4px'
//             }}
//           >
//             <DsTypography
//               sx={{
//                 fontSize: '12px',
//                 fontWeight: 400,
//                 lineHeight: '18px',
//                 letterSpacing: 0.32,
//                 color:
//                   'var(--light-typography-primary-sz-colour-typo-primary, #282828)',
//                 textTransform: 'uppercase'
//               }}
//             >
//               Total Paid
//             </DsTypography>

//             <DsTypography
//               sx={{
//                 fontSize: '12px',
//                 fontWeight: 700,
//                 lineHeight: '18px',
//                 letterSpacing: 0.32,
//                 color:
//                   'var(--light-typography-primary-sz-colour-typo-primary, #282828)',
//                 textTransform: 'uppercase'
//               }}
//             >
//               ₹{paymentAmount.toFixed(2)}
//             </DsTypography>
//           </DsBox>
//         </DsBox>
//       </DsBox>
//     </DsBox>
//   )
// }

type HorizontalLogoInfoButtonProps = {
  title: string;
  logo: string;
  subtitle: string;
  subtext?: string;
  onClick?: () => void;
  // logoStyleProps?: ImageProps
  rightElement?: ReactElement;
  style?: React.CSSProperties;
  isDisabled?: boolean;
  isDefaultCursor?: boolean;
  accountDetail: AccountDetail;
  paymentAmount: number;
  isFromPaymentPage?: boolean;
  isCasaBalanceLess?: boolean;
};

export const HorizontalLogoInfoButton: FC<HorizontalLogoInfoButtonProps> = ({
  title,
  logo,
  subtitle,
  subtext,
  onClick,
  // logoStyleProps,
  rightElement,
  style,
  isDisabled,
  isDefaultCursor,
  accountDetail,
  paymentAmount,
  isFromPaymentPage,
  isCasaBalanceLess,
}: HorizontalLogoInfoButtonProps) => {
  return (
    <DsBox>
      <DsBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // my: 4,
          // marginBottom: "1.5rem",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <DsBox sx={{ display: "flex", alignItems: "center" }}>
          <DsAvatar
            src={logo}
            alt={title.substring(0, 5)}
            sx={{
              borderRadius: "8px",
              objectFit: "cover",
              border: "1px solid var(--ds-colour-strokeDefault)",
              boxSizing: "initial",
              width: "28px",
              height: "28px",
            }}
          />
          <DsBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              ml: "var(--ds-spacing-glacial)",
              overflow: "hidden",
              gap: "var(--ds-spacing-deepFreeze)",
            }}
          >
            <DsTypography
              variant="subheadingSemiboldDefault"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </DsTypography>

            <DsTypography
              variant="supportRegularMetadata"
              sx={{
                color:
                  accountDetail?.accountAvailableBalance +
                    accountDetail?.accountFFDBalance <
                    paymentAmount &&
                  isFromPaymentPage &&
                  isCasaBalanceLess
                    ? "var(--ds-colour-iconNegative)"
                    : "var(--ds-colour-typoSecondary)",
              }}
            >
              {subtitle}
            </DsTypography>

            {subtext && (
              <DsTypography
                variant="supportRegularMetadata"
                sx={{
                  color:
                    accountDetail?.accountAvailableBalance +
                      accountDetail?.accountFFDBalance <
                      paymentAmount && isFromPaymentPage
                      ? "var(--ds-colour-iconNegative)"
                      : "var(--ds-colour-typoSecondary)",
                }}
              >
                {subtext}
              </DsTypography>
            )}
          </DsBox>
        </DsBox>
        {isDefaultCursor ? null : (
          <DsBox
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            {rightElement ? (
              rightElement
            ) : (
              <DsBox
                sx={{
                  "& .MuiButton-root": {
                    paddingTop: "var(--ds-spacing-glacial)",
                    paddingBottom: "var(--ds-spacing-glacial)",
                    paddingLeft: "var(--ds-spacing-quickFreeze)",
                    paddingRight: "var(--ds-spacing-quickFreeze)",
                  },
                }}
              >
                <DsButton
                  variant="text"
                  color="secondary"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={onClick}
                >
                  Change
                </DsButton>
              </DsBox>
            )}
          </DsBox>
        )}
      </DsBox>
    </DsBox>
  );
};
