import { RootState } from "~/src/Configurations/AppStore";
import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  fetchStopScheduledPaymentList,
  fetchStopScheduledPaymentListFail,
  fetchStopScheduledPaymentListSuccess,
} from "~/src/Redux/Payment/Reducers/StopScheduledPayment.reducer";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const STOP_SCHEDULED_PAYMENT = URL.PAYMENT.STOP_SCHEDULED_PAYMENT;

export const stopScheduledPaymentListThunk =
  (
    customerId: string,
    billId?: string,
    paymentId?: string
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(
      fetchStopScheduledPaymentList({
        customerId,
        billId,
        paymentId,
      })
    );
    try {
      const options: WebHttpRequestOptions = {
        url: STOP_SCHEDULED_PAYMENT,
        method: "POST",
        data: {
          customerid: customerId,
          billId: billId ? billId : undefined,
          paymentid: paymentId ? paymentId : undefined,
        },
      };

      const response = await asHttp.request(options);
      const { data } = response;

      dispatch(fetchStopScheduledPaymentListSuccess(data));
      console.log("stop-scheduled-payment", response.data.data);
      return data;
    } catch (error) {
      dispatch(fetchStopScheduledPaymentListFail(error as ApiResponseError));
      throw error;
    }
  };
