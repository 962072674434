import React, { FC } from "react";
import { DsTypography } from "@am92/react-design-system";
import { attemptText } from "../Payment.helper";

type AttemptsErrorTextProps = { attempts: number; type: string };
export const AttemptsErrorText: FC<AttemptsErrorTextProps> = ({
  attempts,
  type,
}: AttemptsErrorTextProps) => {
  return (
    <DsTypography
      sx={{
        fontSize: "0.75rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "1rem",
        letter: "0.32px",
        color: "var(--ds-colour-iconNegative)",
      }}
    >
      {attemptText(attempts, type)}
    </DsTypography>
  );
};

type ErrorTextProps = {
  error: string;
};

export const ErrorText: FC<ErrorTextProps> = ({ error }: ErrorTextProps) => {
  return (
    <DsTypography
      sx={{
        fontSize: "0.75rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "1rem",
        letter: "0.32px",
        color: "var(--ds-colour-iconNegative)",
      }}
    >
      {error}
    </DsTypography>
  );
};
