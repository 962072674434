import React, { FC, useEffect, useState } from "react";
import {
  DsAvatar,
  DsBottomSheet,
  DsBox,
  DsButton,
  DsChip,
  DsList,
  DsListItem,
  DsRadio,
  DsRemixIcon,
  DsTypography,
} from "@am92/react-design-system";
import { AccountDetail } from "~/src/Redux/Payment/Model/AccountDetail.model";
import { HorizontalLogoInfoButton } from "~/src/components/HorizontalInfoItem";
import groupBy from "lodash/groupBy";
import {
  ccmaskAccountNumber,
  getSchemeNameFromType,
  maskAccountNumber,
  updatedMaskAccountNumber,
  updatedccmaskAccountNumber,
} from "../Payment.helper";
import images from "~/src/Constants/images";
import { localeAmountFormat } from "~/src/Common/utitlity/validation.utilities";
import { CreditCardDetailData } from "~/src/Redux/Payment/Model/CreditCard.model";
import { BottomSticker } from "~/src/components/BottomSticker";

type PaymentSelectionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  paymentModes: AccountDetail[];
  onPaymentSelect: (paymentMode: AccountDetail) => void;
  initialSelect?: AccountDetail;
  initialCCSelect?: CreditCardDetailData;
  defaultSelected: string;
  paymentAmount: number;
  isStandAloneAutoPayOrSchedulePay?: boolean;
  onCCPaymentSelect: (ccPaymentMode: CreditCardDetailData) => void;
  ccDetails?: CreditCardDetailData[];
  isFromPaymentPage?: boolean;
};

export const PaymentSelectionDrawer: FC<PaymentSelectionDrawerProps> = ({
  isOpen,
  onClose,
  paymentModes,
  onPaymentSelect,
  initialSelect,
  initialCCSelect,
  defaultSelected,
  paymentAmount,
  isStandAloneAutoPayOrSchedulePay,
  onCCPaymentSelect,
  ccDetails,
  isFromPaymentPage,
}: PaymentSelectionDrawerProps) => {
  const [selectedPayment, setSelectedPayment] = useState(defaultSelected);

  const [prevSelectedAccount, setPrevSelectedAccount] = useState("");

  const [groupedPay, setGroupedPay] =
    useState<Record<string, AccountDetail[]>>();

  const [sortedCreditCardList, setSortedCreditCardList] =
    useState<CreditCardDetailData[]>();

  useEffect(() => {
    const grouped = groupBy(paymentModes, (pay) => pay);
    setGroupedPay(grouped);
  }, [paymentModes]);

  useEffect(() => {
    if (
      initialSelect &&
      initialSelect.acid
      // !initialCCSelect // commented out the cc as mop BPN-2568
    ) {
      //NOTE: Remove this !initialCCSelect when you don't want cc as mop BPN2187
      setSelectedPayment(initialSelect.acid);
    }
  }, [initialSelect]);

  useEffect(() => {
    if (initialCCSelect && initialCCSelect.cardNumber) {
      setSelectedPayment(initialCCSelect.cardNumber);
    }
  }, [initialCCSelect]);

  useEffect(() => {
    const cardWithPrimaryCardNo =
      ccDetails && ccDetails.length
        ? ccDetails.filter((c) => c.primaryCard)
        : [];
    const cardWithNoPrimaryCardNo =
      ccDetails && ccDetails.length
        ? ccDetails.filter((c) => !c.primaryCard)
        : [];
    cardWithPrimaryCardNo.sort((a, b) => {
      if (a.primaryCard < b.primaryCard) return -1;
      if (a.primaryCard > b.primaryCard) return 1;
      return 0;
    });
    const sortedCardList = [
      ...cardWithPrimaryCardNo,
      ...cardWithNoPrimaryCardNo,
    ];
    setSortedCreditCardList(sortedCardList);
  }, [ccDetails]);

  const handleProceed = () => {
    const selectedModeAcc = paymentModes.find(
      (mode) => mode.acid === selectedPayment
    );
    if (selectedModeAcc) {
      onPaymentSelect(selectedModeAcc);
      setPrevSelectedAccount(selectedModeAcc.acid);
      setSelectedPayment(selectedModeAcc.acid);
    }

    const selectedModeCC = sortedCreditCardList?.find(
      (mode) => mode.cardNumber === selectedPayment
    );
    if (selectedModeCC) {
      onCCPaymentSelect(selectedModeCC);
      setPrevSelectedAccount(selectedModeCC.cardNumber);
      setSelectedPayment(selectedModeCC.cardNumber);
    }
    onClose();
  };

  const handleBackClick = () => {
    setSelectedPayment(prevSelectedAccount || defaultSelected);
    onClose();
  };

  return (
    <DsBottomSheet
      open={isOpen}
      onClose={handleBackClick}
      sx={{
        "& .MuiPaper-root": {
          paddingTop: "var(--ds-spacing-zero)",
          paddingBottom: "var(--ds-spacing-zero)",
        },

        "& .MuiDialogContent-root": {
          paddingLeft: "var(--ds-spacing-zero)",
          paddingRight: "var(--ds-spacing-zero)",
          marginTop: "var(--ds-spacing-zero)",
        },

        "& .MuiDrawer-paper": {
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "38rem",
          maxHeight: "34rem",
        },
      }}
    >
      <DsBox>
        <DsBox
          display="flex"
          textAlign="start"
          flexDirection="column"
          sx={{
            paddingTop: "var(--ds-spacing-mild)",
            paddingBottom: "var(--ds-spacing-zero)",
            paddingLeft: "var(--ds-spacing-bitterCold)",
            paddingRight: "var(--ds-spacing-bitterCold)",
          }}
        >
          <DsTypography
            variant="headingBoldMedium"
            sx={{ marginBottom: "var(--ds-spacing-glacial)" }}
          >
            Pay Using
          </DsTypography>
          <DsTypography
            variant="bodyRegularMedium"
            sx={{
              color: "var(--ds-colour-typoSecondary)",
              marginBottom: "var(--ds-spacing-glacial)",
            }}
          >
            Choose your payment method
          </DsTypography>

          <DsBox>
            {ccDetails && ccDetails.length > 0 && (
              <>
                <DsBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    mt: "var(--ds-spacing-bitterCold)",
                    marginBottom: "var(--ds-spacing-bitterCold)",
                  }}
                >
                  <DsBox>
                    <DsTypography variant="subheadingSemiboldLarge">
                      Credit Cards
                    </DsTypography>
                  </DsBox>
                  <DsBox
                    display={"flex"}
                    sx={{
                      width: "50%",
                      justifyContent: "end",
                      flexGrow: 1,
                      borderRadius: "4px",
                    }}
                  >
                    <DsChip
                      label={
                        <DsBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="var(--ds-spacing-quickFreeze)"
                          padding="2px 8px 2px 8px"
                        >
                          <DsRemixIcon
                            className="ri-bank-card-line"
                            sx={{
                              fontSize: "14px",
                              color: "var(--ds-colour-iconTypical)",
                            }}
                          />
                          <DsTypography
                            variant="supportRegularInfo"
                            sx={{
                              color:
                                " var(--light-support-typical-sz-colour-support-typical, #165964)",
                            }}
                          >
                            A new way to pay your Bills!
                          </DsTypography>
                        </DsBox>
                      }
                      sx={{
                        backgroundColor:
                          "var(--ds-colour-supportTypicalNeutral)",
                        textTransform: "none",
                        padding: "var(--ds-spacing-zero)",
                      }}
                    />
                  </DsBox>
                </DsBox>

                {sortedCreditCardList &&
                  sortedCreditCardList.map((cc) => (
                    <DsBox
                      key={cc.cardNumber}
                      sx={{
                        display: "flex",
                        flexDirection: "row", // Display elements in a row
                        alignItems: "center",
                        cursor: "pointer",
                        mb: "var(--ds-spacing-mild)",
                        gap: "var(--ds-spacing-glacial)",
                      }}
                      // onClick={() => {
                      //   setSelectedPayment(cc.cardNumber);
                      //   onCCPaymentSelect(cc);
                      //   setTimeout(onClose, 100);
                      // }}
                    >
                      <DsAvatar
                        src={images.AxisWhiteLogo}
                        sx={{
                          borderRadius: "8px",
                          objectFit: "cover",
                          border: "1px solid var(--ds-colour-strokeDefault)",
                          boxSizing: "initial",
                          width: "28px",
                          height: "28px",
                        }}
                      />
                      <DsBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          overflow: "hidden",
                          gap: "var(--ds-spacing-deepFreeze)",
                        }}
                      >
                        <DsTypography variant="subheadingSemiboldDefault">
                          {cc.productType}
                        </DsTypography>
                        <DsTypography
                          variant="supportRegularMetadata"
                          sx={{
                            color: "var(--ds-colour-typoSecondary)",
                          }}
                        >
                          {updatedccmaskAccountNumber(cc.cardNumber)}
                        </DsTypography>
                      </DsBox>
                      <DsBox
                        sx={{
                          marginLeft: "auto", // Push the radio button to the right
                          "& .MuiFormControlLabel-root": {
                            marginRight: "0px",
                          },
                        }}
                      >
                        <DsRadio
                          value={cc.cardNumber}
                          checked={selectedPayment === cc.cardNumber}
                          onChange={() => setSelectedPayment(cc.cardNumber)}
                          disabled={false}
                        />
                      </DsBox>
                    </DsBox>
                  ))}
              </>
            )}
          </DsBox>
          {/* Need to add toast for not sufficient balance in any account
           */}
          <DsBox>
            {groupedPay &&
              Object.keys(groupedPay).map((type) => (
                <>
                  <DsBox
                    sx={{
                      marginTop:
                        ccDetails?.length === 0 || !ccDetails
                          ? "var(--ds-spacing-bitterCold)"
                          : "0px",
                      marginBottom: "var(--ds-spacing-bitterCold)",
                    }}
                  >
                    <DsTypography variant="subheadingSemiboldLarge">
                      ACCOUNTS
                    </DsTypography>
                  </DsBox>
                  {groupedPay[type]
                    .sort(
                      (acc1, acc2) =>
                        acc2.accountAvailableBalance +
                        acc2.accountFFDBalance -
                        (acc1.accountAvailableBalance + acc1.accountFFDBalance)
                    )
                    .map((pay) => {
                      const isDisabled =
                        pay.accountAvailableBalance + pay.accountFFDBalance <
                          paymentAmount && !isStandAloneAutoPayOrSchedulePay;
                      return (
                        <DsBox
                          key={pay.acid}
                          sx={{
                            display: "flex",
                            flexDirection: "row", // Display elements in a row
                            alignItems: "center",
                            cursor: "pointer",
                            mb: "var(--ds-spacing-mild)",
                            gap: "var(--ds-spacing-glacial)",
                          }}
                          // onClick={() => {
                          //   setSelectedPayment(pay.acid);
                          //   setTimeout(onClose, 100);
                          //   onPaymentSelect(pay);
                          // }}
                        >
                          <DsAvatar
                            src={images.AxisWhiteLogo}
                            sx={{
                              borderRadius: "8px",
                              objectFit: "cover",
                              border:
                                "1px solid var(--ds-colour-strokeDefault)",
                              width: "28px",
                              height: "28px",
                            }}
                          />
                          <DsBox
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-evenly",
                              overflow: "hidden",
                              gap: "var(--ds-spacing-deepFreeze)",
                            }}
                          >
                            <DsTypography variant="subheadingSemiboldDefault">
                              {getSchemeNameFromType(pay.schemType)}
                            </DsTypography>
                            <DsTypography
                              variant="supportRegularMetadata"
                              sx={{
                                color:
                                  pay.accountAvailableBalance < paymentAmount &&
                                  isFromPaymentPage
                                    ? "var(--ds-colour-iconNegative)"
                                    : "var(--ds-colour-typoSecondary)",
                              }}
                            >
                              {`${updatedMaskAccountNumber(
                                pay.acid
                              )} | Balance: ${localeAmountFormat(
                                pay.accountAvailableBalance
                              )}`}
                            </DsTypography>
                          </DsBox>
                          <DsBox
                            sx={{
                              marginLeft: "auto", // Push the radio button to the right

                              "& .MuiFormControlLabel-root": {
                                marginRight: "0px",
                              },
                            }}
                          >
                            <DsRadio
                              value={pay.acid}
                              checked={selectedPayment === pay.acid}
                              onChange={() => setSelectedPayment(pay.acid)}
                              disabled={isDisabled}
                            />
                          </DsBox>
                        </DsBox>
                      );
                    })}
                </>
              ))}
          </DsBox>
        </DsBox>
      </DsBox>

      <BottomSticker
        sx={{
          paddingTop: "var(--ds-spacing-bitterCold)",
          paddingBottom: "var(--ds-spacing-mild)",
          paddingLeft: "var(--ds-spacing-bitterCold)",
          paddingRight: "var(--ds-spacing-bitterCold)",
        }}
      >
        <DsBox
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DsBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "var(--ds-spacing-frostbite)",
              width: "100%",
            }}
          >
            <DsButton
              color="secondary"
              sx={{
                width: "100%",
              }}
              size="large"
              onClick={handleBackClick}
            >
              Back
            </DsButton>
            <DsButton
              sx={{
                width: "100%",
              }}
              size="large"
              onClick={() => handleProceed()}
              disabled={!selectedPayment}
            >
              Proceed
            </DsButton>
          </DsBox>
        </DsBox>
      </BottomSticker>
    </DsBottomSheet>
  );
};
