import React, { FC, useState } from "react";
import { DsBox, DsTypography, DsAvatar } from "@am92/react-design-system";

type BillerInfoCardProps = {
  billerLogo: string;
  billerName: string;
  shortName?: string;
  categoryLogo: any;
  category?: string;
  subtitle?: string;
  authValue?: string;
};
export const BillerInfoCard: FC<BillerInfoCardProps> = ({
  billerLogo,
  billerName,
  shortName,
  categoryLogo,
  category,
  subtitle,
  authValue,
}: BillerInfoCardProps) => {
  const [url, setUrl] = useState(billerLogo);
  const errorHandler = () => {
    let imgUrl = category
      ? categoryLogo[category || ""]
      : categoryLogo[subtitle || ""];
    setUrl(imgUrl);
  };

  return (
    <DsBox display={"flex"} alignItems="center" width={"100%"}>
      <DsBox>
        <DsAvatar
          src={url}
          sx={{
            borderRadius: "8px",
            objectFit: "cover",
            border: "1px solid #E2E2E2",
            boxSizing: "initial",
          }}
          imgProps={{
            onError: errorHandler,
          }}
        />
      </DsBox>
      <DsBox
        width={"100%"}
        marginLeft="var(--ds-spacing-glacial)"
        display={"flex"}
        flexDirection={"column"}
        gap="var(--ds-spacing-quickFreeze)"
        justifyContent={"center"}
      >
        {/* <DsTypography variant="bodyBoldLarge"></DsTypography> */}

        {billerName.length >= 25 ? (
          <DsTypography
            variant="bodyBoldLarge"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {billerName.substring(0, 17) + "..."}
          </DsTypography>
        ) : (
          <DsTypography
            variant="bodyBoldLarge"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {billerName}
          </DsTypography>
        )}

        {authValue && (
          <DsTypography
            variant="supportRegularFootnote"
            sx={{
              color: "var(--ds-colour-typoSecondary)",
            }}
          >
            {`...XX${authValue.substr(-4)}`}
          </DsTypography>
        )}

        {shortName && (
          <DsTypography
            variant="supportRegularFootnote"
            sx={{
              color: "var(--ds-colour-typoSecondary)",
            }}
          >
            Nick Name: {shortName}
          </DsTypography>
        )}
      </DsBox>
    </DsBox>
  );
};
