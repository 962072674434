export const MPIN_OTP = {
  MAX_ATTEMPS_MPIN: 5,
  MAX_ATTEMPS_OTP: 5,
  MAX_ATTEMPS_RESEND_OTP: 3,
  MAX_RESEND_TIMER: 30,
};

export const ERROR = {
  MPIN: {
    ACCOUNT_LOCKED: {
      STATUS: "ACCOUNT_LOCKED",
      MESSAGE: "User account has been locked",
    },
  },
};

export const AUTO_PARTIAL_PAY_LIMIT = {
  MIN_LIMIT: 1,
  MAX_LIMIT: 1000000,
};
export enum CUSTOMER_DETAIL_ERROR {
  CONTACT_INFORMATION_EMPTY = "BILLPAY_HANDSHAKE_03",
}
