import React, { ReactElement, ChangeEvent, Component } from "react";
import {
  DsButton,
  DsRemixIcon,
  DsTypography,
  DsBox,
  DsTextField,
  DsMenuItem,
  DsFormControl,
  DsSelect,
  DsAvatar,
  DsToggle,
  DsBottomSheet,
  enqueueNotistack,
} from "@am92/react-design-system";
// import {PayUsingAccountInfo} from './PaidUsingCard'
import { HorizontalLogoInfoButton } from "~/src/components/HorizontalInfoItem";
import { AccountDetail } from "~/src/Redux/Payment/Model/AccountDetail.model";
import { CreditCardDetailData } from "~/src/Redux/Payment/Model/CreditCard.model";
import { ApiResponseErrorImpl } from "~/src/Lib/types/api";
import { Biller } from "~/src/Redux/Billers/Model/Biller.model";
import { RootState } from "~/src/Configurations/AppStore";
import { AccountDetailsListState } from "~/src/Redux/Payment/Reducers/AccountDetails.reducers";
import {
  CreditCardDetailsListState,
  setCreditCardDataNotLoaded,
} from "~/src/Redux/Payment/Reducers/CreditCardDetails.reducers";
import { getAccountDetailsSelector } from "~/src/Redux/Payment/Selectors/AccountDetails.selector";
import { getCreditCardDetailsSelector } from "~/src/Redux/Payment/Selectors/CreditCardDetails.selector";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getAccountDetailsListThunk } from "~/src/Redux/Payment/Services/accountDetails.service";
import { getCreditCardDetailsListThunk } from "~/src/Redux/Payment/Services/creditcardDetails.service";
import withRouter, { IWithRouterProps } from "~/src/Lib/withRouter";
import { connect } from "react-redux";
import { DataStatus } from "~/src/Lib/types/datatransfer";
import { PayUsingAccountInfo } from "./PayUsingAccountInfo";
import { PaymentSelectionDrawer } from "./PayUsingDrawer";
import OverlayLoader from "~/src/components/OverlayLoader";
import { CUSTOM_ERROR } from "~/src/Constants/API_URLS";
import { PaymentMethodEnum } from "~/src/Redux/Payment/Model/PaymentDetail.model";
import { isCreditCardAllowedAccounts } from "../Payment.helper";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { commonErrorHandler } from "../../common/error/helper";
import { isCreditCardRestriced } from "../../common/utitlity/biller.utilities";

type PayUsingActions = {
  getAccountDetails: (customerId: string) => void;
  getCreditCardDetails: (customerId: string) => void;
  setCreditCardDataNotLoaded: (status: string) => void;
};

interface PayUsingProps extends IWithRouterProps {
  customerId: string;
  defaultAccount?: string;
  actions: PayUsingActions;
  accountDetailsData: AccountDetailsListState;
  creditCardDetailsData: CreditCardDetailsListState;
  onAccountSelect: (acc: AccountDetail) => void;
  onCCAccountSelect?: (ccAcc: CreditCardDetailData) => void;
  onError: (error: ApiResponseErrorImpl) => void;
  paymentAmount: number;
  biller: Biller;
  gaOperation: string;
  isStandAloneAutoPayOrSchedulePay?: boolean;
  isAutopayOpen?: boolean;
  onClose?: () => void;
  defaultCCAccount?: string;
  isFromPaymentPage?: boolean;
}

interface PayUsingState {
  isSelectionDrawer: boolean;
  accData: AccountDetail[];
  initialSelect: AccountDetail | undefined;
  initialCCSelect: CreditCardDetailData | undefined;
  selectedAccount: AccountDetail | undefined;
  selectedCCAccount: CreditCardDetailData | undefined;
  isCCSelected: boolean;
  isCCAvailable: boolean;
  isCasaAccountSelected: boolean;
  isBalanceAvailable: boolean;
  hasShownError: boolean;
  isCreditCardSelectedFromDrawer: boolean;
}

class PayUsing extends Component<PayUsingProps, PayUsingState> {
  constructor(props: PayUsingProps) {
    super(props);
    this.state = {
      isSelectionDrawer: false,
      accData: [],
      initialSelect: undefined,
      initialCCSelect: undefined,
      selectedAccount: undefined,
      selectedCCAccount: undefined,
      isCCSelected: false,
      isCCAvailable: false,
      isCasaAccountSelected: false,
      isBalanceAvailable: true,
      hasShownError: false,
      isCreditCardSelectedFromDrawer: false,
    };
  }

  IsCreditCardAllowed = () => {
    const { biller } = this.props;

    if (Object.keys(biller)?.length > 0) {
      return biller.paymentMethods.filter(
        (item) => item.paymentMethod === PaymentMethodEnum.CreditCard
      )?.length > 0
        ? true
        : false;
    }
    return false;
  };

  componentDidMount(): void {
    this.props.actions.setCreditCardDataNotLoaded(DataStatus.NOT_LOADED);
    this.handleLoadData();
  }

  componentDidUpdate(prevProps: PayUsingProps, prevState: PayUsingState): void {
    const {
      actions: { getCreditCardDetails },
      accountDetailsData: { accountDetailsData },
      creditCardDetailsData: { creditCardData },
      biller,
      customerId,
      paymentAmount,
      defaultAccount,
      onAccountSelect,
      onCCAccountSelect,
      isStandAloneAutoPayOrSchedulePay,
    } = this.props;

    const { accData, isCCAvailable } = this.state;

    if (
      accountDetailsData &&
      accountDetailsData.data &&
      accountDetailsData.data.AccountDetails &&
      prevProps.accountDetailsData.accountDetailsData.data?.AccountDetails !==
        accountDetailsData.data.AccountDetails
    ) {
      // Update the accData state when accountDetailsData is loaded
      this.setState({
        accData: accountDetailsData.data.AccountDetails,
      });
    }

    if (paymentAmount === 0) return;

    if (accountDetailsData.status === DataStatus.LOADED) {
      if (
        !accountDetailsData.data?.AccountDetails ||
        (accountDetailsData.data &&
          accountDetailsData.data.AccountDetails &&
          accountDetailsData.data.AccountDetails?.length === 0)
      ) {
        if (
          accountDetailsData?.data?.AccountDetails?.length === 0 &&
          isCreditCardRestriced(biller.billerCategory) &&
          !this.state.hasShownError
        )
          return (
            enqueueNotistack({
              message: "No CASA Accounts available",
              variant: "error",
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              style: { paddingBottom: "var(--ds-spacing-warm)" },
            }),
            this.setState({ hasShownError: true })
          );

        if (
          !accountDetailsData.data?.AccountDetails ||
          (accountDetailsData.data &&
            accountDetailsData.data.AccountDetails &&
            accountDetailsData.data.AccountDetails?.length === 0 &&
            // !isCCAvailable &&
            creditCardData.status === DataStatus.LOADED &&
            creditCardData.data?.length === 0)
        ) {
          if (!this.state.hasShownError) {
            this.props.onError(
              new ApiResponseErrorImpl(
                CUSTOM_ERROR.PAYMENT_NO_ACCOUNT.CODE,
                CUSTOM_ERROR.PAYMENT_NO_ACCOUNT.STATUS,
                CUSTOM_ERROR.PAYMENT_NO_ACCOUNT.MESSAGE
              )
            );
            this.setState({ hasShownError: true });
          }
        }
      } else if (
        !this.props.isAutopayOpen &&
        accountDetailsData.data?.AccountDetails.filter(
          (acc) =>
            acc.accountAvailableBalance + acc.accountFFDBalance >= paymentAmount
        )?.length === 0
      ) {
        if (
          creditCardData.status === DataStatus.LOADED &&
          prevProps.creditCardDetailsData.creditCardData.status !==
            this.props.creditCardDetailsData.creditCardData.status &&
          this.state.isBalanceAvailable
        ) {
          if (creditCardData.data && creditCardData.data?.length > 0) {
            this.setState({
              initialCCSelect: creditCardData.data[0],
              isCCSelected: true,
              isCasaAccountSelected: false,
              selectedCCAccount: creditCardData.data[0],
            });
            onCCAccountSelect && onCCAccountSelect(creditCardData.data[0]);
          } else {
            // No CreditCard data available, show an error

            this.props.onError(
              new ApiResponseErrorImpl(
                CUSTOM_ERROR.PAYMENT_INSUFFICIENT_BALANCE.CODE,
                CUSTOM_ERROR.PAYMENT_INSUFFICIENT_BALANCE.STATUS,
                CUSTOM_ERROR.PAYMENT_INSUFFICIENT_BALANCE.MESSAGE
              )
            );

            this.setState({
              isBalanceAvailable: !this.state.isBalanceAvailable,
            });

            if (
              accountDetailsData.data &&
              accountDetailsData.data.AccountDetails &&
              accountDetailsData.data.AccountDetails?.length > 0
            ) {
              // when no account available with sufficient fund, set account to display cashback

              this.setState(
                {
                  initialSelect: accountDetailsData?.data?.AccountDetails[0],
                  selectedAccount: accountDetailsData?.data?.AccountDetails[0],
                  isCasaAccountSelected: true,
                  isCCSelected: false,
                },
                () => {
                  if (this.state.initialSelect) {
                    onAccountSelect(this.state.initialSelect);
                  }
                }
              );
            }
          }
        } else if (
          accountDetailsData.data &&
          accountDetailsData.data.AccountDetails &&
          accountDetailsData.data.AccountDetails?.length > 0 &&
          prevProps.accountDetailsData.accountDetailsData.status !==
            this.props.accountDetailsData.accountDetailsData.status &&
          this.props.creditCardDetailsData.creditCardData.status ===
            DataStatus.NOT_LOADED
        ) {
          this.setState(
            {
              initialSelect: accountDetailsData?.data?.AccountDetails[0],
              selectedAccount: accountDetailsData?.data?.AccountDetails[0],
              isCasaAccountSelected: true,
              isCCSelected: false,
            },
            () => {
              if (this.state.initialSelect) {
                onAccountSelect(this.state.initialSelect);
              }
            }
          );
        }
      } else {
        accountDetailsData.data?.AccountDetails.sort(
          (acc1, acc2) =>
            acc2.accountFFDBalance +
            acc2.accountAvailableBalance -
            (acc1.accountFFDBalance + acc1.accountAvailableBalance)
        );
        if (defaultAccount && this.props.isFromPaymentPage) {
          const defAcc = accountDetailsData?.data?.AccountDetails.find((ac) =>
            ac.acid.endsWith(defaultAccount.slice(-4))
          );
          if (
            defAcc &&
            defAcc.accountFFDBalance + defAcc.accountAvailableBalance >
              paymentAmount &&
            prevState.initialSelect !== defAcc
          ) {
            this.setState(
              {
                initialSelect: defAcc,
                selectedAccount: defAcc,
                isCasaAccountSelected: true,
                isCCSelected: false,
              },
              () => {
                if (this.state.initialSelect) {
                  onAccountSelect(this.state.initialSelect);
                }
              }
            );
          }
        } else if (
          accountDetailsData.data &&
          accountDetailsData.data.AccountDetails &&
          accountDetailsData.data.AccountDetails?.length > 0 &&
          prevState.initialSelect !==
            accountDetailsData.data?.AccountDetails[0] &&
          this.props.isFromPaymentPage &&
          !this.props.defaultCCAccount
        ) {
          this.setState({
            initialSelect: accountDetailsData?.data?.AccountDetails[0],
            selectedAccount: accountDetailsData?.data?.AccountDetails[0],
            isCasaAccountSelected: true,
            isCCSelected: false, // Note: comment out these two lines if you don't want casa as default mop
          });
        }
      }
      if (isStandAloneAutoPayOrSchedulePay) {
        if (
          defaultAccount &&
          accountDetailsData.data &&
          !this.props.defaultCCAccount
        ) {
          const defAcc = accountDetailsData?.data?.AccountDetails?.find(
            (ac) =>
              ac.acid.substring(ac?.acid?.length - 4) ===
              defaultAccount.substring(defaultAccount?.length - 4)
          );

          if (defAcc && prevState.initialSelect !== defAcc) {
            this.setState(
              {
                initialSelect: defAcc,
                selectedAccount: defAcc,
                isCasaAccountSelected: true,
                isCCSelected: false,
              },
              () => onAccountSelect(defAcc)
            );
          }
        }
        if (
          this.props.defaultCCAccount &&
          creditCardData &&
          creditCardData?.data &&
          !defaultAccount &&
          creditCardData?.status !==
            prevProps?.creditCardDetailsData?.creditCardData?.status
        ) {
          const defCC = creditCardData?.data?.find(
            (cc) =>
              cc.cardNumber.substring(cc.cardNumber.length - 4) ===
              this.props.defaultCCAccount
          );
          if (defCC && onCCAccountSelect) {
            this.setState(
              {
                initialCCSelect: defCC,
                selectedCCAccount: defCC,
                isCasaAccountSelected: false,
                isCCSelected: true,
              },
              () => onCCAccountSelect(defCC)
            );
          }
        }
        if (
          !this.props.defaultCCAccount &&
          !defaultAccount &&
          accountDetailsData &&
          accountDetailsData?.data &&
          accountDetailsData?.data?.AccountDetails?.length &&
          prevProps.accountDetailsData?.accountDetailsData?.status !==
            accountDetailsData?.status
        ) {
          this.setState(
            {
              initialSelect: accountDetailsData?.data?.AccountDetails[0],
              selectedAccount: accountDetailsData?.data?.AccountDetails[0],
              isCasaAccountSelected: true,
              isCCSelected: false,
            },
            () => {
              if (this.state.initialSelect) {
                onAccountSelect(this.state.initialSelect);
              }
            }
          );
        }
      }
    }

    if (
      prevState.initialSelect !== this.state.initialSelect &&
      this.state.initialSelect
      // (creditCardData.data?.length === 0 ||
      //   creditCardData.status === DataStatus.NOT_LOADED ||
      //   isCreditCardRestriced(biller.billerCategory)) // Note: commenting this due BPN-2568 (changing back to casa as mop)
    ) {
      onAccountSelect(this.state.initialSelect);
    }

    if (accountDetailsData.status === DataStatus.ERRORED) {
      const err = CUSTOM_ERROR.PAYMENT_UNABLE_TO_FETCH_ACCOUNT;

      if (
        accountDetailsData.error &&
        isCCAvailable &&
        creditCardData.status === DataStatus.LOADED &&
        creditCardData.data?.length === 0
      ) {
        commonErrorHandler(accountDetailsData.error);
      } else if (
        isCCAvailable &&
        creditCardData.status === DataStatus.LOADED &&
        creditCardData.data &&
        creditCardData.data?.length > 0
      ) {
        if (prevState.initialCCSelect !== creditCardData.data[0]) {
          this.setState(
            {
              initialCCSelect: creditCardData.data[0],
              selectedCCAccount: creditCardData.data[0],
              isCasaAccountSelected: false,
              isCCSelected: true,
            },
            () => {
              if (this.state.initialCCSelect && onCCAccountSelect) {
                onCCAccountSelect(this.state.initialCCSelect);
              }
            }
          );
        }
      } else {
        commonErrorHandler(
          new ApiResponseErrorImpl(err.CODE, err.STATUS, err.MESSAGE)
        );
      }
    }

    if (
      accountDetailsData.status === DataStatus.LOADED &&
      (!accountDetailsData.data?.AccountDetails ||
        accountDetailsData.data?.AccountDetails?.length === 0) &&
      creditCardData.status === DataStatus.LOADED &&
      isCCAvailable &&
      creditCardData.data &&
      creditCardData.data?.length > 0
    ) {
      if (prevState.initialCCSelect !== creditCardData.data[0]) {
        this.setState({
          initialCCSelect: creditCardData.data[0],
          isCCSelected: true,
          isCasaAccountSelected: false,
          selectedCCAccount: creditCardData.data[0],
        });

        if (onCCAccountSelect) {
          onCCAccountSelect(creditCardData.data[0]);
        }
      }
    }

    // Note: when cc details api loaded before the casa acc details api
    if (
      creditCardData &&
      creditCardData.data &&
      creditCardData.status === DataStatus.LOADED &&
      prevState.initialCCSelect !== creditCardData.data[0]
    ) {
      if (this.props.defaultCCAccount) {
        const defCC = creditCardData?.data?.find(
          (cc) =>
            cc.cardNumber.substring(cc.cardNumber?.length - 4) ===
            this.props.defaultCCAccount
        );
        if (defCC && prevState.initialCCSelect !== defCC) {
          this.setState({
            initialCCSelect: defCC,
            selectedCCAccount: defCC,
            isCasaAccountSelected: false,
            isCCSelected: true,
          });
          if (onCCAccountSelect) {
            onCCAccountSelect(defCC);
          }
        }
      }
      // Note: Remove this if you don't want cc as mop
      // else if (
      //   this.props.isFromPaymentPage &&
      //   !this.props.defaultCCAccount &&
      //   !this.props.defaultAccount &&
      //   prevState.initialCCSelect !== creditCardData.data[0]
      // ) {
      //   this.setState({
      //     initialCCSelect: creditCardData.data[0],
      //     isCCSelected: true,
      //     isCasaAccountSelected: false,
      //     selectedCCAccount: creditCardData.data[0],
      //   });

      //   if (onCCAccountSelect) {
      //     onCCAccountSelect(creditCardData.data[0]);
      //   }
      // }
    }

    //NOTE: Add this condition to show CC as mop when both casa+cc were present for ticket:BPN2187
    // Add this one if you want cc as mode of payment.
    // if (
    //   this.props.accountDetailsData.accountDetailsData.status ===
    //     DataStatus.LOADED &&
    //   this.props.creditCardDetailsData.creditCardData.status !==
    //     prevProps.creditCardDetailsData.creditCardData.status &&
    //   !this.props.defaultAccount &&
    //   !this.props.defaultCCAccount
    // ) {
    //   if (
    //     this.props.creditCardDetailsData.creditCardData.status ===
    //       DataStatus.LOADED &&
    //     creditCardData.data &&
    //     creditCardData.data.length > 0
    //   ) {
    //     this.setState({
    //       initialCCSelect: creditCardData.data[0],
    //       isCCSelected: true,
    //       isCasaAccountSelected: false,
    //       selectedCCAccount: creditCardData.data[0],
    //     });
    //     if (onCCAccountSelect) {
    //       onCCAccountSelect(creditCardData.data[0]);
    //     }
    //   } else if (
    //     creditCardData.status === DataStatus.LOADED &&
    //     creditCardData.data &&
    //     creditCardData.data.length === 0 &&
    //     accountDetailsData.data &&
    //     accountDetailsData.data.AccountDetails.length > 0
    //   ) {
    //     this.setState({
    //       initialSelect: accountDetailsData.data.AccountDetails[0],
    //       selectedAccount: accountDetailsData.data.AccountDetails[0],
    //       isCasaAccountSelected: true,
    //       isCCAvailable: false,
    //     });

    //     if (this.state.initialSelect) {
    //       onAccountSelect(this.state.initialSelect);
    //     }
    //   }
    // }
    // // NOTE: This condition is usefull when the cc-api is loaded before the casa-api
    // else if (
    //   this.props.creditCardDetailsData.creditCardData.status ===
    //     DataStatus.LOADED &&
    //   prevProps.accountDetailsData.accountDetailsData.status !==
    //     this.props.accountDetailsData.accountDetailsData.status &&
    //   !this.props.defaultAccount &&
    //   !this.props.defaultCCAccount
    // ) {
    //   if (creditCardData.data && creditCardData.data.length > 0) {
    //     this.setState({
    //       initialCCSelect: creditCardData.data[0],
    //       isCCSelected: true,
    //       isCasaAccountSelected: false,
    //       selectedCCAccount: creditCardData.data[0],
    //     });
    //     if (onCCAccountSelect) {
    //       onCCAccountSelect(creditCardData.data[0]);
    //     }
    //   } else if (
    //     creditCardData.data &&
    //     creditCardData.data.length === 0 &&
    //     accountDetailsData.status === DataStatus.LOADED &&
    //     accountDetailsData.data &&
    //     accountDetailsData.data.AccountDetails.length > 0
    //   ) {
    //     this.setState({
    //       initialSelect: accountDetailsData.data.AccountDetails[0],
    //       selectedAccount: accountDetailsData.data.AccountDetails[0],
    //       isCasaAccountSelected: true,
    //       isCCAvailable: false,
    //     });

    //     if (this.state.initialSelect) {
    //       onAccountSelect(this.state.initialSelect);
    //     }
    //   } else if (
    //     accountDetailsData.status === DataStatus.ERRORED &&
    //     creditCardData.data &&
    //     creditCardData.data.length > 0
    //   ) {
    //     this.setState({
    //       initialCCSelect: creditCardData.data[0],
    //       isCCSelected: true,
    //       isCasaAccountSelected: false,
    //       selectedCCAccount: creditCardData.data[0],
    //     });
    //     if (onCCAccountSelect) {
    //       onCCAccountSelect(creditCardData.data[0]);
    //     }
    //   }
    // }
  }

  // //NOTE: include this check for the ticket BPN2200 commenting for now.
  // allowedPrepaidCC = (billerId: string) => {
  //   return ["MTNLMUMPRE", "MTNLDELPRE", "BSNL00000NATHL"].includes(billerId);
  // };

  handleLoadData = () => {
    const {
      actions: { getAccountDetails, getCreditCardDetails },
      customerId,
      accountDetailsData,
      creditCardDetailsData,
      biller,
    } = this.props;
    if (customerId && customerId !== "") {
      getAccountDetails(customerId);
      if (
        biller &&
        this.IsCreditCardAllowed() &&
        biller.isBillerBBPS &&
        !isCreditCardRestriced(biller.billerCategory)
        // this.allowedPrepaidCC(biller.billerId) // include this check for the ticket BPN2200 commenting for now
        // isCreditCardAllowedAccounts(accountSchemeCodes)
      ) {
        getCreditCardDetails(customerId);
        this.setState({ isCCAvailable: true });
      }
    }
  };

  handleOpen = () => {
    const { gaOperation, biller } = this.props;

    this.setState({ isSelectionDrawer: !this.state.isSelectionDrawer });

    handleGAPush(
      CategoryHelperObject.categoryPayBills + gaOperation,
      "pageload",
      "pay using",
      `vpv/pay-bills/${biller.billerCategory.toLowerCase()}/${gaOperation}/pay-using`,
      `pay bills - ${biller.billerCategory.toLowerCase()} - ${gaOperation} - pay using`,
      "event_pay_bills_onload_vpv"
    );
  };
  render() {
    const {
      biller,
      accountDetailsData: { accountDetailsData },
      creditCardDetailsData: { creditCardData },
      onAccountSelect,
      onCCAccountSelect,
      paymentAmount,
      isStandAloneAutoPayOrSchedulePay,
    } = this.props;

    const {
      accData,
      initialSelect,
      initialCCSelect,
      selectedAccount,
      isCCAvailable,
      isCCSelected,
      selectedCCAccount,
      isCasaAccountSelected,
    } = this.state;

    return (
      <>
        {accountDetailsData?.status === DataStatus.LOADING ||
        creditCardData.status === DataStatus.LOADING ? (
          <OverlayLoader />
        ) : null}

        {accData || (creditCardData.data && creditCardData.data?.length) ? (
          <PayUsingAccountInfo
            accountDetail={selectedAccount || accData[0]}
            onClick={this.handleOpen}
            isCCSelected={isCCSelected}
            creditCardDetailData={
              selectedCCAccount ||
              initialCCSelect ||
              (creditCardData.data && creditCardData.data[0])
            }
            initialCCSelect={initialCCSelect}
            isCasaAccountSelected={isCasaAccountSelected}
            paymentAmount={paymentAmount}
            isFromPaymentPage={this.props.isFromPaymentPage}
            ccApiStatus={this.props.creditCardDetailsData.creditCardData.status}
            accApiStatus={
              this.props.accountDetailsData.accountDetailsData.status
            }
            isCreditCardSelectedFromDrawer={
              this.state.isCreditCardSelectedFromDrawer
            }
            isdefaultCCAccount={this.props.defaultCCAccount}
          />
        ) : null}

        {accData || (creditCardData.data && creditCardData.data?.length) ? (
          <PaymentSelectionDrawer
            isOpen={this.state.isSelectionDrawer}
            onClose={() =>
              this.setState({
                isSelectionDrawer: !this.state.isSelectionDrawer,
              })
            }
            onPaymentSelect={(acc) => {
              this.setState({
                selectedAccount: acc,
                isCCSelected: false,
                isCasaAccountSelected: true,
              });
              onAccountSelect(acc);
            }}
            onCCPaymentSelect={(ccAcc) => {
              this.setState({
                selectedCCAccount: ccAcc,
                isCCSelected: true,
                isCasaAccountSelected: false,
                isCreditCardSelectedFromDrawer: true,
              });
              onCCAccountSelect && onCCAccountSelect(ccAcc);
            }}
            initialSelect={initialSelect}
            initialCCSelect={initialCCSelect}
            defaultSelected={
              initialCCSelect
                ? initialCCSelect.cardNumber
                : initialSelect?.acid || ""
            }
            paymentModes={accData || []}
            ccDetails={creditCardData.data}
            isStandAloneAutoPayOrSchedulePay={isStandAloneAutoPayOrSchedulePay}
            paymentAmount={paymentAmount}
            isFromPaymentPage={this.props.isFromPaymentPage}
          />
        ) : null}

        {this.props.accountDetailsData?.accountDetailsData?.status ===
          DataStatus.LOADING ||
        this.props.creditCardDetailsData?.creditCardData?.status ===
          DataStatus.LOADING ? (
          <OverlayLoader />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (
  state: RootState & {
    accountDetailsData: AccountDetailsListState;
    creditCardDetailsData: CreditCardDetailsListState;
  }
) => {
  const accountDetailsData = getAccountDetailsSelector(state);
  const creditCardDetailsData = getCreditCardDetailsSelector(state);

  return {
    accountDetailsData,
    creditCardDetailsData,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  actions: {
    getAccountDetails: (customerId: string) =>
      dispatch(getAccountDetailsListThunk(customerId)),
    getCreditCardDetails: (customerId: string) =>
      dispatch(getCreditCardDetailsListThunk(customerId)),
    setCreditCardDataNotLoaded: (status: string) =>
      dispatch(setCreditCardDataNotLoaded(status)),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayUsing)
);
