import React, { lazy } from "react";
import { RouteObject, createBrowserRouter } from "react-router-dom";

import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { billerRoutes } from "~/src/Pages/billers/biller.routes";

import MainLayout from "~/src/Layouts/Main.Layout";
import { paymentRoutes } from "../Pages/Payment/payment.routes";
import { supportRoutes } from "../Pages/Support/support.routes";
import { cashbackRoutes } from "../Pages/Cashback/cashback.routes";
import { ApplicationErrorBoundary } from "../components/ApplicationErrorBoundary";
import {
  homePageLoader,
  verifyDetailPageLoader,
} from "~/src/Pages/Home/HomePageLoader";
import ManageBillerBillDetailPage from "../Pages/ManageBillers/components/ManageBiller.billDetail.page";
import { manageBillerDetailsLoader } from "../Pages/ManageBillers/manageBillerLoader";

const HomePage = React.lazy(() => import("~/src/Pages/Home/Home.Page"));
const ManageBillersPage = React.lazy(
  () => import("~/src/Pages/ManageBillers/ManageBillers.page")
);

const ManageBillerPaymentHistoryPage = React.lazy(
  () => import("~/src/Pages/ManageBillers/components/PaymentHistoryDetails")
);
const VerifyDetailPage = React.lazy(
  () => import("~/src/Pages/Home/VerifyDetail.Page")
);
const FAQPage = React.lazy(() => import("~/src/Pages/FAQ/FAQ.Page"));
const HistoryPage = React.lazy(
  () => import("~/src/Pages/History/History.Page")
);
const CashbackLandingPage = React.lazy(
  () => import("~/src/Pages/Cashback/CashbackLanding.Page")
);
const SupportPage = React.lazy(
  () => import("~/src/Pages/Support/Support.page")
);
const NotFoundPage = React.lazy(
  () => import("~/src/Pages/NotFound/NotFound.Page")
);

const CommonErrorPage = React.lazy(
  () => import("~/src/Pages/common/error/CommonError.page")
);

const CashbackWelcomePage = React.lazy(
  () => import("~/src/Pages/Cashback/CashbackWelcome.page")
);

const TechnicalErrorPage = React.lazy(
  () => import("~/src/Pages/common/error/TechnicalError.page")
);

const AppMaintenance = React.lazy(
  () => import("~/src/Pages/common/error/AppMaintenance.page")
);

const SessionExpiredErrorPage = React.lazy(
  () => import("~/src/Pages/common/error/SessionExpiredError.page")
);
const UpdateInformationErrorPage = React.lazy(
  () => import("~/src/Pages/common/error/UpdateInformationError.page")
);

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    errorElement: <ApplicationErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.HOME.pathname,
        element: <HomePage />,
        loader: homePageLoader,
      },
      {
        path: APP_ROUTES.MANAGE_BILLER.pathname,
        element: <ManageBillersPage />,
      },
      {
        path: APP_ROUTES.MANAGE_BILLER_DETAILS.pathname,
        element: <ManageBillerBillDetailPage />,
        loader: manageBillerDetailsLoader,
      },
      {
        path: APP_ROUTES.MANAGE_BILLER_PAY_HISTORY.pathname,
        element: <ManageBillerPaymentHistoryPage />,
      },
      {
        path: APP_ROUTES.VERIFY_PAGE.pathname,
        element: <VerifyDetailPage />,
        loader: verifyDetailPageLoader,
      },
      {
        path: APP_ROUTES.FAQ.pathname,
        element: <FAQPage />,
      },
      {
        path: APP_ROUTES.HISTORY_PAGE.pathname,
        element: <HistoryPage />,
      },
      {
        path: APP_ROUTES.CASHBACK.pathname,
        element: <CashbackLandingPage />,
      },
      {
        path: APP_ROUTES.SUPPORT.pathname,
        element: <SupportPage />,
      },
      {
        path: APP_ROUTES.CASHBACK_WELCOME.pathname,
        element: <CashbackWelcomePage />,
      },
      {
        path: APP_ROUTES.BILLERS.pathname,
        children: billerRoutes,
      },
      {
        path: APP_ROUTES.PAYMENT.pathname,
        children: paymentRoutes,
      },
      {
        path: APP_ROUTES.SUPPORT.pathname,
        children: supportRoutes,
      },
      {
        path: APP_ROUTES.CASHBACK.pathname,
        children: cashbackRoutes,
      },
      {
        path: APP_ROUTES.SOMETHING_WENT_WRONG.pathname,
        element: <CommonErrorPage />,
      },
      {
        path: APP_ROUTES.TECHNICAL_ERROR.pathname,
        element: <TechnicalErrorPage />,
      },
      {
        path: APP_ROUTES.UNDER_MAINTENANCE.pathname,
        element: <AppMaintenance />,
      },
      {
        path: APP_ROUTES.SESSION_EXPIRED.pathname,
        element: <SessionExpiredErrorPage />,
      },
      {
        path: APP_ROUTES.UPDATE_INFORMATION.pathname,
        element: <UpdateInformationErrorPage />,
      },
    ],
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    element: <CommonErrorPage />,
  } as RouteObject,
];
const getAppRouter = () => createBrowserRouter(routeObj);

export default getAppRouter;
