export const URL = {
  BASE: process.env.API_BASE_URL,
  AUTH_BASE: process.env.AUTH_BASE_URL,
  BANNERS: `${process.env.API_BASE_URL}/banners`,
  CATEGORY_LIST: `${process.env.API_BASE_URL}/category/get-category-list`,
  AUTHENTICATION: {
    HANDSHAKE: `${process.env.AUTH_BASE_URL}/auth/handshake`,
    INVALIDATE_TOKEN: `${process.env.AUTH_BASE_URL}/auth/invalidate-token`,
    CUSTOMER_DETAIL: `${process.env.API_BASE_URL}/init`,
    DISCOVERY_API_CITI: `${process.env.API_BASE_URL}/citi-bank/get-customer-details`,
  },
  PAYMENT: {
    ACCOUNT_DETAIL: `${process.env.API_BASE_URL}/account-details`,
    MAKE_PAYMENT: `${process.env.API_BASE_URL}/payment/make-payment`,
    STOP_SCHEDULED_PAYMENT: `${process.env.API_BASE_URL}/payment/stop-scheduled-payment`,
    VALIDATE_MPIN: `${process.env.API_BASE_URL}/mpin/verify`,
    VALIDATE_OTP: `${process.env.API_BASE_URL}/notification/verify-otp`,
    SEND_OTP: `${process.env.API_BASE_URL}/notification/send-otp`,
    DOWNLOAD_RECIEPT: `${process.env.API_BASE_URL}/payment/generate-receipt`,
    CREDIT_CARD_DETAIL: `${process.env.API_BASE_URL}/cards/list-credit-cards`,
  },
  BILLER: {
    BILLER_ACCOUNT: `${process.env.API_BASE_URL}/bill/due`,
    BILL_VALIDATE: `${process.env.API_BASE_URL}/validate/bill-not-register`,
    GET_SCHEDULED_BILLERS: `${process.env.API_BASE_URL}/payment/history`,
    PAYMENT_HISTORY: `${process.env.API_BASE_URL}/payment/history`,
    BILL_VALIDATE_AND_REGISTER: `${process.env.API_BASE_URL}/validate/bill`,
    REGISTER_BILLER: `${process.env.API_BASE_URL}/biller/register`,
    DELETE_REGISTERED_BILLER: `${process.env.API_BASE_URL}/biller/delete`,
    MODIFY_REGISTERED_BILLER: `${process.env.API_BASE_URL}/biller/modify`,
    NEW_REGISTER_BILLER: `${process.env.API_BASE_URL}/biller/register`,
    FIND_BILLER: `${process.env.API_BASE_URL}/biller/find?`,
  },
  SUPPORT: {
    COMPLAINT_TYPES: `${process.env.API_BASE_URL}/complaint/get-complaint-types`,
    RAISE_COMPLAINT: `${process.env.API_BASE_URL}/complaint/raise-complaint`,
    GET_PAST_TICKETS: `${process.env.API_BASE_URL}/complaint/past-tickets`,
    GET_COMPLAINT_STATUS: `${process.env.API_BASE_URL}/complaint/status`,
  },
  CASHBACK: {
    CASHBACK_DETAILS: `${process.env.API_BASE_URL}/cashback/get-customer-detail`,
    CASHBACK_ACTIVE_OFFERS: `${process.env.API_BASE_URL}/cashback/search-active-offers`,
    RECENT_CASHBACK_DETAILS: `${process.env.API_BASE_URL}/cashback/view-all`,
    AUTOPAY_STREAK_DETAILS: `${process.env.API_BASE_URL}/cashback/autopay-streak`,
    MILESTONE_DETAILS: `${process.env.API_BASE_URL}/cashback/milestone`,
    PAYMENT_STATUS_WITH_SOURCEREF: `${process.env.API_BASE_URL}/payment/get-status-with-sourceref`,
  },
  PROMOCODE: {
    PROMOCODES_ACTIVE_COUPONS: `${process.env.API_BASE_URL}/promocode/search-active-promocodes`,
  },
  LOCATION: {
    CITY_LIST: `${process.env.API_BASE_URL}/location/cities`,
    STATE_FROM_CORDS: `${process.env.API_BASE_URL}/location/current`,
  },
  PREPAID: {
    PREPAID_OPERATOR: `${process.env.API_BASE_URL}/prepaid/get-operator`,
    OPERATOR_PLANS: `${process.env.API_BASE_URL}/prepaid/get-plans`,
    OPERATOR_CIRCLE: `${process.env.API_BASE_URL}/prepaid/get-circle-list`,
    PRINT_LOGS: `${process.env.API_BASE_URL}/prepaid/logs`,
  },
  TNC: {
    GET_TNC: `${process.env.API_BASE_URL}/tnc`,
  },
  FAQ: {
    GET_FAQ: `${process.env.API_BASE_URL}/faq`,
  },
  COACHMARKS: {
    DISPLAY_COACH_MARKS: `${process.env.API_BASE_URL}/coachmarks/display-coachmarks-flag`,
    PROCESS_COACH_MARKS: `${process.env.API_BASE_URL}/coachmarks/process-coachmarks-data`,
  },
};

export const NETWORK = {
  BASE_URL: URL.BASE,
  TIME_OUT: 70000,
};

export const CUSTOM_ERROR = {
  REQUEST_ERROR: {
    CODE: 600,
    MESSAGE: "Error in API Request",
  },
  RESPONSE_ERROR: {
    CODE: 700,
    MESSAGE: "Error in API Response",
  },
  TIMEOUT_ERROR: {
    CODE: 1000,
    STATUS: "TIMEDOUT",
    MESSAGE: "We are experiencing technical issues, please try again",
  },
  PAYMENT_UNABLE_TO_FETCH_ACCOUNT: {
    CODE: 3002,
    STATUS: "UNABLE TO FETCH ACCOUNT",
    MESSAGE: "Unable to fetch account detail.",
  },
  PAYMENT_NO_ACCOUNT: {
    CODE: 3000,
    STATUS: "NO ACCOUNT AVAILABLE",
    MESSAGE: "No Account available",
  },
  PAYMENT_INSUFFICIENT_BALANCE: {
    CODE: 3001,
    STATUS: "INSUFFICIENT BALANCE",
    MESSAGE: "No Account available with sufficient balance",
  },
  SESSION_EXPIRED: {
    CODE: 500,
    STATUS: "",
    MESSAGE: "Handshake is expired",
  },
};
