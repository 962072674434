import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { RootState } from "~/src/Configurations/AppStore";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { URL } from "~/src/Constants/API_URLS";
import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import { PlanDetailDto, PlanDetailMapper } from "../Model/prepaid.model";
import {
  fetchPrepaidPlansList,
  fetchPrepaidPlansListFail,
  fetchPrepaidPlansListSuccess,
} from "../Reducers/PrepaidPlans.reducer";
type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
const PREPAID_OPERATOR_PLANS_URL = URL.PREPAID.OPERATOR_PLANS;
export const getPrepaidPlansListThunk =
  (
    billerId: string,
    circleName: string,
    circleId: string,
    mobile: string,
    customerId: string
  ): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchPrepaidPlansList(customerId));
    try {
      const options: WebHttpRequestOptions = {
        url: `${PREPAID_OPERATOR_PLANS_URL}`,
        method: "POST",
        data: {
          billerid: billerId,
          circle_name: circleName,
          circleId: circleId,
          mobile: mobile,
          // to checking purpose of repeat recharge flow use this
          // billerid: "VILPRE",
          // circle_name: "Maharashtra and Goa",
          // mobile: mobile,
          // circleId: "14",

          /**Temporarily Try using dummy data in case the api is not working */
          // billerid: 'AIRTELPRE',
          // circle_name: 'Rajasthan',
          // mobile: '8107357801'
        },
      };
      const response = await asHttp.request(options);

      const mapper = new PlanDetailMapper();
      const mappedData = response.data.data
        .filter((pl: any) => pl.plan_status.toUpperCase() === "ACTIVE")
        .map((plan: any) => mapper.toModel(plan));
      dispatch(fetchPrepaidPlansListSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchPrepaidPlansListFail(error as ApiResponseError));
      throw error;
    }
  };
