import {
  DsBox,
  DsButton,
  DsCard,
  DsChip,
  DsRemixIcon,
  DsTypography,
  enqueueNotistack,
} from "@am92/react-design-system";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { handleGAPush } from "~/src/Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "~/src/Common/googleAnalytics/googleAnalyticsInterface";
import { BottomSticker } from "~/src/components/BottomSticker";
import {
  NavbarInjectedProps,
  withNavigationBar,
} from "~/src/components/NavigationBar";
import OverlayLoader from "~/src/components/OverlayLoader";
import {
  FullModalAlert,
  SuccessStatusModal,
} from "~/src/components/StatusModal";
import { TechnicalErrorDrawer } from "~/src/components/TechicalErrorDrawer";
import { RootState } from "~/src/Configurations/AppStore";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { ApiResponseErrorImpl } from "~/src/Lib/types/api";
import { DataStatus } from "~/src/Lib/types/datatransfer";
import withRouter, { IWithRouterProps } from "~/src/Lib/withRouter";
import { BillerInfoCard } from "~/src/Pages/ManageBillers/components/BillerInfoCard";
import { BillerAccount } from "~/src/Redux/Billers/Model/BillerAccount.model";
import { BillerDataStateType } from "~/src/Redux/Billers/Reducers/Biller.reducer";
import {
  BillerAccountStateType,
  setBillerAccountStatusNotLoaded,
} from "~/src/Redux/Billers/Reducers/BillerAccount.reducer";
import { DeleteRegisterBillerListState } from "~/src/Redux/Billers/Reducers/DeleteRegisterBiller.Reducer";
import { ModifyRegisteredBillerListState } from "~/src/Redux/Billers/Reducers/ModifyRegisteredBiller.reducer";
import { setBillerRegisterationToPaymentPage } from "~/src/Redux/Billers/Reducers/SearchService.reducer";
import { ValidatePaymentStateType } from "~/src/Redux/Billers/Reducers/ValidatePayment.reducer";
import { billersSelector } from "~/src/Redux/Billers/Selectors/Biller.selector";
import { billerAccountSelector } from "~/src/Redux/Billers/Selectors/BillerAccount.selector";
import { getDeleteRegisterBillerSelector } from "~/src/Redux/Billers/Selectors/DeleteRegisterBiller.selector";
import { getModifyRegisteredBillerSelector } from "~/src/Redux/Billers/Selectors/ModifyRegisteredBiller.selector";
import { validatePaymentSelector } from "~/src/Redux/Billers/Selectors/ValidatePayment.selector";
import { getBillerStatus } from "~/src/Redux/Billers/Services/Biller.Service";
import { getDueBills } from "~/src/Redux/Billers/Services/BillerAccount.Service";
import { getDeleteRegisteredBillerListThunk } from "~/src/Redux/Billers/Services/DeleteRegisterBiller.service";
import { getModifyRegisteredBillerListThunk } from "~/src/Redux/Billers/Services/ModifyRegisteredBiller.Service";
import { validatePayment } from "~/src/Redux/Billers/Services/ValidatePayment.service";
import { getCategoryLogo } from "~/src/Redux/Categories/Selector";
import { CustomerChannelEnum } from "~/src/Redux/Customer/Customer.reducer";
import {
  getCustomerData,
  getCustomerId,
} from "~/src/Redux/Customer/Customer.selector";
import { CustomerDetail } from "~/src/Redux/Customer/Model";
import { HistoryListState } from "~/src/Redux/History/Reducer";
import { getHistorySelector } from "~/src/Redux/History/Selector";
import {
  hideLoader,
  showLoader,
} from "~/src/Redux/Loaders/Reducers/Loaders.reducers";
import {
  BillerPaymentStatusEnum,
  PaymentDetail,
  PaymentStatusEnum,
} from "~/src/Redux/Payment/Model/PaymentDetail.model";
import { StopScheduledPaymentListState } from "~/src/Redux/Payment/Reducers/StopScheduledPayment.reducer";
import { getStopScheduledPaymentSelector } from "~/src/Redux/Payment/Selectors/StopScheduledPayment.selector";
import { stopScheduledPaymentListThunk } from "~/src/Redux/Payment/Services/stopScheduledPayment.service";
import { NicknameDrawer } from "../../billers/components/NicknameDrawer";
import { commonErrorHandler } from "../../common/error/helper";
import { isAutoPayRestriced } from "../../common/utitlity/biller.utilities";
import { localeAmountFormat } from "../../common/utitlity/validation.utilities";
import MpinModal from "../../Payment/components/MpinModal";
import OtpModal from "../../Payment/components/OtpModal";
import {
  updatedMaskAccountNumber,
  updatedccmaskAccountNumber,
} from "../../Payment/Payment.helper";
import { PAYMENT_ROUTES } from "../../Payment/payment.routes";
import {
  GAactionObjectResolver,
  ManageBillerOptions,
  ManageBillerSuccessModalStatusText,
  SelectionModalContentObject,
  billerStatusAmountResolver,
} from "../helpers/ManageBillerHelperFunctions";
import AutoPayModal from "./AutoPayModal";
import SelectionModal from "./SelectionModal";
import { BillerTypeEnum } from "~/src/Redux/Billers/Model/Biller.model";

interface ManageBillerBillDetailProps
  extends IWithRouterProps,
    NavbarInjectedProps {
  customerId: string;
  customerData: CustomerDetail;
  item: BillerAccount;
  historyDetailData: HistoryListState;
  deleteBillerData: DeleteRegisterBillerListState;
  modifyBillerData: ModifyRegisteredBillerListState;
  stopScheduledPaymentData: StopScheduledPaymentListState;
  validatePaymentData: ValidatePaymentStateType;
  billerState: BillerDataStateType;
  billerAccountData: BillerAccountStateType;
  categoryLogo: object;
  paymentJourneyData: any;
  actions: {
    deleteBiller: (customerId: string, billeraccountId: string) => void;
    modifyBiller: (
      customerId: string,
      mobile: string,
      email: string,
      billeraccountId: string,
      autopayStatus?: boolean,
      autopayAmount?: number,
      currency?: number,
      paymentAccount?: {
        paymentMethod: string;
        accountNumber?: string;
        ifsc?: string;
        cardStart?: string;
        cardEnd?: string;
        primeCardSerialNumber?: string;
      },
      isCreditCardSelected?: boolean,
      shortName?: string
    ) => void;
    validatePaymentReq: (
      customerId: string,
      mobile: string,
      email: string,
      billerId: string,
      authenticators: {
        parameterName: string;
        value: string;
      }[]
    ) => void;
    loadBillerStatus: (billerId: string) => void;
    stopScheduledPayment: (
      customerId: string,
      billId?: string,
      paymentId?: string
    ) => void;
    setBillerAccountStatusNotLoaded: () => void;
    getBillerAccounts: (customerId: string) => void;
    setBillerRegisterationToPaymentPage: (obj: any) => void;
    showLoader: () => void;
    hideLoader: () => void;
  };
}

interface ManageBillerBillDetailState {
  selectedBillerAccount: BillerAccount;
  nickname: string;
  mainHeading: string;
  description: string;
  operation: string;
  isMpinOpen: boolean;
  isOtpOpen: boolean;
  isFullPay: boolean;
  statusText: string;
  isNickNameModalOpen: boolean;
  onAutopayModalOpen: boolean;
  isSelectionModalOpen: boolean;
  paymentHistoryModal: boolean;
  isSuccessModalOpen: boolean;
  isTechnicalErrorOpen: boolean;
  isSchedulePaymentModalOpen: boolean;
  selectedDate: Date | null;
  isNoDueBillModalOpen: boolean;
}

class ManageBillerBillDetail extends Component<
  ManageBillerBillDetailProps,
  ManageBillerBillDetailState
> {
  datePickerRef = React.createRef<HTMLDivElement>();
  constructor(props: ManageBillerBillDetailProps) {
    super(props);
    this.state = {
      selectedBillerAccount: {} as BillerAccount,
      nickname: "",
      mainHeading: "",
      description: "",
      operation: "",
      statusText: "",
      isMpinOpen: false,
      isOtpOpen: false,
      isFullPay: true,
      isNickNameModalOpen: false,
      onAutopayModalOpen: false,
      isSelectionModalOpen: false,
      paymentHistoryModal: false,
      isSuccessModalOpen: false,
      isTechnicalErrorOpen: false,
      isSchedulePaymentModalOpen: false,
      selectedDate: null,
      isNoDueBillModalOpen: false,
    };
  }

  // componentDidMount(): void {
  //   const {
  //     actions,
  //     item,
  //     customerData: { customerId, emailId, mobile },
  //   } = this.props;
  // }

  componentDidUpdate(
    prevProps: ManageBillerBillDetailProps,
    prevState: ManageBillerBillDetailState
  ): void {
    const {
      deleteBillerData: { deleteRegisterBillerData },
      modifyBillerData: { modifyRegisteredBillerData },
      stopScheduledPaymentData: { stopScheduledPaymentData },
      billerState: { billerStatusStatus, billerStatusError },
      validatePaymentData: {
        validatePaymentData,
        validatePaymentStatus,
        validatePaymentError,
      },
    } = this.props;

    if (
      (deleteRegisterBillerData.status === DataStatus.LOADED &&
        deleteRegisterBillerData.data) ||
      (modifyRegisteredBillerData.status === DataStatus.LOADED &&
        modifyRegisteredBillerData.data) ||
      (stopScheduledPaymentData.status === DataStatus.LOADED &&
        stopScheduledPaymentData.data)
    ) {
      if (
        prevProps.modifyBillerData.modifyRegisteredBillerData.data !==
          modifyRegisteredBillerData.data ||
        prevProps.deleteBillerData.deleteRegisterBillerData.data !==
          deleteRegisterBillerData.data ||
        prevProps.stopScheduledPaymentData.stopScheduledPaymentData.data !==
          stopScheduledPaymentData.data
      ) {
        this.setState({ isSuccessModalOpen: true });
      }
    }

    //API Error case
    // Delete Api error case
    if (
      deleteRegisterBillerData.status === DataStatus.ERRORED &&
      deleteRegisterBillerData.error &&
      prevProps.deleteBillerData.deleteRegisterBillerData.status !==
        this.props.deleteBillerData.deleteRegisterBillerData.status
    ) {
      if (deleteRegisterBillerData.error.statusCode === 500) {
        this.setState({
          isTechnicalErrorOpen: !this.state.isTechnicalErrorOpen,
        });
      } else if (deleteRegisterBillerData.error) {
        commonErrorHandler(deleteRegisterBillerData.error);
      }
    }

    // Modify Api error case
    if (
      modifyRegisteredBillerData.status === DataStatus.ERRORED &&
      modifyRegisteredBillerData.error &&
      prevProps.modifyBillerData.modifyRegisteredBillerData.status !==
        this.props.modifyBillerData.modifyRegisteredBillerData.status
    ) {
      if (modifyRegisteredBillerData.error.statusCode === 500) {
        this.setState({
          isTechnicalErrorOpen: !this.state.isTechnicalErrorOpen,
        });
      } else if (modifyRegisteredBillerData.error) {
        commonErrorHandler(modifyRegisteredBillerData.error);
      }
    }

    //ValidatePayment eror case
    if (
      validatePaymentStatus === DataStatus.ERRORED &&
      validatePaymentError &&
      prevProps.validatePaymentData.validatePaymentStatus !==
        this.props.validatePaymentData.validatePaymentStatus
    ) {
      if (validatePaymentError.statusCode === 500) {
        this.setState({
          isTechnicalErrorOpen: !this.state.isTechnicalErrorOpen,
        });
      } else if (validatePaymentError) {
        commonErrorHandler(validatePaymentError);
      }
    }

    //Stop-Scheduled Payment error case
    if (
      stopScheduledPaymentData.status === DataStatus.ERRORED &&
      stopScheduledPaymentData.error &&
      prevProps.stopScheduledPaymentData.stopScheduledPaymentData.status !==
        this.props.stopScheduledPaymentData.stopScheduledPaymentData.status
    ) {
      if (stopScheduledPaymentData.error.statusCode === 500) {
        this.setState({
          isTechnicalErrorOpen: !this.state.isTechnicalErrorOpen,
        });
      } else if (stopScheduledPaymentData.error) {
        commonErrorHandler(stopScheduledPaymentData.error);
      }
    }

    // check status api error case.
    if (
      billerStatusStatus === DataStatus.ERRORED &&
      billerStatusError &&
      prevProps.billerState.billerStatusState !==
        this.props.billerState.billerStatusState
    ) {
      this.setState({ isTechnicalErrorOpen: !this.state.isTechnicalErrorOpen });
    } else if (billerStatusError) {
      commonErrorHandler(billerStatusError);
    }
  }

  getLatestTransactionInfo = (item: BillerAccount) => {
    // Filter the historyDetailData based on category and authorization
    const {
      historyDetailData: { historyDetailData },
    } = this.props;
    if (historyDetailData.data) {
      const filteredData: PaymentDetail[] = [];
      historyDetailData.data &&
        historyDetailData.data.filter((history) => {
          if (
            history.authenticators &&
            history.billerId === item.billerId &&
            history.paymentStatus === PaymentStatusEnum.PAID &&
            history.billerStatus === BillerPaymentStatusEnum.SUCCESS
          ) {
            if (item.authenticators.length > 1) {
              if (
                history.authenticators[0]?.value ===
                  item.authenticators[0]?.value &&
                history.authenticators[1]?.value ===
                  item.authenticators[1]?.value
              ) {
                filteredData.push(history);
              }
            } else {
              if (
                history.authenticators[0]?.value ===
                item.authenticators[0]?.value
              ) {
                filteredData.push(history);
              }
            }
          }
        });
      // Sort the filtered data by transaction date in descending order
      const sortedData = filteredData.sort(
        (a, b) =>
          new Date(b.transactionTime).getTime() -
          new Date(a.transactionTime).getTime()
      );
      // Get the latest transaction amount and date
      const latestTransactionAmount =
        sortedData.length > 0 ? sortedData[0].paymentAmount : null;
      const latestTransactionDate =
        sortedData.length > 0 ? new Date(sortedData[0].transactionTime) : null;
      // Format the transaction date as "MonthDate" (e.g., "May12")
      let formattedTransactionDate = null;
      if (latestTransactionDate) {
        const month = latestTransactionDate.toLocaleString("default", {
          month: "long",
        });
        const date = latestTransactionDate.getDate();
        formattedTransactionDate = `${date}${" "}${month}`;
      }
      // Return an object containing the latest transaction amount and date
      return {
        latestTransactionAmount,
        latestTransactionDate: formattedTransactionDate,
      };
    }
  };

  //HANDLING MODAL DATA HERE
  handleModalData = (typeOfOperation: string, biller: BillerAccount): void => {
    if (
      typeOfOperation === "Delete Biller" ||
      typeOfOperation === "Disable Autopay" ||
      typeOfOperation === "Delete Scheduled Payment"
    ) {
      this.gaCallOnModalPopup(GAactionObjectResolver[typeOfOperation]);
      this.setState({
        mainHeading: SelectionModalContentObject[typeOfOperation].heading,
        description: SelectionModalContentObject[typeOfOperation].description,
        selectedBillerAccount: biller,
        operation: typeOfOperation,
        isSelectionModalOpen: true,
      });
    } else if (typeOfOperation === "Edit Nick Name") {
      this.setState({
        selectedBillerAccount: biller,
        operation: typeOfOperation,
        isNickNameModalOpen: true,
      });
    } else {
      this.gaCallOnModalPopup(GAactionObjectResolver[typeOfOperation]);
      this.setState({
        selectedBillerAccount: biller,
        operation: typeOfOperation,
        onAutopayModalOpen: true,
      });
    }
  };

  //INITIATION DISABLE AUTOPAY OR DELETE BILLER OPERATION
  initialOperation = () => {
    const {
      actions,
      customerData: { customerId, mobile, emailId },
    } = this.props;
    const { operation, selectedBillerAccount, nickname } = this.state;

    if (operation === ManageBillerOptions.deleteBiller) {
      actions.deleteBiller(customerId, selectedBillerAccount.billerAccountId);
      this.setState({
        statusText: ManageBillerSuccessModalStatusText.deleteBiller,
      });
    } else if (operation === ManageBillerOptions.disableAutoPay) {
      this.setState({
        statusText: ManageBillerSuccessModalStatusText.disableAutoPay,
      });
      actions.modifyBiller(
        customerId,
        mobile,
        emailId,
        selectedBillerAccount.billerAccountId,
        !selectedBillerAccount.autopayStatus
      );
    } else if (operation === ManageBillerOptions.deleteScheduledPayment) {
      actions.stopScheduledPayment(
        customerId,
        selectedBillerAccount.paymentId
          ? undefined
          : selectedBillerAccount.currentBill?.billId,
        selectedBillerAccount.paymentId
      );
      this.setState({
        statusText: ManageBillerSuccessModalStatusText.deleteScheduledPayment,
      });
    } else if (operation === ManageBillerOptions.editNickName) {
      actions.modifyBiller(
        customerId,
        mobile,
        emailId,
        selectedBillerAccount.billerAccountId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        nickname
      );
      this.setState({
        statusText: ManageBillerSuccessModalStatusText.editNickName,
        nickname: "",
      });
    }
  };

  //GETTING MENU OPTIONS
  getMenuList = (item: BillerAccount) => {
    const {
      //isAutoPay,
      isDue,
      isScheduled,
      scheduledAmount,
      scheduledDate,
      dueAmount,
      dueDate,
    } = billerStatusAmountResolver(item);
    let menuOptions: string[] = [];

    if (item.autopayStatus) {
      menuOptions = [
        ManageBillerOptions.deleteBiller,
        ManageBillerOptions.editAutoPay,
        ManageBillerOptions.disableAutoPay,
        ManageBillerOptions.editNickName,
      ];
      if (isScheduled) {
        if (
          scheduledDate?.toLocaleDateString("en-in") !==
          new Date().toLocaleDateString("en-in")
        ) {
          menuOptions.push(ManageBillerOptions.deleteScheduledPayment);
        }
      }
    } else if (isScheduled) {
      if (
        scheduledDate?.toLocaleDateString("en-in") ===
        new Date().toLocaleDateString("en-in")
      ) {
        menuOptions = [
          ManageBillerOptions.deleteBiller,
          ManageBillerOptions.editNickName,
        ];
      } else {
        menuOptions = [
          ManageBillerOptions.deleteBiller,
          ManageBillerOptions.deleteScheduledPayment,
          ManageBillerOptions.editNickName,
        ];
      }
    } else {
      if (
        (item.billerType === "PAYEE" && !isScheduled) ||
        isAutoPayRestriced(item.billerCategory)
      ) {
        menuOptions = [
          ManageBillerOptions.deleteBiller,
          ManageBillerOptions.editNickName,
        ];
      } else {
        menuOptions = [
          ManageBillerOptions.deleteBiller,
          ManageBillerOptions.enableAutopay,
          ManageBillerOptions.editNickName,
        ];
      }
    }

    return (
      <>
        {menuOptions.map((menuOption) => {
          return (
            <DsBox key={menuOption} m={4}>
              <DsCard
                variant="outlined"
                style={{ borderRadius: "8px", cursor: "pointer" }}
                onClick={() => {
                  this.handleModalData(menuOption, item);
                }}
              >
                <DsBox
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  gap={4}
                  p="12px"
                >
                  {menuOption === ManageBillerOptions.deleteBiller ? (
                    <DsRemixIcon className="ri-delete-bin-line" />
                  ) : menuOption === ManageBillerOptions.editAutoPay ? (
                    <DsRemixIcon className="ri-edit-box-line" />
                  ) : menuOption === ManageBillerOptions.editNickName ? (
                    <DsRemixIcon className="ri-pencil-line" />
                  ) : menuOption === ManageBillerOptions.enableAutopay ? (
                    <DsRemixIcon className="ri-repeat-line" />
                  ) : menuOption ===
                    ManageBillerOptions.deleteScheduledPayment ? (
                    <DsRemixIcon className="ri-error-warning-line" />
                  ) : menuOption === ManageBillerOptions.disableAutoPay ? (
                    <DsRemixIcon className="ri-error-warning-line" />
                  ) : null}
                  <DsTypography
                    variant="subheadingSemiboldDefault"
                    marginLeft="2px"
                    padding="2px"
                  >
                    {menuOption}
                  </DsTypography>
                </DsBox>
              </DsCard>
            </DsBox>
          );
        })}
      </>
    );
  };

  onAuthClose = () => {
    this.setState({ isMpinOpen: false, isOtpOpen: false });
  };

  //MPIN/OTP AUTH STATUS
  onAuthSuccess = () => {
    //Modify And Delete Billers
    this.initialOperation();

    this.onAuthClose();
  };

  onAuthError = (error?: ApiResponseErrorImpl) => {
    // toaster({
    //   title: error?.message || "Authentication failed. Please try again",
    //   status: "error",
    //   variant: "error",
    // });
    enqueueNotistack({
      message: error?.message || "Authentication failed. Please try again",
      variant: "error",
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      style: { paddingBottom: "var(--ds-spacing-cool)" },
    });
  };

  handleMpinClose = () => {
    this.setState({ isMpinOpen: true });
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "mpin verification",
      "back"
    );
  };

  handleOTPClose = () => {
    this.setState({ isOtpOpen: !this.state.isOtpOpen });
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "otp verification",
      "back"
    );
  };

  gaCallOnModalPopup = (operation: string) => {
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "manage billers",
      "manage billers",
      operation,
      `vpv/pay-bills/manage-billler/${operation}`,
      `pay bills - manage billler - ${operation}`,
      "event_pay_bills_onload_vpv"
    );
  };

  handleAutopayModalClose = () => {
    this.setState({ onAutopayModalOpen: false });
  };

  getBillerName = (billerAccount: BillerAccount): string => {
    let billerName = billerAccount.billerName;

    if (billerName && billerName.length > 21) {
      billerName = billerName.substring(0, 24) + "...";
    }

    return billerName;
  };

  handleSchedulingBiller = (date: Date) => {
    const {
      navigate,
      paymentJourneyData,
      billerState: { billerDetailData },
      // validatePayment,
      validatePaymentData: { validatePaymentData },
      categoryLogo,
      actions,
    } = this.props;

    const { selectedDate } = this.state;
    actions.setBillerRegisterationToPaymentPage({
      billerAccount: paymentJourneyData?.billerAccount,
      biller: billerDetailData,
      scheduledDate: date,
      categoryLogo: categoryLogo,
      isFromManageBillerDetailPage: true,
    });
    actions.showLoader();
    navigate(PAYMENT_ROUTES.PAYMENT.pathname, {
      state: {
        billerAccount: paymentJourneyData?.billerAccount,
        biller: billerDetailData,
        // validatePayment: validatePaymentData || validatePayment,
        scheduledDate: date,
        // isCashbackAvailable: isCashbackAvailable,
        // cashbackData: cashbackData,
        // autopayCashbackOffer:
        //   cashbackData && cashbackData?.AUTO?.length
        //     ? cashbackData.AUTO[0]
        //     : {},
        // manualCashbackOffer:
        //   cashbackData && cashbackData.MANUAL?.length
        //     ? cashbackData.MANUAL[0]
        //     : {},
        // isAutoCashbackOfferAvailable: isAutoCashbackOfferAvailable,
        // isManualCashbackOfferAvailable: isManualCashbackOfferAvailable,
        categoryLogo: categoryLogo,
        isFromManageBillerDetailPage: true,
      },
    });

    handleGAPush(
      CategoryHelperObject.categoryPayBills +
        paymentJourneyData?.billerAccount?.billerCategory.toLowerCase(),
      "bill details | existing payments",
      "schedule payment"
    );
  };

  handleDateChange = (date: Date): void => {
    this.setState({ selectedDate: date });
    this.handleSchedulingBiller(date);
    handleGAPush(
      CategoryHelperObject.categoryPayBills +
        this.props.paymentJourneyData?.billerAccount?.billerCategory.toLowerCase(),
      "bill details | existing payments",
      "schedule payment"
    );
  };

  handleScheduleBillerModal = () => {
    this.setState({
      isSchedulePaymentModalOpen: !this.state.isSchedulePaymentModalOpen,
    });
    if (this.datePickerRef?.current) {
      this.datePickerRef.current?.setOpen(true);
    }
  };

  render() {
    const {
      historyDetailData: { historyDetailData },
      customerData: { channelId },
      navigate,
      actions,
      paymentJourneyData,
    } = this.props;

    const {
      paymentHistoryModal,
      isSelectionModalOpen,
      isNickNameModalOpen,
      onAutopayModalOpen,
      mainHeading,
      description,
      selectedBillerAccount,
      operation,
      isFullPay,
      isMpinOpen,
      isOtpOpen,
      statusText,
      isSuccessModalOpen,
    } = this.state;

    const {
      isDue,
      isScheduled,
      dueAmount,
      dueDate,
      scheduledAmount,
      scheduledDate,
    } = billerStatusAmountResolver(paymentJourneyData?.billerAccount);

    /** Function to find biller id in payment history data and push all the history data to an array */
    const paymentHistoryForABiller: PaymentDetail[] = [];

    historyDetailData.data &&
      historyDetailData.data.filter((history) => {
        if (
          history.authenticators &&
          history.billerId === paymentJourneyData?.billerAccount?.billerId &&
          history.paymentStatus === PaymentStatusEnum.PAID &&
          history.billerStatus === BillerPaymentStatusEnum.SUCCESS
        ) {
          if (paymentJourneyData?.billerAccount?.authenticators.length > 1) {
            if (
              history.authenticators[0]?.value ===
                paymentJourneyData?.billerAccount?.authenticators[0]?.value &&
              history.authenticators[1]?.value ===
                paymentJourneyData?.billerAccount?.authenticators[1]?.value
            ) {
              paymentHistoryForABiller.push(history);
            }
          } else {
            if (
              history.authenticators[0]?.value ===
              paymentJourneyData?.billerAccount?.authenticators[0]?.value
            ) {
              paymentHistoryForABiller.push(history);
            }
          }
        }
      });

    const latestTransactionDetails = this.getLatestTransactionInfo(
      paymentJourneyData?.billerAccount
    );

    return (
      <DsBox>
        <DsBox m="var(--ds-spacing-bitterCold)">
          <DsCard
            variant="outlined"
            style={{ borderRadius: "var(--ds-spacing-glacial)" }}
          >
            <DsBox
              p="var(--ds-spacing-bitterCold)"
              display="flex"
              flexDirection="column"
              gap="var(--ds-spacing-bitterCold)"
              sx={{
                borderBottom:
                  paymentJourneyData?.billerAccount?.autopayStatus ||
                  latestTransactionDetails?.latestTransactionAmount
                    ? "1px solid var(--ds-colour-strokeDefault)"
                    : null,
                borderTopLeftRadius: "var(--ds-spacing-glacial)",
                borderTopRightRadius: "var(--ds-spacing-glacial)",
              }}
            >
              <DsBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <BillerInfoCard
                  billerLogo={paymentJourneyData?.billerAccount?.billerLogo}
                  billerName={this.getBillerName(
                    paymentJourneyData?.billerAccount
                  )}
                  shortName={paymentJourneyData?.billerAccount?.shortName}
                  category={paymentJourneyData?.billerAccount?.billerCategory}
                  categoryLogo={this.props.categoryLogo}
                  authValue={
                    paymentJourneyData?.billerAccount?.authenticators?.length >
                    1
                      ? paymentJourneyData?.billerAccount?.authenticators[1]
                          ?.value
                      : paymentJourneyData?.billerAccount?.authenticators[0]
                          ?.value
                  }
                />
                <DsBox>
                  {isDue || isScheduled ? (
                    <DsTypography variant="bodyBoldLarge">
                      {`${
                        isDue
                          ? localeAmountFormat(dueAmount)
                          : localeAmountFormat(scheduledAmount)
                      }`}
                    </DsTypography>
                  ) : latestTransactionDetails?.latestTransactionAmount ? (
                    <DsTypography variant="bodyBoldLarge">
                      {localeAmountFormat(
                        latestTransactionDetails.latestTransactionAmount
                      )}
                    </DsTypography>
                  ) : null}
                </DsBox>
              </DsBox>
            </DsBox>

            {paymentJourneyData?.billerAccount?.autopayStatus ||
            (latestTransactionDetails?.latestTransactionAmount &&
              latestTransactionDetails.latestTransactionDate) ? (
              <DsBox
                sx={{
                  backgroundColor: "var(--ds-colour-surfaceSecondary)",
                  paddingTop: "var(--ds-spacing-frostbite)",
                  paddingBottom: "var(--ds-spacing-frostbite)",
                  paddingLeft: "var(--ds-spacing-bitterCold)",
                  paddingRight: "var(--ds-spacing-bitterCold)",
                }}
              >
                {latestTransactionDetails?.latestTransactionAmount &&
                latestTransactionDetails.latestTransactionDate ? (
                  <DsBox>
                    <DsChip
                      label={`Last Recharge of ${localeAmountFormat(
                        latestTransactionDetails?.latestTransactionAmount
                      )} on ${latestTransactionDetails?.latestTransactionDate}`}
                      color="info"
                      type="nudge"
                    />
                  </DsBox>
                ) : null}

                {paymentJourneyData?.billerAccount?.autopayStatus &&
                  paymentJourneyData?.billerAccount &&
                  paymentJourneyData?.billerAccount?.paymentAccount &&
                  Object.keys(paymentJourneyData?.billerAccount?.paymentAccount)
                    .length > 0 && (
                    <DsBox sx={{ marginTop: "8px" }}>
                      {paymentJourneyData?.billerAccount?.paymentAccount
                        ?.paymentMethod === "BankAccount" &&
                      paymentJourneyData?.billerAccount?.paymentAccount
                        ?.accountNumber ? (
                        <DsChip
                          color="info"
                          type="nudge"
                          label={`Payment via: ${updatedMaskAccountNumber(
                            paymentJourneyData?.billerAccount?.paymentAccount
                              .accountNumber
                          )} (${
                            paymentJourneyData?.billerAccount?.paymentAccount
                              ?.paymentMethod
                          })`}
                        />
                      ) : paymentJourneyData?.billerAccount?.paymentAccount
                          ?.paymentMethod === "CreditCard" &&
                        paymentJourneyData?.billerAccount?.paymentAccount
                          ?.cardEnd ? (
                        <DsChip
                          color="info"
                          type="nudge"
                          label={`Payment via: ${updatedccmaskAccountNumber(
                            paymentJourneyData?.billerAccount?.paymentAccount
                              ?.cardEnd
                          )} (${
                            paymentJourneyData?.billerAccount?.paymentAccount
                              ?.paymentMethod
                          })`}
                        />
                      ) : null}
                    </DsBox>
                  )}
              </DsBox>
            ) : null}
          </DsCard>
        </DsBox>
        <DsBox marginTop="24px" marginLeft="16px" marginRight="16px">
          <DsCard
            variant="outlined"
            style={{ borderRadius: "8px", cursor: "pointer" }}
          >
            <DsBox
              display="flex"
              flexDirection="row"
              gap={4}
              p={3}
              alignItems="center"
              onClick={() => {
                // this.setState({ paymentHistoryModal: true });
                navigate(APP_ROUTES.MANAGE_BILLER_PAY_HISTORY.pathname, {
                  state: {
                    biller: paymentJourneyData?.billerAccount,
                    paymentHistoryForBiller: paymentHistoryForABiller,
                  },
                });
              }}
            >
              <DsRemixIcon className="ri-history-line" />
              <DsTypography
                variant="subheadingSemiboldDefault"
                marginLeft="4px"
                padding="2px"
              >
                Payment History
              </DsTypography>
            </DsBox>
          </DsCard>
        </DsBox>
        <DsBox>{this.getMenuList(paymentJourneyData?.billerAccount)}</DsBox>
        <BottomSticker
          sx={{
            padding: "var(--ds-spacing-bitterCold)",
            paddingBottom: "var(--ds-spacing-mild)",
          }}
        >
          <>
            <DsButton
              color="primary"
              sx={{ width: "100%" }}
              size="large"
              onClick={() => {
                const biller = paymentJourneyData?.biller;
                let billList = !paymentJourneyData?.billerAccount?.billList
                  ? true
                  : paymentJourneyData?.billerAccount?.billList?.length === 0
                  ? true
                  : false;
                const noBillDue =
                  billList &&
                  biller?.billerType === BillerTypeEnum.BILLER &&
                  biller?.billPresentment &&
                  !biller?.paymentAmountValidation &&
                  biller?.billerCategory?.toLowerCase() !== "fastag";

                if (noBillDue) {
                  actions.hideLoader();
                  this.setState({ isNoDueBillModalOpen: true });
                } else {
                  actions.showLoader();
                  actions.setBillerRegisterationToPaymentPage({
                    billerAccount: paymentJourneyData?.billerAccount,
                    biller: biller,
                  });
                  navigate(PAYMENT_ROUTES.PAYMENT.pathname);
                }

                //   actions.setBillerRegisterationToPaymentPage({
                //     billerAccount: item,
                //   });
                //   actions.showLoader();
                //   navigate(PAYMENT_ROUTES.PAYMENT.pathname, {
                //     state: {
                //       billerAccount: item,
                //     },
                //   });
              }}
            >
              Proceed
            </DsButton>
            {/* )} */}
          </>
        </BottomSticker>
        <SelectionModal
          isOpen={isSelectionModalOpen}
          onClose={() => this.setState({ isSelectionModalOpen: false })}
          mainHeading={mainHeading}
          description={description}
          handleDeleteClick={() => {
            if (channelId === CustomerChannelEnum.MobileBanking) {
              this.setState({ isMpinOpen: true });
            } else {
              this.setState({ isOtpOpen: true });
            }
            this.setState({ isSelectionModalOpen: false });
          }}
        />
        <NicknameDrawer
          isOpen={isNickNameModalOpen}
          onClose={() => this.setState({ isNickNameModalOpen: false })}
          defaultNickName={paymentJourneyData?.billerAccount?.shortName}
          onNickNameChange={(shortName) => {
            this.setState({ nickname: shortName });
            if (channelId === CustomerChannelEnum.MobileBanking) {
              this.setState({ isMpinOpen: true });
            } else {
              this.setState({ isOtpOpen: true });
            }
          }}
          alreadyUsedNickNames={
            this.props.billerAccountData?.alreadyUsedNickNames
          }
        />
        {this.state.onAutopayModalOpen && (
          <AutoPayModal
            onClose={this.handleAutopayModalClose}
            title="Setup Auto Pay"
            isOpen={onAutopayModalOpen}
            billerAccount={selectedBillerAccount}
            typeOfOperation={operation as "Edit Autopay" | "Add Autopay"}
            isFullPay={isFullPay}
            setFullPay={(value: boolean) => this.setState({ isFullPay: value })}
          />
        )}

        {/* <PaymentHistoryDetails
          isOpen={paymentHistoryModal}
          onClose={() => this.setState({ paymentHistoryModal: false })}
          biller={item}
          paymentHistoryForBiller={paymentHistoryForABiller}
        /> */}

        {isSuccessModalOpen && (
          <SuccessStatusModal
            isOpen={isSuccessModalOpen}
            onClose={() => {
              actions.getBillerAccounts(this.props.customerId);
              this.setState({ isSuccessModalOpen: false });
              this.props.navigate(APP_ROUTES.HOME.pathname);
            }}
            statusText={statusText}
            duration={3000}
          />
        )}
        {!this.state.onAutopayModalOpen && (
          <MpinModal
            isOpen={isMpinOpen}
            onClose={() => {
              this.setState({ isMpinOpen: false });

              handleGAPush(
                CategoryHelperObject.categoryPayBills + "manage billers",
                "mpin verification",
                "back"
              );
            }}
            onSuccess={this.onAuthSuccess}
            onFailure={this.onAuthError}
            eventCategory={paymentJourneyData?.billerAccount?.billerCategory}
          />
        )}

        {!this.state.onAutopayModalOpen && (
          <OtpModal
            isOpen={isOtpOpen}
            onClose={this.handleOTPClose}
            onSuccess={this.onAuthSuccess}
            onFailure={this.onAuthError}
            eventCategory={paymentJourneyData?.billerAccount?.billerCategory}
          />
        )}
        <TechnicalErrorDrawer
          isOpen={this.state.isTechnicalErrorOpen}
          onClose={() => this.setState({ isTechnicalErrorOpen: false })}
          title="We are experiencing technical difficulties."
          description="Please try again later."
          onClick={() => {
            this.props.navigate(APP_ROUTES.HOME.pathname);
          }}
        />

        {this.state.isNoDueBillModalOpen && (
          <FullModalAlert
            isOpen={this.state.isNoDueBillModalOpen}
            onClose={() => {
              if (
                paymentJourneyData?.biller?.onlineValidation &&
                paymentJourneyData?.billerAccount?.status.toLowerCase() ===
                  "ACTIVE".toLowerCase()
              ) {
                actions.showLoader();
                actions.setBillerRegisterationToPaymentPage({
                  billerAccount: paymentJourneyData?.billerAccount,
                  biller: paymentJourneyData?.selectedBiller?.data,
                });
                navigate(PAYMENT_ROUTES.PAYMENT.pathname);
              } else {
                this.setState({ isNoDueBillModalOpen: false });
              }
            }}
            alertTitle="No Bills Due!"
            alertDescription="We will notify you once the new bill is generated."
            billerAccount={paymentJourneyData?.billerAccount}
            biller={paymentJourneyData?.biller?.data}
          />
        )}

        {this.props.deleteBillerData?.deleteRegisterBillerData?.status ===
          DataStatus.LOADING ||
        this.props.modifyBillerData.modifyRegisteredBillerData?.status ===
          DataStatus.LOADING ||
        this.props.stopScheduledPaymentData?.stopScheduledPaymentData
          ?.status === DataStatus.LOADING ||
        this.props.validatePaymentData?.validatePaymentStatus ===
          DataStatus.LOADING ||
        this.props.billerState?.billerStatus === DataStatus.LOADING ||
        this.props.billerState?.billerStatusState.status ===
          DataStatus.LOADING ? (
          <OverlayLoader />
        ) : null}
      </DsBox>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const customerId = getCustomerId(state);
  const customerData = getCustomerData(state);
  const historyDetailData = getHistorySelector(state);
  const deleteBillerData = getDeleteRegisterBillerSelector(state);
  const modifyBillerData = getModifyRegisteredBillerSelector(state);
  const validatePaymentData = validatePaymentSelector(state);
  const billerState = billersSelector(state);
  const stopScheduledPaymentData = getStopScheduledPaymentSelector(state);
  const categoryLogo = getCategoryLogo(state);
  const billerAccountData = billerAccountSelector(state);
  const paymentJourneyData = state?.billers.billerRegisterationToPaymentPage;
  return {
    customerId,
    customerData,
    historyDetailData,
    deleteBillerData,
    modifyBillerData,
    validatePaymentData,
    billerState,
    stopScheduledPaymentData,
    categoryLogo,
    billerAccountData,
    paymentJourneyData,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  actions: {
    deleteBiller: (customerId: string, billeraccountId: string) =>
      dispatch(getDeleteRegisteredBillerListThunk(customerId, billeraccountId)),
    modifyBiller: (
      customerId: string,
      mobile: string,
      email: string,
      billeraccountId: string,
      autopayStatus?: boolean,
      autopayAmount?: number,
      currency?: number,
      paymentAccount?: {
        paymentMethod: string;
        accountNumber?: string;
        ifsc?: string;
        cardStart?: string;
        cardEnd?: string;
        primeCardSerialNumber?: string;
      },
      isCreditCardSelected?: boolean,
      shortName?: string
    ) =>
      dispatch(
        getModifyRegisteredBillerListThunk(
          customerId,
          mobile,
          email,
          billeraccountId,
          autopayStatus,
          autopayAmount,
          currency,
          paymentAccount,
          isCreditCardSelected,
          shortName
        )
      ),
    stopScheduledPayment: (
      customerId: string,
      billId?: string,
      paymentId?: string
    ) => dispatch(stopScheduledPaymentListThunk(customerId, billId, paymentId)),
    loadBillerStatus: (billerId: string) => dispatch(getBillerStatus(billerId)),
    validatePaymentReq: (
      customerId: string,
      mobile: string,
      email: string,
      billerId: string,
      authenticators: {
        parameterName: string;
        value: string;
      }[]
    ) => dispatch(validatePayment({ billerId, authenticators })),
    getBillerAccounts: (customerId: string) =>
      dispatch(getDueBills(customerId)),
    setBillerAccountStatusNotLoaded: () =>
      dispatch(setBillerAccountStatusNotLoaded(DataStatus.NOT_LOADED)),
    setBillerRegisterationToPaymentPage: (obj: any) =>
      dispatch(setBillerRegisterationToPaymentPage(obj)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withNavigationBar(ManageBillerBillDetail)("Biller Details"))
);
