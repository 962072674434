import {
  Biller,
  PaymentChannel,
  PaymentChannelEnum,
  PaymentMethod,
} from "~/src/Redux/Billers/Model/Biller.model";
import { CustomerChannelEnum } from "~/src/Redux/Customer/Customer.reducer";
import { AccountDetail } from "~/src/Redux/Payment/Model/AccountDetail.model";
import {
  BillerPaymentStatusEnum,
  PaymentMethodEnum,
} from "~/src/Redux/Payment/Model/PaymentDetail.model";

export function getSchemeNameFromType(type: string): string {
  switch (type) {
    case "CAA":
      return "Current";
      break;
    case "credit":
      return "Credit Card";
      break;
    case "debit":
      return "Debit Card";
      break;
    default:
      // SBA
      return "Savings";
      break;
  }
}

export function maskAccountNumber(account: string): string {
  const first = account.substring(0, 4);
  const last = account.substring(account.length - 4, account.length);
  const masked = account.substring(4, account.length - 4).replace(/\d/g, "*");
  return `${first} ${masked} ${last}`;
  // return account
  //   .replace(new RegExp(/^\d{4}\K/), "**** ")
  //   .replace(new RegExp(/[^\d{4}]$/), " ****");
  // return account.replace(new RegExp(/^\d{8}/), "**** **** ");
  // .replace(new RegExp(/\d{4}$/), " ****");
}

export function updatedMaskAccountNumber(account: string): string {
  const lastEight = account.substring(account.length - 4, account.length);
  const maskedFirstFour = account.substring(0, 4).replace(/[a-zA-Z0-9]/g, "*");

  // insert a space after every 4 characters of the masked string
  let spacedMasked = "";
  for (let i = 0; i < maskedFirstFour.length; i += 4) {
    spacedMasked += maskedFirstFour.substr(i, 4) + " ";
  }
  // remove the extra space at the end if any
  spacedMasked = spacedMasked.trimRight();

  return `${spacedMasked} ${" "} ${lastEight}`;
}

export function ccmaskAccountNumber(account: string): string {
  const first = account.substring(0, 4);
  const last = account.substring(account.length - 4, account.length);
  const masked = account
    .substring(4, account.length - 4)
    .replace(/[a-zA-Z0-9]/g, "*");

  // insert a space after every 4 characters of the masked string
  let spacedMasked = "";
  for (let i = 0; i < masked.length; i += 4) {
    spacedMasked += masked.substr(i, 4) + " ";
  }
  // remove the extra space at the end if any
  spacedMasked = spacedMasked.trimRight();

  return `${first} ${spacedMasked} ${last}`;
}

export function updatedccmaskAccountNumber(account: string): string {
  const lastEight = account.substring(account.length - 4, account.length);
  const maskedFirstFour = account.substring(0, 4).replace(/[a-zA-Z0-9]/g, "*");

  // insert a space after every 4 characters of the masked string
  let spacedMasked = "";
  for (let i = 0; i < maskedFirstFour.length; i += 4) {
    spacedMasked += maskedFirstFour.substr(i, 4) + " ";
  }
  // remove the extra space at the end if any
  spacedMasked = spacedMasked.trimRight();

  return `${spacedMasked} ${" "} ${lastEight}`;
}

export function isChannelAllowed(
  channelId: CustomerChannelEnum,
  biller: Biller
): boolean {
  for (let i = 0; i < biller.paymentChannels.length; i++) {
    if (
      biller.paymentChannels[i].paymentChannel ===
      mapPaymentChannelWithId(channelId)
    ) {
      return true;
    }
  }
  return false;
}

function mapPaymentChannelWithId(
  channelId: CustomerChannelEnum
): PaymentChannelEnum {
  if (channelId === CustomerChannelEnum.InternetBanking) {
    return PaymentChannelEnum.InternetBanking;
  }
  return PaymentChannelEnum.MobileBanking;
}

export function allowedPaymentMethod(
  accounts: AccountDetail[],
  biller: Biller
): AccountDetail[] {
  const methods = biller.paymentMethods.map((m) => m.paymentMethod);
  return accounts.filter((acc) => methods.includes(acc.paymentMethod));
}

export function getPaymentMenthodInfo(
  paymentMethod: PaymentMethodEnum,
  biller: Biller
): PaymentMethod | undefined {
  for (let i = 0; i < biller?.paymentMethods.length; i++) {
    if (paymentMethod === biller?.paymentMethods[i]?.paymentMethod) {
      return biller?.paymentMethods[i];
    }
  }
  return undefined;
}

export function getPaymentChannelInfo(
  channelId: CustomerChannelEnum,
  biller: Biller
): PaymentChannel | undefined {
  for (let i = 0; i < biller.paymentChannels.length; i++) {
    if (
      mapPaymentChannelWithId(channelId) ===
      biller.paymentChannels[i].paymentChannel
    ) {
      return biller.paymentChannels[i];
    }
  }
  return undefined;
}

export const getPaymentStatusColor = (
  status: BillerPaymentStatusEnum
): string => {
  switch (status) {
    case BillerPaymentStatusEnum.SUCCESS:
      return "var(--ds-colour-supportPositiveNeutral)";
    case BillerPaymentStatusEnum.FAILED:
      return "var(--ds-colour-supportNegativeNeutral)";
    default:
      return "var(--ds-colour-supportWarningNeutral)";
  }
};

export const attemptText = (attempts: number, type: string) => {
  switch (attempts) {
    case 0:
      return `Invalid ${type}. Attempts exhausted. Your account is locked.`;
    case 1:
      return `Invalid ${type}. You have only one attempt remaining. Please enter the valid ${type} or your account will be locked.`;
    default:
      return `Invalid ${type}: ${attempts} attempt(s) remaining`;
  }
};

export enum PAYMENT_ERROR {
  RETRY_PAYMENT = "BILLPAY_JINX_PAYMENT_03",
  INSUFFIENT_FUND = "BILLPAY_JINX_PAYMENT_04",
  PAYMENT_ERROR_3 = "BILLPAY_JINX_PAYMENT_05",
  PAYMENT_ERROR_2 = "BILLPAY_JINX_PAYMENT_06",
  PAYMENT_FAILED_2 = "BILLPAY_JINX_PAYMENT_07",
  PAYMENT_PROCESSING = "BILLPAY_JINX_PAYMENT_08",
  PAYMENT_ERROR = "BILLPAY_JINX_PAYMENT_09",
  PAYMENT_FAILED = "BILLPAY_JINX_PAYMENT_10",
  SCHEDULE_ERROR = "BILLPAY_JINX_PAYMENT_11",
  PAYMENT_ERROR_4 = "BILLPAY_JINX_PAYMENT_13",
  ISSUE_IN_CREDIT_CARD = "BILLPAY_JINX_CARD_03",
}

export const calculateMinAmount = (
  isFastag: boolean,
  partialPayLessThanAmount: boolean,
  methodMin: number,
  billAmount: string,
  biller: Biller
): number => {
  if (isFastag) return methodMin;

  switch (true) {
    case biller.isExactUpPayment:
      return Number(billAmount);
    case partialPayLessThanAmount || biller.isAnyPartialPayAmount:
      return methodMin;
    default:
      return methodMin;
  }
};

export const calculateMaxAmount = (
  isFastag: boolean,
  partialPayLessThanAmount: boolean,
  methodMax: number,
  billAmount: string,
  biller: Biller
): number => {
  if (isFastag) return methodMax;

  switch (true) {
    case biller.isExactUpPayment || biller.isAnyPartialPayAmount:
      return methodMax;
    case partialPayLessThanAmount:
      return Number(billAmount);
    default:
      return methodMax;
  }
};

export const isCreditCardAllowedAccounts = (
  accountSchemeCodes: string[]
): boolean => {
  const creditcardAllowedSchemeCode =
    process.env.CREDIT_CARD_SCHEME_CODES || "*";
  if (creditcardAllowedSchemeCode === "*") return true;
  const isValidSchemeCode = creditcardAllowedSchemeCode
    .split(",")
    .filter((value) => accountSchemeCodes?.includes(value));

  return isValidSchemeCode?.length ? true : false;
};
