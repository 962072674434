import { RootState } from "~/src/Configurations/AppStore";
import { WEB_HTTP_CONTEXT, WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { URL } from "~/src/Constants/API_URLS";
import { Action, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import {
  fetchMpinList,
  fetchMpinListFail,
  fetchMpinListSuccess,
} from "~/src/Redux/Payment/Reducers/Mpin.reducers";

const VALIDATE_MPIN = URL.PAYMENT.VALIDATE_MPIN;

export const validateMpinListThunk =
  (customerId: string, mpin: string): AppThunk<Promise<{ isValid: boolean }>> =>
  async (dispatch: ThunkDispatch<RootState, string, Action<string>>) => {
    dispatch(fetchMpinList({ customerId, mpin }));
    try {
      const options: WebHttpRequestOptions = {
        url: VALIDATE_MPIN,
        method: "POST",
        data: {
          customerid: customerId,
          mpin: mpin,
        },
      };
      const response = await asHttp.request(options);
      const { data } = response;

      if (response.data.data.respDesc.toLowerCase() === "success") {
        dispatch(fetchMpinListSuccess({ isValid: true }));
      } else {
        dispatch(fetchMpinListSuccess({ isValid: false }));
      }
    } catch (error) {
      dispatch(fetchMpinListFail(error as ApiResponseError));
      throw error;
    }
  };
